<script setup lang="ts">
  import { User as ChatUser } from '@pubnub/chat';
  import { computed, ref } from 'vue';
  import { useChatStore } from '@src/store/chat';
  import CatsWelcome from '@components/svgs/CatsWelcome.vue';
  import ChatParticipant from '@components/Chat/ChatParticipant.vue';

  const props = defineProps<{
    open: boolean;
  }>();

  const emit = defineEmits(['toggle', 'send-message']);

  const chatStore = useChatStore();
  const selectedUser = ref<ChatUser>();
  const searchQuery = ref('');

  const filteredParticipants = computed((): ChatUser[] => {
    if (['announcements', 'modcon_2024'].includes(chatStore.currentChannelUrl)) {
      return [];
    }
    if (searchQuery.value === '') {
      return chatStore.currentChannelParticipants
        .map((user) => user)
        .sort((a, b) => {
          return (a.name || '').localeCompare(b.name || '');
        }) as ChatUser[];
    }
    return chatStore.currentChannelParticipants.filter((user) => user.name?.toLowerCase().includes(searchQuery.value.toLowerCase())) as ChatUser[];
  });

  const isSpecialChannel = computed(() => {
    return ['announcements', 'modcon_2024'].includes(chatStore.currentChannelUrl);
  });

  const isChannelOperator = computed(() => {
    return chatStore.canOperateOnCurrentChannel;
  });

  const sendMessage = ({ user, message }: { user: ChatUser; message: string }) => {
    selectedUser.value = user;
    emit('send-message', { user, message });
  };

  const closeAllOpenMenus = () => {
    chatStore.emojiPickerOpen = false;

    chatStore.participantCardOpen = false;
    chatStore.participantCardUser = null;
    chatStore.composerFocusStealOverride = false;

    chatStore.showMessageContextMenu = false;
    chatStore.messageContextTimeToken = null;
  };
</script>

<template>
  <div class="bg-surface-50 dark:bg-surface-800/80 h-full p-2 group" @contextmenu.stop.prevent="closeAllOpenMenus">
    <div v-if="open" class="relative h-full flex flex-col">
      <div v-if="!isSpecialChannel && isChannelOperator">
        <div class="relative">
          <img :src="chatStore.currentChannelRaw?.custom.cover" size="large" class="rounded-full h-16 w-16 object-cover cursor-pointer" alt="" />
        </div>
      </div>
      <h2 v-if="isSpecialChannel" class="mb-2 flex-initial font-bold text-surface-600 dark:text-surface-300 p-2 text-xs uppercase">Event Channel</h2>
      <h2 v-else class="mb-2 flex-initial font-bold text-surface-600 dark:text-surface-300 p-2 text-xs uppercase">Members - {{ chatStore.currentChannelParticipants.length }}</h2>
      <div v-if="isSpecialChannel">
        <CatsWelcome class="w-full h-32" />
        <p v-if="chatStore.currentChannelRaw?.description" class="text-center text-sm text-surface-700 dark:text-surface-300">
          {{ chatStore.currentChannelRaw?.description }}
        </p>
      </div>
      <!-- DONT REMOVE HEIGHT, ITS THE MAGIC SAUCE -->
      <!-- Look I removed it don't yell at me we changed the layout so this should happen but i left i here cause magic-->
      <!--      <div class="flex-1" style="height: 100px">-->
      <div v-else class="h-full overflow-y-auto small-scroll-group">
        <ChatParticipant v-for="participant in filteredParticipants" :key="participant.id" class="mb-1" :user="participant" @send-message="sendMessage" />
      </div>
      <!--      </div>-->
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useChatStore } from '@src/store/chat';
  import { EmojiManager } from '@utils/emoji_manager';
  import { Message } from '@pubnub/chat';
  import { calculateTooltipPosition } from '@utils/tooltip_calculator';

  const chatStore = useChatStore();
  const emojiManager = EmojiManager.getInstance();
  const { normalizeEmojiInput } = emojiManager;

  const emits = defineEmits(['react']);
  const props = defineProps<{
    message: Message;
    currentUserId: string;
    showEmojiPickerButton: boolean;
  }>();

  const react = (emojiString: string) => {
    console.log(emojiString);
    emits('react', emojiString);
  };

  const showEmojiCard = (event: MouseEvent, emoji: string) => {
    chatStore.emojiReactionTooltipMessage = getToolTipMessage(props.message.reactions[emoji], emoji);
    chatStore.reactionEmoji = emoji;
    chatStore.showEmojiReactionCard = true;
    requestAnimationFrame(() => {
      let success = calculateTooltipPosition(event, 'chat-layer2', 'emoji-reaction-card', 'top', 0, 10);
      if (!success) {
        chatStore.showEmojiReactionCard = false;
      }
    });
  };

  const handleLeave = () => {
    chatStore.reactionEmoji = null;
    chatStore.showEmojiReactionCard = false;
  };

  const hasReacted = (emojiKey: string) => {
    if (props.message.reactions[emojiKey].some((user) => user.uuid === props.currentUserId)) {
      return true;
    }
    return false;
  };

  const getToolTipMessage = (userIds: Record<string, any>[], emoji: string): string => {
    let channelParticipants = chatStore.currentChannelParticipants;

    // Get the data of users who have reacted based on userIds
    let usersWhoReacted = userIds.map((userId) => channelParticipants.find((user) => user.id === userId.uuid));

    // Filter out the current user and get the first 6 users who reacted
    let first6UsersWhoReacted = usersWhoReacted.filter((user) => user?.id !== props.currentUserId).slice(0, 6);

    // Get the names of those 6 users
    let userNames = first6UsersWhoReacted.map((user) => user?.name);

    // Check if the current user has reacted
    let foundUser = userIds.find((user) => user.uuid === props.currentUserId);

    // Calculate how many others reacted that are not displayed
    let remainingReactions = userIds.length - userNames.length;

    // If the current user did react put them at the start away with the identification of 'You'
    if (foundUser) {
      userNames.unshift('You');
    }

    // Create the tooltip message based on remaining reactions
    if (remainingReactions > 1) {
      return `${userNames.join(', ')} and +${remainingReactions} reacted`;
    }

    // If there are no others, just return the names, adding 'and' before the last name if necessary
    if (userNames.length > 1) {
      const lastUser = userNames.pop();
      return `${userNames.join(', ')} and ${lastUser} reacted`;
    }

    // If there's only one user, just return their name
    return `${userNames[0]} reacted`;
  };

  const closeAllOpenMenus = () => {
    chatStore.emojiPickerOpen = false;

    chatStore.participantCardOpen = false;
    chatStore.participantCardUser = null;

    chatStore.composerFocusStealOverride = false;

    chatStore.showMessageContextMenu = false;
    chatStore.messageContextTimeToken = null;
  };

  const showEmojiPanel = (event: MouseEvent) => {
    // Close all open menus
    closeAllOpenMenus();

    chatStore.composerFocusStealOverride = true;
    chatStore.emojiPickerOpen = true;
    chatStore.messageContextTimeToken = props.message.timetoken;

    requestAnimationFrame(() => {
      let success = calculateTooltipPosition(event, 'chat-layer', 'floating-chat-emoji-picker', 'left');
      if (!success) {
        chatStore.composerFocusStealOverride = false;
        chatStore.emojiPickerOpen = false;
        chatStore.messageContextTimeToken = props.message.timetoken;
      }
    });
  };
</script>

<template>
  <transition-group v-if="Object.keys(chatStore.reactionsForMessage(props.message.timetoken)).length > 0" name="reaction" tag="div" class="flex gap-0.5 flex-wrap my-2">
    <button
      v-for="(users, emojiKey) in chatStore.reactionsForMessage(props.message.timetoken)"
      :key="emojiKey"
      :class="[
        hasReacted(emojiKey as string)
          ? 'bg-primary-50 dark:hover:bg-primary-700/20 border border-primary-500 dark:border-primary-600 dark:bg-primary-700/20'
          : 'bg-surface-200/50 hover:bg-white hover:border-surface-300 dark:hover:border-surface-600 dark:hover:bg-surface-900/50 border border-transparent dark:bg-surface-900/50',
      ]"
      class="flex items-center gap-x-2 rounded-md px-1 text-xs transition-transform duration-300 ease-out py-0.5"
      @contextmenu.stop.prevent
      @mouseenter="showEmojiCard($event, emojiKey as string)"
      @mouseleave="handleLeave"
      @click="react(normalizeEmojiInput(emojiKey as string))"
    >
      <div v-if="(users as any).length && (users as any).length > 0" class="pointer-events-none w-12 flex items-center">
        <div class="emojiSprite-20" :style="emojiManager.getBackgroundPosition(normalizeEmojiInput(emojiKey as string), 20)"></div>
        <span class="text-surface-500 text-xs font-semibold dark:text-white overflow-hidden text-ellipsis grow">{{ (users as any).length }}</span>
      </div>
    </button>

    <!--Button at end of Reaction List to Show Emoji Picker-->
    <button v-if="showEmojiPickerButton" class="ml-1" @click="showEmojiPanel" @contextmenu.stop.prevent>
      <svg class="size-5 group" viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="45" :class="[chatStore.emojiPickerOpen ? 'fill-[#ffcc4d]' : 'fill-[rgb(113,113,112)] group-hover:fill-[#ffcc4d]']" />
        <circle cx="35" cy="40" r="6" :class="[chatStore.emojiPickerOpen ? 'fill-black' : 'fill-white dark:fill-black group-hover:fill-black']" />
        <circle cx="65" cy="40" r="6" :class="[chatStore.emojiPickerOpen ? 'fill-black' : 'fill-white dark:fill-black group-hover:fill-black']" />
        <path d="M 30 60 Q 50 80 70 60" stroke="currentColor" stroke-width="6" fill="none" :class="[chatStore.emojiPickerOpen ? 'stroke-black' : 'stroke-white dark:stroke-black group-hover:stroke-black']" />
      </svg>
    </button>
  </transition-group>
</template>

<script setup lang="ts">
  import { computed, onMounted, ref } from 'vue';
  import { useChatStore } from '@src/store/chat';
  import { User as ChatUser } from '@pubnub/chat';
  import { calculateTooltipPosition } from '@utils/tooltip_calculator';

  const props = defineProps<{
    user: ChatUser;
  }>();

  const emits = defineEmits(['send-message']);

  const message = ref('');

  const chatStore = useChatStore();
  const tooltipRef = ref();
  const showTippy = ref(false);

  const getInitials = (name: string | undefined) => {
    if (!name) return '';
    return name
      .split(' ')
      .map((word) => word[0])
      .join('')
      .slice(0, 2);
  };

  const connectivityHandler = () => {
    // we are offline, disconnect from chat temporarily
    if (!navigator.onLine) {
      console.log('CONNECTION DOWN - DISCONNECTING FROM CHAT');
      chatStore.disconnect();
    } else {
      console.log('CONNECTION UP, INITIALIZING CHAT');
      chatStore.reinitializeChat();
    }
  };

  const isOnline = computed(() => {
    return chatStore.onlineUserIds.has(props.user.id);
  });

  const isIdle = computed(() => {
    return chatStore.idleUserIds.has(props.user.id);
  });

  const activityIconClass = computed(() => {
    return {
      'bg-green-500': isOnline.value,
      'bg-yellow-500': isIdle.value,
      'bg-surface-500': !isOnline.value && !isIdle.value,
    };
  });

  const showUserTooltip = (event: MouseEvent, user?: ChatUser) => {
    /**
     * requestAnimationFrame is used to avoid a race condition when opening a new tooltip while another is still open.
     * Without this, the old tooltip might close but the new one may not display. This ensures the old tooltip is fully
     * closed before opening the new one.
     */

    requestAnimationFrame(() => {
      if (!props.user) {
        return;
      }

      chatStore.participantCardUser = props.user;
      chatStore.composerFocusStealOverride = true;
      chatStore.participantCardOpen = true;

      /**
       * We request an additional animation frame to ensure the tooltip is in the DOM,
       * allowing us to accurately retrieve its dimensions.
       */

      requestAnimationFrame(() => {
        let success = calculateTooltipPosition(event, 'chat-layer', 'participant-card', 'left');

        // If positioning the tooltip fails for any reason, close it and reset the values.
        if (!success) {
          chatStore.participantCardUser = null;
          chatStore.composerFocusStealOverride = false;
          chatStore.participantCardOpen = false;
        }
      });
    });
  };

  onMounted(() => {
    document.addEventListener('online', connectivityHandler);
    document.addEventListener('offline', connectivityHandler);
  });
</script>

<template>
  <div>
    <button class="hover:bg-surface-200/40 dark:hover:bg-surface-700/30 rounded-md px-2 py-1 w-full" @click="showUserTooltip">
      <div class="flex items-center pointer-events-none">
        <div class="relative shrink-0">
          <img v-if="user.profileUrl" class="relative w-9 h-9 rounded-full ring-1 ring-surface-100 dark:dark:ring-surface-950 object-cover select-none shrink-0" draggable="false" :src="user.profileUrl" alt="" />
          <div v-else class="bg-surface-200 size-9 ring-1 ring-surface-100 dark:ring-surface-950 dark:bg-surface-700 rounded-full shrink-0 relative flex items-center justify-center text-sm">
            {{ getInitials(user.name) }}
          </div>
          <div :class="activityIconClass" class="border-2 size-3 rounded-full absolute -right-0.5 bottom-0 dark:border-surface-950"></div>
        </div>
        <div class="line-clamp-1 ml-2 text-left capitalize text-sm font-medium text-gray-800 dark:text-gray-300">
          {{ user.name?.toLowerCase() }}
        </div>
      </div>
    </button>
  </div>
</template>

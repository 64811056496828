<script setup lang="ts">
  import { ChevronUpIcon } from '@heroicons/vue/16/solid';
  import Button from 'primevue/button';

  const emits = defineEmits(['toggle']);

  withDefaults(
    defineProps<{
      isOpen: boolean;
      showToggle?: boolean;
    }>(),
    {
      isOpen: true,
      showToggle: true,
    }
  );

  const toggle = (event: any) => {
    emits('toggle', event);
  };
</script>

<template>
  <button class="mb-2 flex w-full select-none items-center px-2 gap-1.5 text-surface-700 dark:text-surface-400" :class="[showToggle ? 'cursor-pointer' : 'cursor-default']" :disabled="!showToggle" @click="toggle">
    <div class="size-4 text-surface-500">
      <slot name="icon" />
    </div>
    <p class="flex-grow text-left text-xs drag-handle">
      <slot name="category" />
    </p>
    <ChevronUpIcon v-if="showToggle" class="shrink-0 transform transition-transform size-5 text-surface-500 dark:text-surface-500" :class="[isOpen ? '' : 'rotate-180']" />
  </button>
</template>

<style scoped></style>

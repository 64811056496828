import { Node } from '@tiptap/core';
import { Plugin, PluginKey } from 'prosemirror-state';
import { useChatStore } from '@src/store/chat';
import { EditorView } from '@tiptap/pm/view';
import { Fragment } from 'prosemirror-model';
import { getImageSizeFromBlob } from '@utils/media';
import { TextSelection } from 'prosemirror-state';
import { v4 as uuidv4 } from 'uuid';

function insertTextUsingView(view: EditorView, text: string) {
  const { state, dispatch } = view;
  const { schema, tr: transaction, selection } = state;
  const { from, to } = selection;

  if (from < 0 || to > state.doc.content.size) {
    console.error('Invalid selection range.');
    return;
  }

  // Split the text into paragraphs by line breaks, trim and filter out empty lines.
  const lines = text.split(/\r?\n/);
  let content: any;

  if (lines.length === 1) {
    content = schema.text(lines[0]);
  } else {
    // Create paragraphs, but skip empty lines
    const nodes = lines.map((paragraph) => {
      const trimmedText = paragraph.trim();
      if (trimmedText === '') {
        // Create an empty paragraph
        return schema.nodes.paragraph.create({});
      } else {
        return schema.nodes.paragraph.create({}, schema.text(trimmedText));
      }
    });
    content = Fragment.fromArray(nodes);
  }

  const resolvedPos = state.doc.resolve(to);
  const parentNode = resolvedPos.parent;

  // Check if the editor is empty
  const isEditorEmpty = state.doc.childCount === 1 && parentNode.type.name === 'paragraph' && parentNode.content.size === 0;

  if (isEditorEmpty) {
    // Delete the empty paragraph
    transaction.delete(0, state.doc.content.size);
    // Now insert the content at position 0
    transaction.insert(0, content);
  } else {
    // Delete existing content at the selection
    transaction.delete(from, to);
    // Insert new content
    transaction.insert(from, content);
  }
  const size = content.size ? content.size : content.text.length;

  // Move the cursor to the end of the inserted content
  transaction.setSelection(TextSelection.create(transaction.doc, from + size));

  dispatch(transaction);
  view.focus();
}

const pasteHandler = async (clipboardData: DataTransfer, editor: EditorView, channelUrl: string): Promise<boolean> => {
  const chatStore = useChatStore();

  const addFileToAttachments = (fileDetails: FileUploadDetails) => {
    const attachments = chatStore.channelAttachments.get(channelUrl);
    if (!attachments) {
      chatStore.channelAttachments.set(channelUrl, [fileDetails]);
    } else {
      attachments.push(fileDetails);
    }
  };

  const createFileDetails = async (blob: Blob, fileName: string): Promise<FileUploadDetails> => {
    const mimeType = blob.type;
    const extension = mimeType.split('/')[1];

    // If fileName doesn't end with an extension, give it one
    if (!fileName.includes('.')) {
      fileName += '.' + extension;
    }

    const file = new File([blob], fileName, { type: blob.type });
    let dimensions = {
      width: 0,
      height: 0,
      originalWidth: 0,
      originalHeight: 0,
    };

    // Only do this for images
    if (mimeType.includes('image')) {
      dimensions = await getImageSizeFromBlob(blob);
    }

    let preview = '';
    if (mimeType.includes('image')) {
      preview = URL.createObjectURL(blob);
    }

    return { id: uuidv4(), file, extension, preview, dimensions };
  };

  const handleImageElement = (imgElement: Element) => {
    const dataName = imgElement.getAttribute('data-name');
    const altText = imgElement.getAttribute('alt');
    const regex = /^:.*:$/;

    if (dataName && regex.test(dataName) && altText) {
      console.info('Image is a copied emoji from our app');
      insertTextUsingView(editor, altText);
    } else {
      const src = imgElement.getAttribute('src');
      if (src) {
        try {
          fetch(src)
            .then((res) => res.blob())
            .then(async (blob) => {
              const imageName = altText ? altText : '_untiled';
              const fileDetails = await createFileDetails(blob, imageName);
              addFileToAttachments(fileDetails);
            });
        } catch (error) {
          //we don't really care about the error here, if we can't fetch the image we just don't add it
          return;
        }
      }
    }
  };

  const handleHtmlPaste = (item: DataTransferItem) => {
    item.getAsString((html: string) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      const bodyChildren = doc.body.children;
      const dataName = doc.body.getAttribute('data-name') || '';

      // Check if the value starts and ends with a colon using a regular expression
      const regex = /^:.*:$/;
      const isEmoji = regex.test(dataName);

      if (bodyChildren.length === 1 && bodyChildren[0].tagName === 'IMG' && isEmoji) {
        handleImageElement(bodyChildren[0]);
      } else {
        // browser is sending both the RAW HTML and the TEXT Version of the HTML on paste (at least in safari)
        return;
      }
    });
  };

  /**
   * insert text and put the cursor after it, with no new added paragraphs.
   *
   * @param text
   */
  const handleRawText = async (text: string) => {
    //if the text is longer than 4000 characters, we create a file and attach it
    if (text.length > 4000) {
      const blob = new Blob([text], { type: 'text/plain' });
      const fileDetails = await createFileDetails(blob, 'message.txt');
      console.log(fileDetails);

      addFileToAttachments(fileDetails);
      return;
    }
    editor.dispatch(editor.state.tr.insertText(text));
  };

  const handleImagePaste = async (item: DataTransferItem) => {
    const blob = item.getAsFile();
    if (!blob) {
      console.warn('Pasted image file blob is null, skipping');
      return;
    }

    const fileDetails = await createFileDetails(blob, blob.name || '_untiled');
    addFileToAttachments(fileDetails);
  };

  const handleFile = (item: DataTransferItem) => {
    const file = item.getAsFile();
    if (!file) {
      console.warn('Pasted file is null, skipping');
      return;
    }

    const fileDetails: FileUploadDetails = {
      id: uuidv4(),
      file,
      extension: file.name.split('.').pop() || '',
    };
    addFileToAttachments(fileDetails);
  };

  const handleText = async (text: string) => {
    const state = editor.state;
    const { from, to } = state.selection;

    if (text.length > 4000) {
      // If there's selected text, delete it
      if (from !== to) {
        const tr = state.tr.delete(from, to);
        editor.dispatch(tr);
      }

      //create a new text file and attach it
      const blob = new Blob([text], { type: 'text/plain' });
      const fileDetails = await createFileDetails(blob, 'message.txt');

      addFileToAttachments(fileDetails);
      return;
    }
    // Insert the content if it is within the limit
    // Create a text node.
    insertTextUsingView(editor, text);
  };

  const handleTextPaste = (item: DataTransferItem) => {
    item.getAsString(async (text: string) => {
      handleText(text).catch((error) => {
        console.error(error);
      });
    });
  };

  //@ts-ignore
  for (const item: DataTransferItem of clipboardData.items) {
    if (item.type === 'text/html') {
      console.info('Handling HTML paste');
      handleHtmlPaste(item);
    } else if (item.type === 'text/plain') {
      console.log('handling text plain');
      item.getAsString((text: any) => {
        handleRawText(text);
      });
    } else if (item.type.startsWith('image/')) {
      console.info('Handling image paste from file');
      await handleImagePaste(item);
    } else if (item.type.includes('image/')) {
      console.info('Handling file paste');
      handleFile(item);
    }
  }

  return true;
};

export default Node.create({
  name: 'pasteHandler',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('pasteHandler'),

        props: {
          handlePaste(editor, event) {
            event.preventDefault();
            const chatStore = useChatStore();
            const channelUrl = chatStore.currentChannelUrl;

            const { state } = editor;
            const { selection } = state;
            const { $from } = selection;
            const currentNode = $from.node();

            if (currentNode.type.name === 'codeBlock' || currentNode.type.name === 'code') {
              console.log('Pasting inside a <code> block:', currentNode);
              return false; // Indicate that the event was not handled use default paste
            }

            const clipboardData = event.clipboardData;
            if (!clipboardData) {
              console.error('Clipboard data is not available.');
              return false; // Indicate that the event was not handled use default paste
            }

            // Call your custom paste handler
            pasteHandler(clipboardData, editor, channelUrl).catch((error) => {
              console.error('Paste Error');
              console.error(error);
            });

            return true; // Indicate that the event was handled
          },
        },
      }),
    ];
  },
});

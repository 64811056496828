<script setup lang="ts">
  import InputText from 'primevue/inputtext';
  import { User as ChatUser } from '@pubnub/chat';
  import { computed, onMounted, ref } from 'vue';
  import { useChatStore } from '@src/store/chat';

  const emits = defineEmits(['close', 'send-message']);
  const chatStore = useChatStore();
  const message = ref('');
  const msgInput = ref<HTMLElement>();

  const user = computed((): ChatUser | null => {
    return chatStore.participantCardUser as ChatUser | null;
  });

  const isOnline = computed(() => {
    if (!user.value) {
      return false;
    }
    return chatStore.onlineUserIds.has(user.value.id);
  });

  const isIdle = computed(() => {
    if (!user.value) {
      return false;
    }
    return chatStore.idleUserIds.has(user.value.id);
  });

  const activityIconClass = computed(() => {
    return {
      'bg-green-500': isOnline.value,
      'bg-yellow-500': isIdle.value,
      'bg-surface-500': !isOnline.value && !isIdle.value,
    };
  });
  const getInitials = (name: string | undefined) => {
    if (!name) return '';
    return name
      .split(' ')
      .map((word) => word[0])
      .join('')
      .slice(0, 2);
  };

  const groupAndTeams = (user: ChatUser): string => {
    let group: string = (user.custom?.group as string) || '';
    let teams: string = (user.custom?.team_names as string) || '';

    if (group.endsWith(',')) {
      group = group.slice(0, -1);
    }
    if (teams.endsWith(',')) {
      teams = teams.slice(0, -1);
    }

    return [group, teams].filter(Boolean).join(' ');
  };

  const closeTooltip = () => {
    emits('close');
  };
  const sendMessage = () => {
    emits('send-message', { user: user.value, message: message.value });
    closeTooltip();
  };

  onMounted(() => {
    if (msgInput.value) {
      console.log('focusing');
      msgInput.value.$el.focus();
    }
  });
</script>

<template>
  <div v-if="user" class="bg-white w-72 text-black shadow-md dark:bg-surface-800 ring-[4px] ring-surface-300 dark:ring-surface-950 dark:shadow-none rounded-md select-auto">
    <div class="w-full h-full z-20">
      <div class="bg-surface-200 dark:bg-surface-900 h-24"></div>
      <div class="p-4 space-y-4 -mt-16">
        <div class="relative w-fit">
          <img v-if="user.profileUrl" class="relative w-20 h-20 rounded-full ring-[6px] ring-white dark:ring-surface-950 object-cover select-none shrink-0" draggable="false" :src="user.profileUrl" alt="" />
          <div v-else class="bg-surface-200 w-20 h-20 ring-6 ring-[6px] ring-white dark:ring-surface-950 dark:bg-surface-700 rounded-full shrink-0 relative flex items-center text-2xl justify-center dark:text-surface-200">
            {{ getInitials(user.name) }}
          </div>
          <div :class="activityIconClass" class="border-2 size-6 rounded-full absolute -right-0.5 bottom-0 dark:border-surface-950"></div>
        </div>
        <div class="p-1 text-left">
          <div class="line-clamp-1 capitalize text-lg font-semibold dark:text-surface-300">
            {{ user.name?.toLowerCase() }}
          </div>
          <div class="text-xs dark:text-surface-400">
            {{ groupAndTeams(user) }}
          </div>
        </div>
        <div>
          <InputText ref="msgInput" v-model="message" size="large" :placeholder="`Message @${user.name}`" class="w-full text-sm" @keydown.enter="sendMessage" @keydown.esc="closeTooltip()" />
        </div>
      </div>
    </div>
  </div>
</template>

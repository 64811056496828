/* eslint-disable prettier/prettier */
import { useAlertStore } from "@src/store/alert";
import { useAppStateStore } from "@src/store/app";
import { useTimerStore } from "@src/store/timer";
import { invoke } from "@tauri-apps/api/core";
import { Logger } from "@utils/logger";
import { defineStore } from "pinia";

const log = new Logger("STORE:ACTIVEJOB");

function getInitialState() {
  return {
    activeJob: null as ActiveJob | null,
    additionalDetails: {
      onBreak: false as boolean,
      beRightBack: false as boolean,
      inMeeting: false as boolean,
      clockingIn: false,

      client: null as Client | null,
      refreshing: false as boolean,
      availablePto: 0 as number,
      seniorTeamLead: null as Contact | null,
      juniorTeamLead: null as Contact | null,
      screenshotsTaken: 0 as number,
    },
  };
}
export const useActiveJobStore = defineStore("activejob", {
  state: () => getInitialState(),
  getters: {
    hasActiveJob: (state) => {
      return state.activeJob !== null;
    },
    saved: (state) => {
      return state.activeJob?.saved === true;
    },
    shouldTrackWebsites: (state) => {
      return (
        state.activeJob &&
        state.activeJob.trackerEnabled &&
        state.activeJob.trackWebsites
      );
    },
    shouldTakeScreenshots: (state) => {
      return (
        state.activeJob &&
        state.activeJob.trackerEnabled &&
        state.activeJob.trackScreenshots
      );
    },
    shouldTrackProcesses: (state) => {
      return (
        state.activeJob &&
        state.activeJob.trackerEnabled &&
        state.activeJob.trackProcesses
      );
    },
    shouldTrackApplications: (state) => {
      return (
        state.activeJob &&
        state.activeJob.trackerEnabled &&
        state.activeJob.trackProcesses
      );
    },
    isClockedIn: (state) => {
      return ["active", "late active"].includes(
        state.activeJob?.decoratedStatus?.toLowerCase() ?? "",
      );
    },
    canClockIn: (state) => {
      return ["yet to start", "late"].includes(
        state.activeJob?.decoratedStatus?.toLowerCase() ?? "",
      );
    },
    isClockedOut: (state) => {
      return [
        "break",
        "off the clock",
        "pto",
        "pto (mod)",
        "upto (mod)",
        "absent: no activity",
        "undertime",
        "late",
        "rest day",
        "system logged out",
      ].includes(state.activeJob?.decoratedStatus?.toLowerCase() ?? "");
    },
    canUseBrb: (state) => {
      return (
        ["active", "late active"].includes(
          state.activeJob?.status?.toLowerCase() ?? "",
        ) && !state.additionalDetails.beRightBack
      );
    },
    canReturnFromBrb: (state) => {
      return state.additionalDetails.beRightBack;
    },
    canGoOnBreak: (state) => {
      return (
        ["active", "late active"].includes(
          state.activeJob?.status?.toLowerCase() ?? "",
        ) && !state.additionalDetails.onBreak
      );
    },
    canReturnFromBreak: (state) => {
      return state.additionalDetails.onBreak;
    },
    isOffTheClock: (state) => {
      return ["off the clock"].includes(
        state.activeJob?.decoratedStatus?.toLowerCase() ?? "",
      );
    },
    isLate: (state) => {
      return ["late", "late active"].includes(
        state.activeJob?.status?.toLowerCase() ?? "",
      );
    },
    timeOnBreak: (state) => {
      // foreach this.activeJob.breaks, return duration between time_from, time_to
      if (!state.activeJob) return "0";
      const total_seconds_on_break = state.activeJob.breaks.reduce(
        (acc, cur) => {
          if (cur.time_to) {
            acc +=
              new Date(cur.time_to).getTime() -
              new Date(cur.time_from).getTime();
          }
          return acc;
        },
        0,
      );

      return (total_seconds_on_break / 1000 / 60).toFixed(2);
    },
  },
  actions: {
    async refresh() {
      const appStateStore = useAppStateStore();
      try {
        const data = await appStateStore.api.getActiveJob();
        this.activeJob = data.activeJob;
        this.additionalDetails.onBreak = this.activeJob?.status === "Break";
        this.additionalDetails.client = data.client;
        this.additionalDetails.availablePto = data.flexiDuration;
        this.additionalDetails.seniorTeamLead = data.seniorTeamLead;
        this.additionalDetails.juniorTeamLead = data.juniorTeamLead;
      } catch (e) {
        const alertStore = useAlertStore();
        alertStore.addAlerts(["Failed to contact MyTimeIn"]);
        throw e;
      }
    },
    async startBreak() {
      const appStateStore = useAppStateStore();
      if (!this.activeJob) {
        return;
      }
      try {
        this.activeJob =
          (await appStateStore.api.startBreak()) as unknown as ActiveJob;
        this.additionalDetails.onBreak = true;
      } catch (e) {
        log.error("Failed to start break");
        log.error(e);
      }
    },
    async endBreak() {
      const appStateStore = useAppStateStore();
      const timerStore = useTimerStore();
      try {
        this.additionalDetails.onBreak = false;
        this.activeJob =
          (await appStateStore.api.endBreak()) as unknown as ActiveJob;
        timerStore.overBreakSnoozeReturnTime = null;
      } catch (e) {
        log.error("Failed to end break");
        log.error(e);
      }
    },
    async startShift(payload: { message: string; jobId?: number }) {
      payload.jobId = this.activeJob?.jobId;
      const appStateStore = useAppStateStore();
      try {
        this.activeJob = (await appStateStore.api.startShift(
          payload as ShiftEvent,
        )) as unknown as ActiveJob;
        await invoke("clear_data");
      } catch (e) {
        log.error("Failed to start shift");
        log.error(e);
      }
    },
    async endShift(payload: { message: string; jobId?: number }) {
      payload.jobId = this.activeJob?.jobId;
      const appStateStore = useAppStateStore();
      const timerStore = useTimerStore();
      try {
        this.activeJob = (await appStateStore.api.endShift(
          payload as ShiftEvent,
        )) as unknown as ActiveJob;
        timerStore.$reset();
        await invoke("clear_data");
      } catch (e) {
        log.error("Failed to end shift");
        log.error(e);
      }
    },
    async changeBrbState(flag: boolean) {
      this.additionalDetails.beRightBack = flag;
    },
  },
});

<script setup lang="ts">
  import { nextTick, onMounted, ref, watch } from 'vue';
  import { User as ChatUser } from '@pubnub/chat';
  import Button from 'primevue/button';
  import FormLabel from '@components/Shared/FormLabel.vue';
  import Dialog from 'primevue/dialog';
  import InputText from 'primevue/inputtext';
  import ChatRemoveUserButton from '@components/Chat/ChatRemoveUserButton.vue';
  import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline';
  import { useAppStateStore } from '@src/store/app';
  import { debounce } from 'lodash';

  defineProps<{
    visible: boolean;
  }>();

  const appStore = useAppStateStore();
  const emit = defineEmits(['create', 'close']);
  const searchQuery = ref('');
  const createChannelInput = ref<HTMLElement>();
  const availableResults = ref<ChatUser[]>([]);

  watch(
    searchQuery,
    debounce(() => {
      appStore.api.searchChatUsers(searchQuery.value).then((response) => {
        availableResults.value = response.users.map((user: any) => {
          return {
            id: user.id,
            name: user.chat_nickname,
            profileUrl: user.avatar,
            custom: {},
          };
        });
      });
    }, 500)
  );

  const close = () => {
    emit('close');
  };

  const createChannelForm = ref({
    name: '',
    participants: [] as ChatUser[],
  });

  const addParticipant = (user: ChatUser) => {
    createChannelForm.value.participants.push(user);
  };

  const removeParticipant = (user: ChatUser) => {
    createChannelForm.value.participants = createChannelForm.value.participants.filter((participant) => participant.id !== user.id);
  };

  const isParticipating = (user: ChatUser) => {
    return createChannelForm.value.participants.some((participant) => participant.id === user.id);
  };

  const getInitials = (name: string | undefined) => {
    if (!name) return '';
    return name
      .split(' ')
      .map((word) => word[0])
      .join('')
      .slice(0, 2);
  };

  const groupAndTeams = (user: ChatUser): string => {
    let group: string = '';
    let teams: string = (user.custom?.team_names as string) || '';

    if (group.endsWith(',')) {
      group = group.slice(0, -1);
    }
    if (teams.endsWith(',')) {
      teams = teams.slice(0, -1);
    }

    return [group, teams].filter(Boolean).join(' ');
  };

  onMounted(() => {
    nextTick(() => {
      if (createChannelInput.value) {
        createChannelInput.value.$el.focus();
      }
    });
  });
</script>

<template>
  <Dialog :visible="visible" header="Start Conversation" modal :draggable="false" class="dark:text-surface-300 md:w-2/3 xl:w-1/3 select-none" block-scroll @update:visible="close">
    <FormLabel class="mt-4">Members</FormLabel>
    <div class="relative">
      <InputText ref="createChannelInput" v-model="searchQuery" placeholder="Search for users" autocomplete="false" class="w-full pr-10" />
      <MagnifyingGlassIcon class="size-6 absolute top-2 right-2 text-surface-400" />
    </div>
    <hr class="-mx-6 my-4 border-t dark:border-surface-700" />
    <div class="max-h-48 overflow-y-auto pr-1 space-y-3 small-scroll">
      <div v-for="user in availableResults" :key="user.id" class="flex w-full items-center rounded-md px-3 py-1 group hover:bg-surface-100 dark:hover:bg-surface-700/30">
        <div class="flex grow items-center gap-x-2">
          <img v-if="user.profileUrl" :src="user.profileUrl" class="rounded-full size-8" :alt="`${user.name}'s profile picture`" />
          <div v-else class="flex items-center justify-center rounded-full outline outline-1 size-8 bg-surface-200 outline-surface-400 dark:bg-surface-700 dark:outline-black">
            {{ getInitials(user.name) }}
          </div>
          <div class="text-sm font-light dark:text-surface-400">
            <p>{{ user.name }}</p>
            <p v-if="user.custom" class="text-xs">
              {{ groupAndTeams(user as ChatUser) }}
            </p>
          </div>
        </div>
        <button
          v-if="!isParticipating(user as ChatUser)"
          class="rounded-md border px-6 py-2 text-xs border-primary-500 group-hover:bg-primary-500 hover:bg-primary-500 hover:text-white group-hover:text-white dark:border-primary-700 dark:group-hover:bg-primary-700"
          @click="addParticipant(user as ChatUser)"
        >
          Invite
        </button>
        <ChatRemoveUserButton v-else @click="removeParticipant(user as ChatUser)" />
      </div>
    </div>
    <div class="-m-6 mt-4 px-6 pt-3 pb-6 bg-surface-100 dark:bg-surface-800">
      <div class="text-right">
        <Button :disabled="createChannelForm.participants.length < 1" class="mt-4" @click="emit('create', createChannelForm)">Start Chat</Button>
      </div>
    </div>
  </Dialog>
</template>

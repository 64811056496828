<script setup lang="ts">
  import { useAppStateStore } from '@src/store/app';
  import { computed, onBeforeMount } from 'vue';
  import { EmojiManager } from '@utils/emoji_manager';

  const emojiManager = EmojiManager.getInstance();

  const props = defineProps<{
    open: boolean;
  }>();

  const appStore = useAppStateStore();

  const emits = defineEmits(['open']);

  const toggleOpen = (flag: boolean) => {
    emits('open', { open: flag });
  };
  const setSkinTone = (variation: SkinVar | undefined = undefined) => {
    // default yellow variation, bail because that's default for some reason no idea who decided that
    if (!variation) {
      EmojiManager.getInstance().setSkinTone(undefined);
      appStore.settings.emojiSkinTone = undefined;
      appStore.saveSettings();
      toggleOpen(false);
      return;
    }
    if (handFingersOpen.skin_variations && handFingersOpen.skin_variations[variation as SkinVar]) {
      EmojiManager.getInstance().setSkinTone(variation);
      appStore.settings.emojiSkinTone = variation;
      appStore.saveSettings();
      toggleOpen(false);
      return;
    }
    appStore.settings.emojiSkinTone = undefined;
    appStore.saveSettings();
    toggleOpen(false);
  };

  const handFingersOpen: Emoji = {
    name: 'HAND WITH FINGERS SPLAYED',
    unified: '1F590-FE0F',
    non_qualified: '1F590',
    docomo: null,
    au: null,
    softbank: null,
    google: null,
    image: '1f590-fe0f.png',
    sheet_x: 32,
    sheet_y: 2,
    short_name: 'raised_hand_with_fingers_splayed',
    short_names: ['raised_hand_with_fingers_splayed'],
    text: null,
    texts: null,
    category: 'People & Body',
    subcategory: 'hand-fingers-open',
    sort_order: 171,
    added_in: '0.7',
    has_img_apple: true,
    has_img_google: true,
    has_img_twitter: true,
    has_img_facebook: true,
    skin_variations: {
      '1F3FB': {
        unified: '1F590-1F3FB',
        non_qualified: null,
        image: '1f590-1f3fb.png',
        sheet_x: 32,
        sheet_y: 3,
        added_in: '1.0',
        has_img_apple: true,
        has_img_google: true,
        has_img_twitter: true,
        has_img_facebook: true,
      },
      '1F3FC': {
        unified: '1F590-1F3FC',
        non_qualified: null,
        image: '1f590-1f3fc.png',
        sheet_x: 32,
        sheet_y: 4,
        added_in: '1.0',
        has_img_apple: true,
        has_img_google: true,
        has_img_twitter: true,
        has_img_facebook: true,
      },
      '1F3FD': {
        unified: '1F590-1F3FD',
        non_qualified: null,
        image: '1f590-1f3fd.png',
        sheet_x: 32,
        sheet_y: 5,
        added_in: '1.0',
        has_img_apple: true,
        has_img_google: true,
        has_img_twitter: true,
        has_img_facebook: true,
      },
      '1F3FE': {
        unified: '1F590-1F3FE',
        non_qualified: null,
        image: '1f590-1f3fe.png',
        sheet_x: 32,
        sheet_y: 6,
        added_in: '1.0',
        has_img_apple: true,
        has_img_google: true,
        has_img_twitter: true,
        has_img_facebook: true,
      },
      '1F3FF': {
        unified: '1F590-1F3FF',
        non_qualified: null,
        image: '1f590-1f3ff.png',
        sheet_x: 32,
        sheet_y: 7,
        added_in: '1.0',
        has_img_apple: true,
        has_img_google: true,
        has_img_twitter: true,
        has_img_facebook: true,
      },
    },
  };

  // Filtered skin variations, excluding the selected tone
  const filteredSkinVariations = computed(() => {
    return Object.entries(handFingersOpen.skin_variations as Record<SkinVar, SkinVariation>)
      .filter(([key]) => key !== appStore.settings.emojiSkinTone) // Exclude the selected tone
      .reduce(
        (acc, [key, variation]) => {
          let skinVarKey = key as SkinVar; // Cast key to SkinVar type
          let skinVariation = variation as SkinVariation; // Cast value to SkinVariation type
          acc[skinVarKey] = skinVariation;
          return acc;
        },
        {} as Record<SkinVar, SkinVariation>
      );
  });

  const currentUnified = computed(() => {
    if (!handFingersOpen.skin_variations) {
      return '1F590-FE0F';
    }
    return appStore.settings.emojiSkinTone ? handFingersOpen.skin_variations[appStore.settings.emojiSkinTone].unified : handFingersOpen.unified;
  });

  onBeforeMount(() => {
    if (!EmojiManager.getValidSkinTones().includes(appStore.settings.emojiSkinTone)) {
      appStore.settings.emojiSkinTone = undefined;
    }
  });
</script>

<template>
  <div>
    <button :key="appStore.settings.emojiSkinTone ?? 'skin-tone-default'" class="skin-tone p-1" tabindex="0" @click.stop="toggleOpen(!open)">
      <div class="emojiSprite-32" :style="emojiManager.getBackgroundPosition(currentUnified, 32, true)"></div>
    </button>
    <!--Needs to be a v-if because of a weird bug with v-click-away and v-show-->
    <div v-show="open" class="absolute top-10 -left-[1px] bg-white shadow-md dark:bg-surface-800 flex flex-col justify-center space-y-0.5">
      <transition-group enter-active-class="transition ease-in transform duration-320" enter-from-class="-translate-y-full opacity-0" enter-to-class="translate-y-0 opacity-100">
        <button v-if="appStore.settings.emojiSkinTone" class="p-1 hover:bg-surface-100 dark:hover:bg-surface-700" @click="setSkinTone()">
          <div class="emojiSprite-32" :style="emojiManager.getBackgroundPosition(handFingersOpen.unified, 32, true)" />
        </button>
        <button v-for="(variation, key) in filteredSkinVariations" :key="'skin-tone-color-' + key" class="p-1 hover:bg-surface-100 dark:hover:bg-surface-700" @click="setSkinTone(key)">
          <div class="emojiSprite-32" :style="emojiManager.getBackgroundPosition(variation.unified, 32, true)" />
        </button>
      </transition-group>
    </div>
  </div>
</template>

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import ConfirmationService from 'primevue/confirmationservice';
import VueClickAway from 'vue3-click-away';
import * as Sentry from '@sentry/vue';
import { makeRouter } from '@src/router';
import { TauriSyncPlugin } from '@src/store/tauri_sync';
import { SentinelKey, SentryKey, MarkdownServiceKey } from '@utils/symbols';
import { Sentinel } from '@src/sentinel';
import { MarkdownService } from '@utils/markdown_service';

// @ts-ignore
import mti from './presets/mytimeinapp';
import 'primeicons/primeicons.css';
import './style.css';
import { emojiDirective } from '@src/directives/emoji';

const pinia = createPinia();
pinia.use(TauriSyncPlugin);

const app = createApp(App).use(pinia).use(VueClickAway).use(ConfirmationService);

const router = makeRouter();
app.use(router);

// Setup our watcher process which hooks down into our main process
app.provide(SentinelKey, new Sentinel());
app.provide(MarkdownServiceKey, new MarkdownService());
app.directive('emoji', emojiDirective);

if (import.meta.env.MODE !== 'development') {
  Sentry.init({
    app,
    dsn: 'https://57d244b9166a44329684641399688a8b@o29515.ingest.sentry.io/4505354254548992',
    ignoreErrors: ['Failed to fetch dynamically imported module', 'Importing a module script failed', 'Aborting all operations', 'PubNubError'],
    // dsn: '',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
      Sentry.feedbackIntegration({
        // Additional SDK configuration goes in here, for example:
        colorScheme: 'system',
        isNameRequired: true,
        isEmailRequired: true,
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,

    tracePropagationTargets: ['localhost'],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
  app.provide(SentryKey, Sentry);
}
// Configure PrimeVue for our UI layer
app.use(PrimeVue, { ripple: true, unstyled: true, pt: mti });
app.use(ToastService);
app.directive('tooltip', Tooltip);

// On page load or when changing themes, best to add inline in `head` to avoid FOUC
const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
const theme = localStorage.theme || (prefersDarkScheme ? 'dark' : '');

document.documentElement.classList.toggle('dark', theme === 'dark');
app.mount('#app');

window.addEventListener('vite:preloadError', (event) => {
  event.preventDefault();
  //this is how you clear cache now hard reload
  window.location.href = `${window.location.pathname}?timestamp=${new Date().getTime()}`;
  // window.location.reload();
});

import { debug, error, info, warn } from '@tauri-apps/plugin-log';
import { DateTime } from 'luxon';

class Logger {
  readonly logGroupName: string;

  constructor(logGroupName: string) {
    this.logGroupName = `[${logGroupName}]`;
  }

  private async logMessage(logFunc: (msg: string) => Promise<void>, consoleFunc: (...args: any[]) => void, ...messages: unknown[]) {
    const formattedMessage = messages.map((msg) => (typeof msg === 'string' ? msg : JSON.stringify(msg))).join(' ');
    const timestamp = `[${DateTime.now().toLocaleString(DateTime['TIME_WITH_SECONDS'])}]`;

    try {
      await logFunc(formattedMessage);
    } catch (e) {
      console.error(e);
    }
    consoleFunc(`${timestamp}${this.logGroupName}:`, ...messages);
  }

  info(...messages: unknown[]) {
    this.logMessage(info, console.info, ...messages).catch(() => {});
  }

  error(...messages: unknown[]) {
    messages.push(window.location.href);
    this.logMessage(error, console.error, ...messages).catch(() => {});
  }

  warn(...messages: unknown[]) {
    this.logMessage(warn, console.warn, ...messages).catch(() => {});
  }

  debug(...messages: unknown[]) {
    this.logMessage(debug, console.debug, ...messages).catch(() => {});
  }
}

export { Logger };

export const waitFor = (conditionFunction: () => boolean, timeout = 10000) => {
  const poll = (resolve: any) => {
    if (conditionFunction()) {
      resolve();
    } else {
      setTimeout(() => {
        poll(resolve);
      }, 100);
    }
  };

  return new Promise(poll);
};

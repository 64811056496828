import { Node } from '@tiptap/core';
import Suggestion from '@tiptap/suggestion';
import { mergeAttributes, VueRenderer } from '@tiptap/vue-3';
import EmojiInlinePicker from '@components/EmojiPicker/EmojiInlinePicker.vue';
import tippy, { Instance as TippyInstance, Props } from 'tippy.js';
import { EmojiManager } from '@src/utils/emoji_manager';

const unicodeToEmoji = (unicode: string) => {
  const codePoints = unicode.split('-').map((u) => parseInt(u, 16));
  return String.fromCodePoint(...codePoints);
};

export default Node.create({
  name: 'emojiSuggestion',

  addOptions() {
    return {
      deleteTriggerWithBackspace: true,
      renderHTML({ options, node }: { options: any; node: any }) {
        return ['span', mergeAttributes({ class: '' }, options.HTMLAttributes), `${node.attrs.emoji.character}`];
      },
      suggestion: {
        char: ':',
        command: ({ editor, range, props }: { editor: any; range: any; props: any }) => {
          const nodeAfter = editor.view.state.selection.$to.nodeAfter;
          const overrideSpace = nodeAfter?.text?.startsWith(' ');

          if (overrideSpace) {
            range.to += 1;
          }

          editor
            .chain()
            .focus()
            .insertContentAt(range, [
              {
                type: this.name,
                attrs: props,
              },
              {
                type: 'text',
                text: unicodeToEmoji(props.emoji.unified),
              },
              {
                type: 'text',
                text: ' ',
              },
            ])
            .run();

          window.getSelection()?.collapseToEnd();
        },
        render: () => {
          let component: VueRenderer;
          let popup: TippyInstance<Props>;
          const emojiManager = EmojiManager.getInstance();
          const emojiCategories = emojiManager.getUiGroups();
          const emojiMap = emojiManager.getDefaultEmojiMap();

          const closePopup = () => {
            component.editor.chain().closeEmojisInlinePanel();
            component.destroy();
          };

          const getEmojis = (query: string): Emoji[] => {
            const filteredEmojis: Emoji[] = [];
            if (!emojiCategories.length) {
              return [];
            }
            for (const [key, emoji] of emojiMap) {
              if (emoji.short_name?.includes(':' + query)) {
                filteredEmojis.push(emoji);
              }
              if (emoji.short_names) {
                emoji.short_names?.forEach((name) => {
                  if (name.toLowerCase().includes(query)) {
                    if (!filteredEmojis.find((e) => e.unified === emoji.unified)) {
                      //only add if it's not there already
                      filteredEmojis.push(emoji);
                    }
                  }
                });
              }
            }
            return filteredEmojis;
          };

          const setPopupWidth = (instance: TippyInstance<Props>) => {
            const editorElement = document.getElementById('message-editor');

            const tippyBox = instance.popper.querySelector('.tippy-box') as HTMLElement;
            if (tippyBox) {
              tippyBox.style.maxWidth = 'unset'; // Unset the max-width property
            }

            if (editorElement) {
              instance.popper.style.width = `${editorElement.getBoundingClientRect().width}px`;
              instance.popper.style.maxWidth = 'unset';
            }
          };

          return {
            onStart: (props: any) => {
              component = new VueRenderer(EmojiInlinePicker, {
                props: { ...props, items: getEmojis(props.query) },
                editor: props.editor,
              });

              if (!props.clientRect) {
                return;
              }

              //@ts-ignore
              popup = tippy(document.getElementById('composer-container') as Element, {
                appendTo: () => document.body,
                content: component.element,
                showOnCreate: true,
                interactive: true,
                trigger: 'manual',
                placement: 'top',
                onShow: (instance) => {
                  props.editor.chain().openEmojisInlinePanel();
                  setPopupWidth(instance);
                },
                onHide: () => {},
              });

              // Handle window resize to adjust popup width
              window.addEventListener('resize', () => setPopupWidth(popup));
            },

            onUpdate(props: any) {
              const { query } = props;
              const filteredEmojis = getEmojis(query);
              component.updateProps({ ...props, items: filteredEmojis });
            },

            onKeyDown(props: any) {
              if (props.event.key === 'Escape') {
                closePopup();
              }
              return component.ref?.onKeyDown(props);
            },

            onExit() {
              closePopup();
            },
          };
        },
      },
    };
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
        //lets us type 2 characters after the colon before the suggestion is shown
        allow: ({ state, range }: { state: any; range: any }) => {
          const text = state.doc.textBetween(range.from, range.to);
          return text.length > 2; // ':' + 2 characters
        },
      }),
    ];
  },
});

<script setup lang="ts">
  import PinIcon from '@components/svgs/PinIcon.vue';
  import UnPinIcon from '@components/svgs/UnPinIcon.vue';
  import { PencilSquareIcon, TrashIcon } from '@heroicons/vue/24/solid';
  import { ArrowUturnLeftIcon, ClipboardDocumentIcon, ArrowPathIcon } from '@heroicons/vue/24/outline';
  import { useChatStore } from '@src/store/chat';
  import { useConfirm } from 'primevue/useconfirm';
  import { useAppStateStore } from '@src/store/app';
  import { computed, onMounted, ref } from 'vue';
  import { Message } from '@pubnub/chat';
  import { EmojiManager } from '@utils/emoji_manager';

  const emojiManager = EmojiManager.getInstance();
  const chatStore = useChatStore();
  const appStore = useAppStateStore();
  const confirm = useConfirm();
  const appState = useAppStateStore();

  const isTextSelected = ref(false);

  const isOwnMessage = computed((): boolean => {
    const messages = chatStore.currentChannelMessages;
    const message = messages.find((m: Message) => m.timetoken === (chatStore.messageContextTimeToken as string));
    if (!message) {
      return false;
    }
    return message.userId === chatStore.chatUserId;
  });

  const canUnpin = computed(() => {
    return chatStore.currentChannelPinnedTimetokens.includes(chatStore.messageContextTimeToken as string);
  });

  const canPin = computed(() => {
    return chatStore.currentChannelUrl.startsWith('direct.') || chatStore.currentChannelUrl.startsWith('group.');
  });

  const closeMenu = () => {
    chatStore.messageContextTimeToken = null;
    chatStore.showMessageContextMenu = false;
  };

  const deleteMessage = () => {
    chatStore.showMessageContextMenu = false;

    confirm.require({
      message: 'Are you sure you want to delete this message?',
      header: 'Delete message?',
      blockScroll: true,
      rejectLabel: 'Cancel',
      acceptLabel: 'Delete',
      group: 'message-delete',
      accept: () => {
        chatStore.deleteMessage(chatStore.messageContextTimeToken as string);
      },
    });
  };
  const pinMessage = () => {
    chatStore.pinMessage(chatStore.messageContextTimeToken as string);
    closeMenu();
  };

  const unpinMessage = () => {
    chatStore.removePin(chatStore.messageContextTimeToken as string);
    closeMenu();
  };

  const editMessage = () => {
    chatStore.startEditingMessage(chatStore.messageContextTimeToken as string);
    closeMenu();
  };

  const replyMessage = () => {
    chatStore.replyToMessage(chatStore.messageContextTimeToken as string);
    closeMenu();
  };

  const copyText = () => {
    const messages = chatStore.currentChannelMessages;
    const message = messages.find((m: Message) => m.timetoken === (chatStore.messageContextTimeToken as string));
    if (!message) {
      return;
    }
    navigator.clipboard.writeText(message.text);
    closeMenu();
  };

  const top4MostUsedEmojis = computed(() => {
    //get the top 3 most used emojis
    const entries = appStore.recentlyUsedEmojis;
    return entries.slice(0, 4);
  });

  const unicodeToEmoji = (unicode: string) => {
    const codePoints = unicode.split('-').map((u) => parseInt(u, 16));
    return String.fromCodePoint(...codePoints);
  };

  const applyReaction = (emojiString: string) => {
    let emoji = emojiManager.getEmojiMap().get(emojiString);
    if (!emoji) {
      closeMenu();
      return;
    }

    const skinTonePreference = appStore.settings.emojiSkinTone;
    if (emoji.skin_variations && skinTonePreference && emoji.skin_variations[skinTonePreference]) {
      emoji.unified = emoji.skin_variations[skinTonePreference].unified;
    }

    appStore.updateRecentlyUsedEmojis(emoji.unified);
    chatStore.applyReactionToMessage(chatStore.messageContextTimeToken as string, unicodeToEmoji(emoji.unified));
    closeMenu();
  };

  const checkTextSelection = () => {
    const selectedText = window.getSelection()?.toString();
    if (!selectedText) {
      return false;
    }
    return selectedText.length > 0;
  };

  const isWindows = () => {
    return navigator.userAgent.toLowerCase().includes('windows');
  };

  const copySelection = () => {
    const selectedText = window.getSelection()?.toString();
    if (!selectedText) {
      return;
    }
    navigator.clipboard.writeText(selectedText);
    closeMenu();
  };

  const reload = () => {
    closeMenu();
    window.location.reload();
  };

  onMounted(() => {
    isTextSelected.value = checkTextSelection();
  });
</script>

<template>
  <div class="flex w-48 flex-col gap-2 rounded-md border p-2 text-xs shadow-lg bg-surface-0 border-surface-100 dark:bg-surface-950 dark:border-transparent">
    <div v-if="isTextSelected" class="border-b pb-2 border-surface-100 dark:border-surface-700">
      <button class="flex w-full items-center rounded p-2 text-left text-surface-800 hover:bg-primary-500 hover:text-white dark:text-surface-300" @click="copySelection">
        <div class="grow">Copy</div>
        <div class="flex items-center gap-1">
          <div class="text-xs font-bold">
            {{ isWindows() ? 'CTRL' : 'CMD' }}
          </div>
          <span>-</span>
          <div>C</div>
        </div>
      </button>
    </div>
    <div v-if="top4MostUsedEmojis.length > 0" class="flex justify-center gap-2">
      <button
        v-for="(emojiString, index) in top4MostUsedEmojis"
        :key="`context-menu-${emojiString}-${index}`"
        class="rounded-full border bg-surface-200/50 border-surface-200 p-1.5 hover:bg-surface-100 dark:bg-surface-800 dark:border-transparent dark:hover:bg-surface-700"
        @click="applyReaction(emojiString)"
      >
        <div class="emojiSprite-20" :style="emojiManager.getBackgroundPosition(emojiString, 20)"></div>
      </button>
    </div>
    <button v-if="isOwnMessage" class="flex items-center rounded p-2 text-left text-surface-800 hover:bg-primary-500 hover:text-white dark:text-surface-300" @click="editMessage">
      <div class="grow">Edit Message</div>
      <PencilSquareIcon class="size-4" />
    </button>
    <button v-if="canPin && !canUnpin" class="flex items-center rounded p-2 text-left text-surface-800 hover:bg-primary-500 hover:text-white dark:text-surface-300" @click="pinMessage">
      <div class="grow">Pin Message</div>
      <PinIcon class="size-4" />
    </button>
    <button v-if="canUnpin" class="flex items-center rounded p-2 text-left text-surface-800 hover:bg-primary-500 hover:text-white dark:text-surface-300" @click="unpinMessage">
      <div class="grow">Unpin Message</div>
      <UnPinIcon class="size-4" />
    </button>
    <button class="flex items-center rounded p-2 text-left text-surface-800 hover:bg-primary-500 hover:text-white dark:text-surface-300" @click="replyMessage">
      <div class="grow">Reply</div>
      <ArrowUturnLeftIcon class="size-4" />
    </button>
    <button class="flex items-center rounded p-2 text-left text-surface-800 hover:bg-primary-500 hover:text-white dark:text-surface-300" @click="copyText">
      <div class="grow">Copy Message</div>
      <ClipboardDocumentIcon class="size-4" />
    </button>
    <button v-if="isOwnMessage" class="flex items-center rounded p-2 text-left text-surface-800 group hover:bg-red-500 hover:text-white dark:text-surface-300" @click="deleteMessage">
      <div class="grow">Delete Message</div>
      <TrashIcon class="text-red-500 size-4 group-hover:text-white" />
    </button>
    <div v-if="appState.isDevUser" class="border-t dark:border-surface-700">
      <button class="mt-2 flex w-full items-center rounded p-2 text-left text-surface-800 hover:bg-primary-500 hover:text-white dark:text-surface-300" @click="reload">
        <div class="grow">Reload</div>
        <ArrowPathIcon class="size-4" />
      </button>
    </div>
  </div>
</template>

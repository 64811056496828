<script setup lang="ts">
  import { invoke } from '@tauri-apps/api/core';
  import { UnlistenFn } from '@tauri-apps/api/event';
  import { readFile, stat } from '@tauri-apps/plugin-fs';
  import { DragDropEvent, getCurrentWebview } from '@tauri-apps/api/webview';
  import { sep } from '@tauri-apps/api/path';
  import { Logger } from '@utils/logger';

  import { CameraIcon, DocumentArrowUpIcon, DocumentTextIcon } from '@heroicons/vue/24/outline';
  import { computed, onBeforeUnmount, onMounted, onUnmounted, ref } from 'vue';
  import { useAppStateStore } from '@src/store/app';
  import Dialog from 'primevue/dialog';
  import { Editor } from '@tiptap/vue-3';
  import { useToast } from 'primevue/usetoast';
  import { useRouter } from 'vue-router';
  import { mimeTypes } from '@components/Chat/mimetypes';
  import { getImageSizeFromBlob } from '@utils/media';
  import { useChatStore } from '@src/store/chat';
  import { v4 as uuidv4 } from 'uuid';

  import Boop from '@src/assets/sounds/boop.wav';
  import ChatChannel from '@components/Chat/ChatChannel.vue';
  import ChannelSidebar from '@components/Chat/ChannelSidebar.vue';
  import ChatChannelList from '@components/Chat/ChatChannelList.vue';
  import UpdateGroupChat from '@components/Chat/UpdateGroupChat.vue';
  import MessageComposer from '@components/Chat/MessageComposer.vue';
  import CreateGroupChat from '@components/Chat/CreateGroupChat.vue';
  import ChatChannelHeader from '@components/Chat/ChatChannelHeader.vue';
  import ChatTypingIndicator from '@components/Chat/ChatTypingIndicator.vue';
  import { Channel, User as ChatUser } from '@pubnub/chat';
  import { PendingChatMessage } from '@src/types/ChatAndMessaging';
  import EmojiPicker from '@components/EmojiPicker/EmojiPicker.vue';
  import ChatParticipantCard from '@components/Chat/ChatParticipantCard.vue';
  import MessageContextMenu from '@components/Chat/MessageContextMenu.vue';
  import EmojiReactionCard from '@components/Chat/EmojiReactionCard.vue';
  import MessageOptionTooltip from '@components/Chat/MessageOptionTooltip.vue';

  const creatingChannel = ref(false);
  const log = new Logger('CHAT_VIEW');

  const boopNoise = new Audio(Boop);

  const appStore = useAppStateStore();
  const chatStore = useChatStore();
  const currentUserCanModerateChannels = new Set<string>();

  const showCreateChannelDialog = ref(false);
  const editorRef = ref<Editor | null>(null);
  const showAdduserDialog = ref(false);
  const channelSidebarOpen = ref(true);

  const toast = useToast();
  const router = useRouter();

  // for uploading files
  const fileObserverListener = ref<UnlistenFn>();
  const dragging = ref(false);
  const showFileError = ref(false);
  const chatStoreSubscription = ref<() => void>();
  const toManyAttachments = ref(false);

  /**
   * Works with the composer and builds messages to send into a specific channel
   *
   * @param messageData
   */
  const sendMessageToChannel = async (messageData: PendingChatMessage) => {
    const channel = findChannelViaUrl(messageData.url);
    if (!channel) {
      return;
    }
    // find the existing message from the channel conversations based on the messageId
    if (chatStore.currentlyEditingMessage) {
      chatStore.currentlyEditingMessage.editText(messageData.message).then((message) => {
        const messages = chatStore.channelMessages.get(messageData.url);
        if (messages) {
          const index = messages.findIndex((m) => m.timetoken === message.timetoken && m.userId === message.userId);
          if (index > -1) {
            messages[index] = message;
          }
        }
      });
      chatStore.stopEditingCurrentMessage();
      return;
    }
    if (chatStore.currentlyReplyingTo && chatStore.currentlyReplyingTo.userId) {
      let targetUserId = chatStore.currentlyReplyingTo.userId;
      let replyToUser = chatStore.knownUsers.find((user) => user.id === targetUserId);
      if (replyToUser) {
        messageData.mentions = [{ id: replyToUser.id, name: replyToUser.name }];
      }
    }

    const availableFileAttachments = chatStore.channelAttachments.get(channel.id) ?? [];

    let files = availableFileAttachments.map((file) => file.file) ?? [];
    let dimensions = availableFileAttachments.map((file) => file.dimensions);
    let size = availableFileAttachments.map((file) => file.file?.size);
    let uniqueTimetokenForPendingMessage = 0;

    if (files.length > 0) {
      uniqueTimetokenForPendingMessage = chatStore.addPendingSend(channel.id, messageData);
    }
    chatStore.channelAttachments.set(channel.id, []);

    // Check to make sure we are still subscribed to this channel, as sometimes, it actually breaks here.
    if (!chatStore.chat?.sdk.getSubscribedChannels().includes(channel.id)) {
      log.warn(`found missing channel when performing broadcast request, reconnecting...`);
      chatStore.connectToChannel(channel);
      return;
    }

    const mytimeinFiles = [];
    let batch_id = null;

    //TODO Handle Video Thumbnails so the receiver doesn't have to encode a PNG for their preview image.

    // Handle uploading files to S3 protected links before sending the message
    if (files.length) {
      try {
        const { data } = await appStore.api.startChatFileUploadBatch(
          channel.id,
          files.map((file) => file.name)
        );

        const batch_information = data;
        // bump this up to the outer scope
        batch_id = batch_information.batch_id;
        const uploadsInFlight = [];
        let totalFilesFinishedUploading = 0;
        for (const index in files) {
          const file = files[index];
          log.info(`Uploading file ${file.name} to S3`);
          log.info(`File size: ${file.size}`);
          uploadsInFlight.push(appStore.api.uploadMultipartFileToS3(batch_information.upload_links[index].upload_key, file));
        }
        const inflightUpdatePromise = uploadsInFlight.map((ongoingUpload) => {
          return ongoingUpload
            .then(() => {
              totalFilesFinishedUploading++;
              const statusString = `Uploaded ${totalFilesFinishedUploading} of ${files.length} files`;
              chatStore.updatePendingSendCustomStatus(channel.id, uniqueTimetokenForPendingMessage, statusString);
            })
            .catch((e) => {
              log.error('Failed to upload file', e);
            });
        });
        await Promise.all(inflightUpdatePromise);
        console.log('All files uploaded, finalizing batch', batch_information.batch_id);
        // finalize the batch now
        const { data: successfulUploads } = await appStore.api.finishUploadBatch(batch_information.batch_id);
        // add all the IDs to the MyTimeIn files array
        mytimeinFiles.push(
          ...successfulUploads.map((info) => {
            return {
              id: info.id,
              name: info.file_name,
              type: info.mime_type,
              size: info.file_size,
              key: info.key,
            };
          })
        );
      } catch (e) {
        log.error(e);
        toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Upload failed, aborting operation',
          life: 1000,
        });
        // abandon the batch here
        chatStore.decrementPendingSend(channel.id, uniqueTimetokenForPendingMessage);
        if (batch_id) {
          await appStore.api.abandonBatch(batch_id);
        }
        return;
      }
    }

    channel
      .sendText(messageData.message, {
        meta: {
          customType: files.length ? 'file' : messageData.customType,
          dimensions: dimensions,
          size: size,
          parentId: messageData.parentId ?? null,
          mytimeinFiles: mytimeinFiles,
        },
        files: [],
        mentionedUsers: messageData.mentions,
      })
      .then(() => {
        chatStore.channelAttachments.set(channel.id, []);
        chatStore.streamUpdatesOnCurrentMessageBatch();
        if (uniqueTimetokenForPendingMessage) {
          chatStore.decrementPendingSend(channel.id, uniqueTimetokenForPendingMessage);
        }
      })
      .catch((error) => {
        chatStore.lastErrors.push(JSON.stringify(error));
        // check if its a 403 token is expired
        if (error.status === 403) {
          toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Sending message failed, please try again',
            life: 5000,
          });
          if (uniqueTimetokenForPendingMessage) {
            chatStore.decrementPendingSend(channel.id, uniqueTimetokenForPendingMessage);
          }
          chatStore.refreshChatToken();
        } else {
          toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Your session has expired. Please refresh the page.',
            life: 5000,
          });
        }
        console.error('error', error);
      });
  };

  /**
   * This initializes the chat SDK and configures some listeners to help the composer and other components
   */
  onMounted(async () => {
    if (appStore.currentUser?.id) {
      await chatStore.initSdkIfRequired(appStore.currentUser.id + '');
    }
    // in rare cases, this sometimes appears to mount twice, so we need to ensure we don't double up on listeners
    if (fileObserverListener.value) {
      console.log('[chat] file observer being removed');
      fileObserverListener.value();
      fileObserverListener.value = undefined;
    }
    installFileObserver();
    chatStoreSubscription.value = chatStore.$onAction(({ name, after }) => {
      if (name === 'startEditingMessage') {
        console.log('[chat] ok to start editing message');
        after(() => {
          if (chatStore.currentlyEditingMessage && editorRef.value) {
            let nodes = [];
            let mentionIndex = 0; // Initialize mention index
            let text = chatStore.currentlyEditingMessage.text;
            const regex = /<span\s+class=["']mention["'][^>]*>@[^<]*<\/span>/g;

            let matches;
            let lastIndex = 0;

            while ((matches = regex.exec(text)) !== null) {
              // Add text before the mention
              if (matches.index > lastIndex) {
                nodes.push({
                  type: 'text',
                  text: text.slice(lastIndex, matches.index),
                });
              }

              // Add the mention
              nodes.push({
                type: 'mention',
                attrs: {
                  id: {
                    //no idea why this needs to be nested but i can't get it not to be.
                    id: chatStore.currentlyEditingMessage?.meta?.mentionedUsers[mentionIndex].id,
                    name: chatStore.currentlyEditingMessage?.meta?.mentionedUsers[mentionIndex].name,
                  },
                },
              });

              // Update the last index and increment mention index
              lastIndex = regex.lastIndex;
              mentionIndex++;
            }

            // Add any remaining text after the last mention
            if (lastIndex < text.length) {
              nodes.push({
                type: 'text',
                text: text.slice(lastIndex),
              });
            }

            editorRef.value.commands.insertContent(nodes);
            editorRef.value.commands.focus();
          }
        });
      } else if (name === 'replyToMessage') {
        after(() => {
          if (editorRef.value) {
            editorRef.value.commands.setContent('');
            editorRef.value.commands.focus();
          }
        });
      } else if (name.includes('addAlert') || name.includes('addMention')) {
        boopNoise.play();
      }
    });
  });

  /**
   * Locate either a group or an open channel and return as a BaseChannel
   *
   * @param id
   */
  const findChannelViaUrl = (id: string): undefined | Channel => {
    const channel = chatStore.knownChannels.get(id);
    if (!channel) {
      return;
    }
    // @ts-ignore
    return channel;
  };

  const createChannel = async ({ _name, participants }: { _name: string; participants: ChatUser[] }) => {
    if (creatingChannel.value) {
      return;
    }
    creatingChannel.value = true;
    try {
      await chatStore.createChannel(participants);
      showCreateChannelDialog.value = false;
      creatingChannel.value = false;
    } catch (e) {
      creatingChannel.value = false;
    }
  };

  const favorite = (url: string) => {
    appStore.addFavoriteChannel(url);
  };

  const unfavorite = (url: string) => {
    appStore.removeFavoriteChannel(url);
  };

  const setEditorRef = (editor: Editor) => {
    // @ts-ignore
    editorRef.value = editor;
  };

  const installFileObserver = () => {
    // Attach a drag-and-drop event listener to the current webview
    getCurrentWebview()
      .onDragDropEvent(async ({ payload }: { payload: DragDropEvent }) => {
        // Exit early if not on the chat page
        if (router.currentRoute.value.name !== 'chat') {
          return;
        }

        // Exit if no channel is selected, disallowing drop events
        if (!chatStore.currentChannelUrl) {
          return;
        }

        const { type } = payload;
        let files: string[] = [];

        switch (type) {
          case 'enter':
            // Extract filenames from paths
            files = payload.paths?.map((path) => path.split(sep()).pop() as string);

            // Set dragging to true if there are files, avoiding issues with empty paths (common on Windows)
            if (files && files.length > 0) {
              dragging.value = true;
            }
            break;

          case 'drop':
            dragging.value = false;
            if (payload.paths) {
              // Add files to attachments
              //TODO PUT THIS IN A WORKER MAIN THREAD HUNG
              await addFileToAttachments(payload.paths);

              // Focus the editor and reset dragging if there are no excessive attachments
              if (!toManyAttachments.value) {
                editorRef.value?.commands.focus();
              }
            }
            break;

          case 'leave':
            // Reset dragging on leave event
            dragging.value = false;
            break;
        }
      })
      .then((listener) => {
        // Store the listener for potential cleanup
        fileObserverListener.value = listener;
      });
  };

  const getDataPreview = async (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve(e.target?.result as string);
      };
      reader.onerror = () => {
        reject(new Error('Error reading file'));
      };
      reader.readAsDataURL(file);
    });
  };

  async function filterBySize(paths: string[], sizeInMb: number = 50): Promise<string[]> {
    const maxSize = sizeInMb * 1024 * 1024; // 5MB
    const validPaths = [];

    for (const path of paths) {
      try {
        const stats = await stat(path);
        if (stats.size <= maxSize) {
          validPaths.push(path);
        } else {
          // Assuming `toast` is an instance of your toast notification handler
          toast.add({
            severity: 'error',
            summary: 'File too large',
            detail: `File ${path} must be less than ${sizeInMb}MB`,
            life: 5000,
          });
        }
      } catch (error) {
        console.error(`Error getting stats for file: ${path}`, error);
        toast.add({
          severity: 'error',
          summary: 'Error',
          detail: `Error getting stats for file: ${path}`,
          life: 5000,
        });
      }
    }

    return validPaths;
  }

  const addFileToAttachments = async (paths: string[]) => {
    const channel = findChannelViaUrl(chatStore.currentChannelUrl);
    if (!channel) {
      return;
    }

    let attachments = chatStore.channelAttachments.get(chatStore.currentChannelUrl) ?? [];

    if (attachments.length + paths.length > 10) {
      toManyAttachments.value = true;
      showFileError.value = true;
      return;
    }

    for (const index in paths) {
      //get last part of path
      let filename = paths[index].split(sep()).pop() as string;
      let fileDetails: FileUploadDetails = {
        id: uuidv4(),
        pending: true,
        file: new File([], filename),
        extension: '',
        path: paths[index],
      };
      attachments.push(fileDetails);
      chatStore.channelAttachments.set(chatStore.currentChannelUrl, attachments);
      attachments = chatStore.channelAttachments.get(chatStore.currentChannelUrl) ?? [];
    }

    for (let i = 0; i < attachments.length; i++) {
      if (attachments[i].pending) {
        try {
          let updatedFileDetails = await getFileData(attachments[i].path as string, attachments[i].id);
          Object.assign(attachments[i], {
            file: updatedFileDetails.file,
            extension: updatedFileDetails.extension,
            dimensions: updatedFileDetails.dimensions,
            preview: updatedFileDetails.preview,
            videoThumbnail: updatedFileDetails.videoThumbnail,
            pending: false,
          });
          chatStore.channelAttachments.set(chatStore.currentChannelUrl, attachments);
        } catch (error: any) {
          console.log(error);
          //delete the attachment
          attachments = attachments.filter((attachment) => attachment.id !== attachments[i].id);
          chatStore.channelAttachments.set(chatStore.currentChannelUrl, attachments);

          if (error.message && error.filePath) {
            toast.add({
              severity: 'error',
              summary: 'File too large',
              detail: `${error.message} ${error.filePath}`,
              life: 5000,
            });
          } else {
            toast.add({
              severity: 'error',
              summary: 'Error',
              detail: JSON.stringify(error),
              life: 5000,
            });
          }
        }
      }
    }
  };

  const compressImage = async (path: string, quality: number = 0.7): Promise<string> => {
    let compressedPath = await invoke<string>('compress_image', {
      inputPath: path,
      quality,
    });
    log.debug(`[chat_file] compressed path: ${compressedPath}`);
    return compressedPath;
  };

  interface VideoThumbDetails {
    width: number;
    height: number;
    originalWidth: number;
    originalHeight: number;
    dataURL: string;
  }

  const captureFrame = (video: HTMLVideoElement): VideoThumbDetails => {
    const width = video.videoWidth;
    const height = video.videoHeight;

    if (width === 0 || height === 0) {
      throw new Error('Video dimensions are zero, cannot capture frame.');
    }

    // Create a canvas to capture the frame
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    const context = canvas.getContext('2d');

    if (!context) {
      return {
        width: 0,
        height: 0,
        originalWidth: 0,
        originalHeight: 0,
        dataURL: '',
      };
    }

    context.drawImage(video, 0, 0, width, height);

    // Convert the canvas to a data URL (base64 string)
    const dataURL = canvas.toDataURL('image/png');

    // Scaling logic to fit within max dimensions
    let scaledWidth, scaledHeight;
    const MAX_WIDTH = 350;
    const MAX_HEIGHT = 500;
    const aspectRatio = video.videoWidth / video.videoHeight;

    if (video.videoWidth > video.videoHeight) {
      scaledWidth = Math.min(MAX_WIDTH, video.videoWidth);
      scaledHeight = Math.round(scaledWidth / aspectRatio);
    } else {
      scaledHeight = Math.min(MAX_HEIGHT, video.videoHeight);
      scaledWidth = Math.round(scaledHeight * aspectRatio);
    }

    // URL.revokeObjectURL(video.src); // Clean up the object URL
    return {
      originalWidth: video.videoWidth,
      originalHeight: video.videoHeight,
      width: scaledWidth,
      height: scaledHeight,
      dataURL: dataURL,
    };
  };

  const captureFrameFromPartialVideo = async (videoURL: string, timestampSeconds: number = 1): Promise<VideoThumbDetails> => {
    return new Promise((resolve, reject) => {
      try {
        // Create the video element and set up the event handling
        const video = document.createElement('video');
        video.src = videoURL;

        const handleLoadedData = () => {
          video.currentTime = timestampSeconds;
        };

        const handleSeeked = () => {
          const frameData = captureFrame(video); // Capture the frame
          // URL.revokeObjectURL(videoURL); // Clean up the object URL
          return resolve(frameData); //Resolve with the captured frame data
        };

        video.addEventListener('loadeddata', handleLoadedData, { once: true });
        video.addEventListener('seeked', handleSeeked, { once: true });
        video.addEventListener('error', (e) => {
          console.error(e);
          console.error('Error capturing frame:', e);
          reject(e);
        });
      } catch (error) {
        console.error('Error reading partial file or capturing frame:', error);
        throw error;
      }
    });
  };

  const getVideoDimensions = (objectUrl: string): Promise<{ width: number; height: number; originalWidth: number; originalHeight: number }> => {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');

      video.addEventListener('loadedmetadata', () => {
        const width = video.videoWidth;
        const height = video.videoHeight;

        // Scaling logic to fit within max dimensions
        let scaledWidth, scaledHeight;
        const MAX_WIDTH = 350;
        const MAX_HEIGHT = 500;
        const aspectRatio = video.videoWidth / video.videoHeight;

        if (video.videoWidth > video.videoHeight) {
          scaledWidth = Math.min(MAX_WIDTH, video.videoWidth);
          scaledHeight = Math.round(scaledWidth / aspectRatio);
        } else {
          scaledHeight = Math.min(MAX_HEIGHT, video.videoHeight);
          scaledWidth = Math.round(scaledHeight * aspectRatio);
        }

        // an up the object URL
        resolve({ width: scaledWidth, height: scaledHeight, originalWidth: width, originalHeight: height });
      });

      video.addEventListener('error', (e) => {
        URL.revokeObjectURL(objectUrl); // Clean up on error
        reject(new Error('Failed to load video metadata.'));
      });

      video.src = objectUrl;
    });
  };

  const getFileData = async (path: string, id: string): Promise<FileUploadDetails> => {
    const fileExtension = path.split('.').pop()?.toLowerCase();
    if (!fileExtension) {
      return { id, file: new File([], ''), extension: '', path: '' };
    }

    const mimeType: string = mimeTypes.find((mimeType) => mimeType.extension === fileExtension)?.mimeType ?? '';

    const fileStatsPromise = stat(path);
    const sizeInMb = 50;
    const maxSize = sizeInMb * 1024 * 1024;
    console.log(mimeType);

    //Video Files
    if (mimeType.startsWith('video')) {
      const fileStats = await fileStatsPromise;
      if (fileStats.size >= maxSize) {
        throw { message: `File size exceeds ${sizeInMb}MB`, filePath: path };
      }

      let length = 0;

      if (fileStats.size >= 1024 * 1024 * 3) {
        length = Math.ceil(fileStats.size * 0.1); //10%
      } else {
        length = fileStats.size;
      }

      const binaryData = await invoke<Uint8Array>('read_partial_file', { path, start: 0, length: length });
      const blob = new Blob([new Uint8Array(binaryData)], { type: mimeType });
      const videoURL = URL.createObjectURL(blob);

      let file = new File([binaryData], path.split(sep()).pop() as string, {
        type: mimeType,
      });

      let dimensions = await getVideoDimensions(videoURL);

      return {
        id,
        file,
        extension: fileExtension,
        path,
        dimensions: dimensions,
        preview: videoURL, //this is only used for the client not uploading
      };
    }
    //Image files
    else if (mimeType.startsWith('image')) {
      const fileStats = await fileStatsPromise;
      if (fileStats.size >= maxSize) {
        throw new Error(`File size exceeds ${sizeInMb}MB`);
      }

      const sizeInKilobytes = fileStats.size / 1024;

      // Compress if needed
      if (sizeInKilobytes > 1000 && mimeType.includes('image') && fileExtension.match(/(jpg|jpeg|png)/)) {
        log.debug(`[chat_file] before file size (kb): ${sizeInKilobytes}`);
        path = await compressImage(path);
      }

      const data = await readFile(path); // Read once after potential compression
      let file = new File([data], path.split(sep()).pop() as string, {
        type: mimeType,
      });
      console.log('created file');
      let dimensions = await getImageSizeFromBlob(new Blob([data]));
      console.log('got dimensions');
      let previewDataUrl = await getDataPreview(file);
      console.log('got preview');

      return {
        id,
        file,
        extension: fileExtension,
        path,
        dimensions,
        preview: previewDataUrl,
      };
    }
    //All other files
    else {
      const data: Uint8Array = await readFile(path);
      let file = new File([data], path.split(sep()).pop() as string, {
        type: mimeTypes.find((mimeType) => mimeType.extension === fileExtension)?.mimeType,
      });
      return {
        id,
        file,
        extension: fileExtension,
        path,
      };
    }
  };

  const updateChannel = ({ participants, exposeHistory }: { participants: ChatUser[]; exposeHistory: boolean }) => {
    chatStore.updateChannel(chatStore.channelUrl, participants);
  };

  const currentChannel = computed(() => {
    if (!chatStore.knownChannels.get(chatStore.currentChannelUrl)) {
      return undefined;
    }
    return chatStore.knownChannels.get(chatStore.currentChannelUrl) as Channel;
  });

  const favoriteChannelIds = computed(() => new Set(appStore.favoriteChannels.map((fav) => fav.id)));

  /**
   * TODO: OPTIMIZE THIS
   */
  const nonFavoriteGroupChannels = computed((): Channel[] => {
    const order = chatStore.channelOrder;
    const knownChannelsArray = Array.from(chatStore.knownChannels);

    return knownChannelsArray
      .filter(([url, channel]) => !favoriteChannelIds.value.has(channel.id) && channel.custom?.type !== 'team')
      .map(([url, channel]) => channel as Channel)
      .sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id));
  });

  const favoriteChannelUrls = computed(() => new Set(appStore.favoriteChannels.map((fav) => fav.id)));

  const favoriteChannels = computed((): Channel[] => {
    return Array.from(chatStore.knownChannels)
      .filter(([url]) => favoriteChannelUrls.value.has(url))
      .map(([url, channel]) => channel as Channel);
  });
  /**
   * This list is a special set of group channels that have a custom type. They are based on the teams
   * in MyTimeIn
   */
  const teamChannels = computed((): Channel[] => {
    return Array.from(chatStore.knownChannels)
      .filter(([url, channel]) => channel.custom?.type === 'team')
      .map(([url, channel]) => channel as Channel);
  });

  const toggleCreateDialog = () => {
    chatStore.composerFocusStealOverride = showCreateChannelDialog.value = !showCreateChannelDialog.value;
  };

  const toggleGroupDialog = () => {
    chatStore.composerFocusStealOverride = showAdduserDialog.value = !showAdduserDialog.value;
  };

  const sendMessageFromSidebar = async ({ message, user }: { message: string; user: ChatUser }) => {
    log.debug('sending message from tooltip');
    await createChannel({ _name: '', participants: [user] });
    log.debug('channel created from tooltip', chatStore.currentChannelUrl);
    let outgoingMessage = {
      message: message,
      type: 'text',
      mentions: [],
      url: chatStore.currentChannelUrl,
      customType: null,
    };
    sendMessageToChannel(outgoingMessage as unknown as PendingChatMessage);
  };

  const clearToManyFiles = () => {
    toManyAttachments.value = false;
    showFileError.value = false;
  };

  const applyReaction = (emoji: Emoji) => {
    const skin_variation_from_base = appStore.settings.emojiSkinTone;
    if (emoji.skin_variations && skin_variation_from_base && emoji.skin_variations[skin_variation_from_base]) {
      emoji.unified = emoji.skin_variations[skin_variation_from_base].unified;
    }
    appStore.updateRecentlyUsedEmojis(emoji.unified);
    // find the current message timetoken from the store
    const message = chatStore.currentChannelMessages.find((m) => m.timetoken === chatStore.messageContextTimeToken);
    message?.toggleReaction(unicodeToEmoji(emoji.unified)).catch((error: any) => {
      // our message is outdated? it needs to be updated
      if (error && error.includes('status of 409 (Conflict)')) {
        chatStore.refreshMessageFromPubnub(message.timetoken);
        return;
      }
      console.error('Failed to apply reaction!');
      console.error(error);
    });
  };

  // TODO: move this to Emoji Manager?
  const unicodeToEmoji = (unicode: string) => {
    const codePoints = unicode.split('-').map((u) => parseInt(u, 16));
    return String.fromCodePoint(...codePoints);
  };

  onUnmounted(async () => {
    console.log('[chat] resetting chat store');
    await chatStore.disconnect();
    chatStore.$reset();
    editorRef.value?.destroy();
    if (fileObserverListener.value) {
      console.log('[chat] file observer being removed');
      fileObserverListener.value();
      fileObserverListener.value = undefined;
    }
    if (chatStoreSubscription.value) {
      chatStoreSubscription.value();
      console.log('[chat] store subscription being removed');
      chatStoreSubscription.value = undefined;
    }
  });

  const hideChatParticipantCard = () => {
    chatStore.participantCardUser = null;
    chatStore.participantCardOpen = false;
    chatStore.composerFocusStealOverride = false;
  };

  const hideMessageContextMenu = () => {
    chatStore.showMessageContextMenu = false;
    chatStore.messageContextTimeToken = null;
  };

  const showSidebarBasedOnWindowSize = () => {
    channelSidebarOpen.value = window.innerWidth >= 1200;
  };

  onMounted(() => {
    showSidebarBasedOnWindowSize();

    window.addEventListener('resize', showSidebarBasedOnWindowSize);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', showSidebarBasedOnWindowSize);
  });
</script>

<template>
  <div>
    <div id="floating-chat-reference" class="hidden" />
    <div id="chat-layer" class="absolute z-[99]">
      <EmojiPicker v-if="chatStore.emojiPickerOpen" id="floating-chat-emoji-picker" @emoji-selected="applyReaction" @contextmenu.stop.prevent />
      <ChatParticipantCard v-if="chatStore.participantCardUser" id="participant-card" v-click-away="hideChatParticipantCard" @close="hideChatParticipantCard" @send-message="sendMessageFromSidebar" @contextmenu.stop.prevent />
      <MessageContextMenu v-if="chatStore.showMessageContextMenu" id="message-context-menu" v-click-away="hideMessageContextMenu" @contextmenu.stop.prevent />
    </div>
    <div id="chat-layer2" class="absolute z-[99]">
      <MessageOptionTooltip v-if="chatStore.showMessageOptionTooltip" id="message-options-tooltip" @contextmenu.stop.prevent />
      <EmojiReactionCard v-if="chatStore.showEmojiReactionCard" id="emoji-reaction-card" @contextmenu.stop.prevent />
    </div>

    <CreateGroupChat v-if="showCreateChannelDialog" :visible="showCreateChannelDialog" @create="createChannel" @close="toggleCreateDialog()" />
    <UpdateGroupChat v-if="showAdduserDialog" :visible="showAdduserDialog" :participants="chatStore.currentChannelParticipants as ChatUser[]" @update="updateChannel" @close="toggleGroupDialog" />

    <Dialog v-model:visible="showFileError" :dismissable-mask="true" :closeable="true" modal :draggable="false" class="w-[25vw] md:w-[25vw]" :block-scroll="true" @update:visible="clearToManyFiles">
      <template #container>
        <div class="justify-center flex flex-col items-center p-3 text-white last:border-b-0 rounded-lg select-none transition-colors duration-300 ease-in-out" :class="[toManyAttachments ? 'bg-red-500 animate-shake' : 'bg-primary-500 dark:bg-primary-600']">
          <div class="border-dashed border-surface-200 border-2 w-full text-center h-44 rounded-md">
            <div class="flex justify-center -mt-16 mb-4">
              <DocumentTextIcon class="text-surface-200 size-32 mt-1 -mr-14 -rotate-[22deg] transition-colors duration-300 ease-in-out" :class="[toManyAttachments ? 'fill-red-500' : 'fill-primary-500 dark:fill-primary-600']" />
              <DocumentArrowUpIcon class="text-surface-200 size-32 z-10 -mt-2 transition-colors duration-300 ease-in-out" :class="[toManyAttachments ? 'fill-red-500' : 'fill-primary-500 dark:fill-primary-600']" />
              <CameraIcon class="text-surface-200 size-32 -ml-14 mt-2 rotate-[22deg] z-8 transition-colors duration-300 ease-in-out" :class="[toManyAttachments ? 'fill-red-500' : 'fill-primary-500 dark:fill-primary-600']" />
            </div>

            <div class="px-4">
              <h1 class="text-2xl mb-1 font-extrabold line-clamp-1 break-all">
                <span v-if="!toManyAttachments"> Upload to {{ currentChannel?.name }} </span>
                <span v-else>To many files!</span>
              </h1>
              <p class="text-xs">
                <span v-if="!toManyAttachments">You can add comments before uploading.</span>
                <span v-else>You can upload a maximum of 10 files at once!</span>
              </p>
            </div>
          </div>
        </div>
      </template>
    </Dialog>

    <Dialog v-model:visible="dragging" :dismissable-mask="true" :closeable="true" modal :draggable="false" class="w-[25vw] md:w-[25vw]" :block-scroll="true">
      <template #container>
        <div class="justify-center flex flex-col items-center p-3 text-white last:border-b-0 rounded-lg select-none transition-colors duration-300 ease-in-out bg-primary-500 dark:bg-primary-600">
          <div class="border-dashed border-surface-200 border-2 w-full text-center h-44 rounded-md">
            <div class="flex justify-center -mt-16 mb-4">
              <DocumentTextIcon class="text-surface-200 size-32 mt-1 -mr-14 -rotate-[22deg] transition-colors duration-300 ease-in-out fill-primary-500 dark:fill-primary-600" />
              <DocumentArrowUpIcon class="text-surface-200 size-32 z-10 -mt-2 transition-colors duration-300 ease-in-out fill-primary-500 dark:fill-primary-600" />
              <CameraIcon class="text-surface-200 size-32 -ml-14 mt-2 rotate-[22deg] z-8 transition-colors duration-300 ease-in-out fill-primary-500 dark:fill-primary-600" />
            </div>

            <div class="px-4">
              <h1 class="text-2xl mb-1 font-extrabold line-clamp-1 break-all">Upload to {{ currentChannel?.name }}</h1>
              <p class="text-xs">You can add comments before uploading.</p>
            </div>
          </div>
        </div>
      </template>
    </Dialog>

    <div class="flex">
      <!-- Channel List -->
      <ChatChannelList
        :team-channels="teamChannels"
        :favorite-channels="favoriteChannels"
        :non-favorite-channels="nonFavoriteGroupChannels"
        :loading="chatStore.initialLoad"
        :channel-alerts="chatStore.channelAlerts"
        :unread-mentions="chatStore.unreadMentions"
        :current-channel-url="chatStore.currentChannelUrl"
        :non-favorite-group-channels="nonFavoriteGroupChannels"
        @create="toggleCreateDialog()"
        @change-channel="chatStore.changeChannel"
        @favorite="favorite"
        @unfavorite="unfavorite"
      />
      <!-- Main Area -->
      <section v-if="currentChannel" class="grow flex flex-col md:max-h-screen md:min-h-screen overflow-hidden">
        <ChatChannelHeader @add-member="toggleGroupDialog" @open-sidebar="channelSidebarOpen = !channelSidebarOpen" @favorite="favorite" @unfavorite="unfavorite" />
        <!--Chat / Members 2 Column Layout -->
        <div class="flex grow z-50 overflow-hidden">
          <!--Left - Chat Column-->
          <div class="flex-col flex grow overflow-hidden">
            <ChatChannel
              class="flex grow"
              :channel="currentChannel"
              :participants="chatStore.currentChannelParticipants as ChatUser[]"
              :current-user-id="`${chatStore.chat?.currentUser?.id ?? -1}`"
              :loading="chatStore.isLoadingCurrentChannelUrl"
              :operator-mode="currentUserCanModerateChannels.has(chatStore.channelUrl)"
              style="height: 1px"
              @send-message="sendMessageFromSidebar"
            />
            <!--Message Composer and User is Typing-->
            <div v-if="chatStore.chat?.currentUser?.id && chatStore.currentChannelUrl" class="shrink-0 relative">
              <MessageComposer :current-user-id="`${chatStore.chat.currentUser.id}`" :participants="chatStore.currentChannelParticipants as ChatUser[]" :channel-url="chatStore.currentChannelUrl" @attach="addFileToAttachments" @send="sendMessageToChannel" @share-editor="setEditorRef" />
              <ChatTypingIndicator class="" />
            </div>
          </div>
          <!--Right - Member List Column-->
          <ChannelSidebar v-if="currentChannel && channelSidebarOpen" class="shrink-0 w-[240px]" :open="channelSidebarOpen" @toggle="channelSidebarOpen = !channelSidebarOpen" @send-message="sendMessageFromSidebar" />
        </div>
      </section>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ArrowUturnLeftIcon, FaceSmileIcon, PencilSquareIcon, TrashIcon, BookmarkIcon, EllipsisVerticalIcon } from '@heroicons/vue/16/solid';
  import MessageOptionButton from '@components/Chat/MessageOptionButton.vue';
  import { computed, onBeforeMount, ref } from 'vue';
  import { useChatStore } from '@src/store/chat';
  import { useAppStateStore } from '@src/store/app';

  import { EmojiManager } from '@utils/emoji_manager';
  import { calculateTooltipPosition } from '@utils/tooltip_calculator';

  const emojiManager = EmojiManager.getInstance();

  const props = defineProps<{
    senderId: string;
    reactions: boolean;
    isOwnMessage: boolean;
    timetoken: string;
  }>();

  const emits = defineEmits(['react-message', 'reply-message', 'edit-message', 'simple-react']);
  const chatStore = useChatStore();
  const appStore = useAppStateStore();
  const isModeratorMessage = ref(false);

  const top3MostUsedEmojis = computed(() => {
    //get the top 3 most used emojis
    const entries = appStore.recentlyUsedEmojis;
    return entries.slice(0, 3);
  });

  const closeAllOpenMenus = () => {
    chatStore.emojiPickerOpen = false;

    chatStore.participantCardOpen = false;
    chatStore.participantCardUser = null;

    chatStore.composerFocusStealOverride = false;

    chatStore.showMessageContextMenu = false;
    chatStore.messageContextTimeToken = null;
  };

  const toggleEmojiPanel = (event: MouseEvent) => {
    // Close all open menus
    closeAllOpenMenus();

    chatStore.composerFocusStealOverride = true;
    chatStore.emojiPickerOpen = true;
    chatStore.messageContextTimeToken = props.timetoken;

    requestAnimationFrame(() => {
      let success = calculateTooltipPosition(event, 'chat-layer', 'floating-chat-emoji-picker', 'left');
      if (!success) {
        chatStore.composerFocusStealOverride = false;
        chatStore.emojiPickerOpen = false;
        chatStore.messageContextTimeToken = props.timetoken;
      }
    });
  };

  const showMessageContextMenu = (event: MouseEvent) => {
    chatStore.messageContextTimeToken = props.timetoken;
    chatStore.showMessageContextMenu = true;
    requestAnimationFrame(() => {
      let success = calculateTooltipPosition(event, 'chat-layer', 'message-context-menu', 'left', 0);
      if (!success) {
        chatStore.showMessageContextMenu = false;
      }
    });
  };

  const simpleReact = (emoji: string) => {
    emits('simple-react', emoji);
  };

  const editMessage = () => {
    chatStore.startEditingMessage(props.timetoken);
  };

  const replyMessage = () => {
    chatStore.replyToMessage(props.timetoken);
  };

  onBeforeMount(() => {
    if (props.senderId === 'PUBNUB_INTERNAL_MODERATOR') {
      isModeratorMessage.value = true;
    }
  });
</script>

<template>
  <div class="gap-2 hover:shadow-md dark:hover:shadow-surface-900 rounded-md border bg-white shadow-sm dark:shadow-none dark:bg-surface-700/30 dark:border-surface-950" @contextmenu.stop.prevent>
    <div class="flex items-center">
      <div class="h-8 flex items-center">
        <MessageOptionButton v-for="(emojiString, index) in top3MostUsedEmojis" :key="`${emojiString}-${index}`" @click="simpleReact(emojiString)">
          <template #icon>
            <div class="12">
              <div class="emojiSprite-20" :style="emojiManager.getBackgroundPosition(emojiString, 20)"></div>
            </div>
          </template>
        </MessageOptionButton>
      </div>
      <div v-if="top3MostUsedEmojis.length" class="border-x mx-1 dark:border-surface-600">&nbsp;</div>
      <div class="flex items-center h-8 justify-center">
        <MessageOptionButton tooltip="React" @click="toggleEmojiPanel">
          <template #icon>
            <FaceSmileIcon class="size-5 chat-emoji-opener" />
          </template>
        </MessageOptionButton>
        <template v-if="!isModeratorMessage">
          <MessageOptionButton v-if="!isOwnMessage" tooltip="Reply" @click="replyMessage">
            <template #icon>
              <ArrowUturnLeftIcon class="size-5" />
            </template>
          </MessageOptionButton>

          <MessageOptionButton v-if="isOwnMessage" tooltip="Edit" @click="editMessage">
            <template #icon>
              <PencilSquareIcon class="size-5" />
            </template>
          </MessageOptionButton>
          <MessageOptionButton tooltip="More" @click="showMessageContextMenu">
            <template #icon>
              <EllipsisVerticalIcon class="size-5" />
            </template>
          </MessageOptionButton>
        </template>
      </div>
    </div>
  </div>
</template>

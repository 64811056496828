import { defineStore } from 'pinia';
import { Channel, Chat, Message, MessageMentionedUsers, TimetokenUtils, User as ChatUser } from '@pubnub/chat';
import { ChatAuthDetails, ChatComposerState, ChatReactionCollection, KnownChatUser, PendingChatMessage } from '@src/types/ChatAndMessaging';
import { useAppStateStore } from '@src/store/app';
import { restoreSerializedMap, serializeMap } from '@utils/serializers';
import { sendNotification } from '@tauri-apps/plugin-notification';
import { invoke } from '@tauri-apps/api/core';
import { addToArrayInMap, removeFromArrayInMap, removeFromArrayInMapWithPredicate } from '@utils/mapUtils';
import { waitFor } from '@utils/waitFor';
import { Logger } from '@utils/logger';
import { isEqual } from 'lodash';

const log = new Logger('STORE:CHAT');

const STATE_REPLYING = 'replying';
const STATE_COMPOSING = 'composing';
const STATE_EDITING = 'editing';
const MESSAGES_TO_LOAD_MAX = 100;
const OMIT_CHANNEL_WATCHES = ['modcon_2024', 'announcements'];

const EVENT_USER_ONLINE = 1;
const EVENT_USER_OFFLINE = 2;
const EVENT_USER_IDLE = 3;
const EVENT_USER_DND = 4;
const EVENT_MESSAGE_DELETED = 101;
const EVENT_USER_UPDATED = 102;

export const useChatStore = defineStore('chat', {
  state: () => ({
    initialLoad: false,
    chatUserId: '',

    channelUrl: '',

    unreadMentions: new Map<string, number>(),
    channelAlerts: new Map<string, number>(),
    channelComposerStates: new Map<string, ChatComposerState>(),

    // SPECIAL FUNCTIONS TO STREAM UPDATES FROM PUBNUB
    currentMessageStreamDisconnectFunction: null as (() => void) | null,
    eventDisconnectFns: [] as (() => void)[],
    currentChannelListDisconnectFunction: null as (() => void) | null,
    currentChannelStopTypingUpdatesDisconnectFunction: null as (() => void) | null,
    // @todo: this actually only returns userIds, not online activity, so don't use it yet.
    currentChannelParticipantListStreamDisconnectFunction: null as (() => void) | null,

    // STREAM FOR PENDING MESSAGE UPDATES THAT ARE *NOT* ON THE CURRENT MESSAGE CHANNEL
    pendingMessageUpdateStreams: new Map<string, (() => void)[]>(),
    pinnedMessagesForChannels: new Map<string, Message[]>(),

    chat: null as Chat | null,

    knownChannels: new Map<string, Channel>(),
    loadingChannels: new Set<string>(),
    finishedLoadingChannels: new Set<string>(),

    channelOrder: [] as string[],
    channelMessages: new Map<string, Message[]>(),
    channelAttachments: new Map<string, FileUploadDetails[]>(),
    channelParticipants: new Map<string, ChatUser[]>(),
    channelPinnedMessages: new Map<string, Message>(),
    channelDisconnectFns: new Map<string, () => void>(),

    mentionsOpen: false,
    composerFocusStealOverride: false,
    largeEditorMode: false,

    knownUsers: [] as KnownChatUser[],
    participantsOnlineInChannel: new Map<string, string[]>(),
    onlineUserIds: new Set(),
    idleUserIds: new Set(),

    // optimized for the current chat view as a way to keep the UI hot and responsive
    currentChannelMutations: 0,
    currentlyTyping: new Map<string, NodeJS.Timeout>(),
    optimizingUploads: false,

    currentRecoveryAttempts: 0,

    // this is strictly to enable mod controls at a later date
    operators: new Map<string, string[]>(),

    // Authentication
    authentication: null as ChatAuthDetails | null,
    tokenExpirationTime: null as null | Date,
    tokenRefreshInterval: null as NodeJS.Timeout | null,
    busyUpdatingToken: false,

    customEventListenerFn: null as any,
    customStatusListenerFn: null as any,
    inviteListenerFn: null as any,

    lastErrors: [] as string[],

    emojiPickerOpen: false,

    participantCardOpen: false,
    participantCardUser: null as ChatUser | null,

    showMessageContextMenu: false,

    showMessageOptionTooltip: false,
    messageOptionTooltipMessage: null as string | null,

    showEmojiReactionCard: false,
    reactionEmoji: null as string | null,
    emojiReactionTooltipMessage: null as string | null,

    messageContextTimeToken: null as string | null,

    pendingMessagesMap: new Map<string, PendingChatMessage[]>(),
    knownReactionsToMessages: new Map<string, ChatReactionCollection>(),

    // first draw?
    firstDraw: false,
  }),
  actions: {
    async initSdkIfRequired(userId: string) {
      if (this.chat) {
        return;
      }
      if (!userId) {
        log.error('no user id provided');
        return;
      }
      this.initialLoad = true;
      const appStore = useAppStateStore();
      let PUBLISH_KEY = '';
      let SUBSCRIBE_KEY = '';
      try {
        this.authentication = await appStore.refreshChatToken();
        try {
          await appStore.api.getSignedCookie(this.authentication.token);
        } catch (e) {
          console.error(e);
        }
        PUBLISH_KEY = this.authentication.publish_key;
        SUBSCRIBE_KEY = this.authentication.subscribe_key;
        // calculate the expiration time (-10 minutes)
        this.tokenExpirationTime = new Date(Date.now() + this.authentication.ttl * 1000 - 10000);
        this.scheduleTokenRefresh();
      } catch (error) {
        log.error('failed to get chat token');
        log.error(error);
        return;
      }

      log.debug(`initializing chat with user id`, userId);
      // remove this if it was accidentally appended too early
      userId = userId.replace('mytimein-user-', '');
      try {
        this.chat = await Chat.init({
          publishKey: PUBLISH_KEY,
          subscribeKey: SUBSCRIBE_KEY,
          userId: `mytimein-user-${userId}`,
          storeUserActivityTimestamps: true,
          authKey: this.authentication.token,
        });
      } catch (error) {
        log.error('failed to initialize chat');
        log.error(error);
        return;
      }
      this.chatUserId = this.chat.currentUser.id;

      // @ts-ignore this actually works, no idea what its bitching about.
      this.customStatusListenerFn = this.chat.addListener({
        status: (statusEvent: any) => {
          if (['PNUnknownCategory', 'PNBadRequestCategory', 'PNAccessDeniedCategory', 'PNTimeoutCategory'].includes(statusEvent.category)) {
            log.error('status', statusEvent);
          } else {
            //Non Error Status
            log.debug('status', statusEvent);
          }
          if (statusEvent.operation === 'PNUnsubscribeOperation') {
            log.error('unsubscribed from known channel', statusEvent.affectedChannels);
          } else if (statusEvent.category === 'PNAccessDeniedCategory' || statusEvent.statusCode === 403) {
            if (statusEvent.category === 'PNAccessDeniedCategory') {
              // check the channels in the payload
              const channels = statusEvent.errorData.payload.channels;
              console.log('broken channels discovered, we cannot subscribe to these', channels);
            }
            // @ts-ignore
            if (statusEvent.errorData?.message === 'Token is expired.') {
              log.error('access denied, token expired');
              this.refreshChatToken(true);
            }
          }
        },
      });
      this.customEventListenerFn = this.chat.listenForEvents<'custom'>({
        channel: 'mytimein-broadcast',
        method: 'signal',
        type: 'custom',
        callback: (event: any) => {
          if (event.payload.status === EVENT_USER_ONLINE) {
            this.onlineUserIds.add(event.payload.userId);
            this.idleUserIds.delete(event.payload.userId);
          } else if (event.payload.status === EVENT_USER_OFFLINE) {
            this.onlineUserIds.delete(event.payload.userId);
            return;
          } else if (event.payload.status === EVENT_USER_IDLE) {
            this.onlineUserIds.delete(event.payload.userId);
            this.idleUserIds.add(event.payload.userId);
          } else if (event.payload.status === EVENT_MESSAGE_DELETED) {
            this.deleteMessageFromChannel(event.payload.channelId, event.payload.timetoken);
          } else if (event.payload.status === EVENT_USER_UPDATED) {
            this.updateKnownUsers([event.payload.userId]);
          }
          if (this.knownUsers.some((user) => user.id === event.payload.userId)) {
            this.updateKnownUsers([event.payload.userId]);
          }
        },
      });

      const { memberships } = await this.chat.currentUser.getMemberships();
      const authenticationReadyFor = this.chat.sdk.parseToken(this.authentication.token);
      const channelsAvailableToJoin = Object.keys(authenticationReadyFor.resources.channels);
      memberships.forEach((membership) => {
        if (!channelsAvailableToJoin.includes(membership.channel.id)) {
          log.error('bad membership data discovered', membership.channel.id);
          return;
        }
        log.debug('joining channel', membership.channel.id);
        this.knownChannels.set(membership.channel.id, membership.channel);
        this.connectToChannel(membership.channel);
      });
      this.initialLoad = false;
      this.listenForInviteEvents();
      this.streamUpdatesOnCurrentChannelList();
      this.restoreNotifications();
      this.broadcastOnlineNotice();
      this.currentRecoveryAttempts = 0;

      setTimeout(() => {
        this.restoreLastVisibleChannel();
      }, 2000);
    },
    broadcastOnlineNotice() {
      if (!this.chat) {
        return;
      }
      this.chat
        .emitEvent({
          method: 'signal',
          channel: 'mytimein-broadcast',
          type: 'custom',
          payload: {
            status: EVENT_USER_ONLINE,
            userId: this.chat.currentUser?.id,
          },
        })
        .catch((e: any) => {
          log.error('failed to broadcast online notice');
          log.error(e);
          log.error(e.status);
        });
    },
    broadcastIdleNotice() {
      if (!this.chat) {
        return;
      }
      this.chat
        .emitEvent({
          method: 'signal',
          channel: 'mytimein-broadcast',
          type: 'custom',
          payload: {
            status: EVENT_USER_IDLE,
            userId: this.chat.currentUser?.id,
          },
        })
        .catch((e: any) => {
          log.error('failed to broadcast online notice');
          log.error(e);
          log.error(e.status);
        });
    },
    listenForInviteEvents() {
      log.debug('listening to all chat events');
      if (!this.chat) {
        log.error('chat not initialized');
        return;
      }
      this.inviteListenerFn = this.chat.listenForEvents({
        channel: this.chat.currentUser?.id,
        type: 'invite',
        callback: (event: any) => {
          log.debug('received invite event', event);
          //Don't listen to events from yourself
          if (event.userId === this.chatUserId) {
            return;
          }

          // get the new membership and join the channel
          this.chat?.currentUser
            .getMemberships()
            .then(async ({ memberships }) => {
              const membership = memberships.find((m) => m.channel.id === event.payload.channelId);
              if (membership) {
                // get a new token with updated permissions
                await this.refreshTokenAsync();
                // make sure we can join the channel for real
                const authenticationReadyFor = this.chat?.sdk.parseToken(this.authentication?.token);
                const channelsAvailableToJoin = Object.keys(authenticationReadyFor.resources.channels);
                if (!channelsAvailableToJoin.includes(event.payload.channelId)) {
                  log.error('bad membership data discovered', event.payload.channelId);
                  return;
                }
                // join the channel now
                this.knownChannels.set(membership.channel.id, membership.channel);
                this.connectToChannel(membership.channel);
              }
            })
            .catch((e) => {
              log.error('failed to get memberships on INVITE');
              log.error(e);
              log.error(e.status);
            });
        },
      });
    },
    restoreLastVisibleChannel() {
      const lastVisibleChannel = localStorage.getItem('current-channel');
      if (lastVisibleChannel) {
        log.debug('restoring last known visible channel', lastVisibleChannel);
        this.changeChannel(lastVisibleChannel);
      }
    },
    restoreNotifications() {
      const alerts = localStorage.getItem('chat-alerts');
      const mentions = localStorage.getItem('chat-mentions');
      this.channelAlerts = alerts ? restoreSerializedMap(alerts) : new Map<string, number>();
      this.unreadMentions = mentions ? restoreSerializedMap(mentions) : new Map<string, number>();
      // resort the channel order based on notifications
      this.channelOrder = Array.from(this.channelAlerts.keys());
    },
    broadcastOfflineNotice() {
      if (!this.chat) {
        return;
      }

      this.chat
        .emitEvent({
          method: 'signal',
          channel: 'mytimein-broadcast',
          type: 'custom',
          payload: {
            status: EVENT_USER_OFFLINE,
            userId: this.chat.currentUser?.id,
          },
        })
        .then()
        .catch();
    },
    async disconnect() {
      return new Promise((resolve) => {
        this.broadcastOfflineNotice();
        if (this.currentMessageStreamDisconnectFunction) {
          log.debug('removing pending message stream watchers');
          this.currentMessageStreamDisconnectFunction();
        }
        // remove all messages in all channels
        this.channelMessages = new Map<string, Message[]>();
        this.channelAttachments = new Map<string, FileUploadDetails[]>();
        this.channelParticipants = new Map<string, ChatUser[]>();
        this.channelPinnedMessages = new Map<string, Message>();
        this.pendingMessagesMap = new Map<string, PendingChatMessage[]>();
        // disconnect from channels, echo the disconnects
        this.channelDisconnectFns.forEach((disconnect) => {
          log.debug('disconnecting from known channel...');
          disconnect();
        });
        if (this.tokenRefreshInterval) {
          log.debug('clearing refresh interval on token');
          clearInterval(this.tokenRefreshInterval);
        }
        if (this.currentChannelParticipantListStreamDisconnectFunction) {
          log.debug('removing participant stream');
          this.currentChannelParticipantListStreamDisconnectFunction();
        }
        if (this.currentChannelListDisconnectFunction) {
          log.debug('removing channel list listener');
          this.currentChannelListDisconnectFunction();
        }
        if (this.currentChannelStopTypingUpdatesDisconnectFunction) {
          log.debug('removing typing watchers');
          this.currentChannelStopTypingUpdatesDisconnectFunction();
        }
        this.dumpCurrentlyTypingList();
        if (this.customEventListenerFn && this.chat) {
          log.debug('removing custom event listener');
          this.customEventListenerFn();
        }
        if (this.inviteListenerFn && this.chat) {
          log.debug('removing channel invite listener');
          this.inviteListenerFn();
        }
        if (this.customStatusListenerFn && this.chat) {
          log.debug('removing custom status listener');
          this.chat.sdk.removeListener(this.customStatusListenerFn);
        }
        log.debug('destroying chat sdk');
        this.chat?.sdk.destroy();
        log.debug('nulling chat object');
        this.chat = null;
        resolve(true);
      });
    },
    connectToChannel(channel: Channel) {
      if (!this.chat) {
        return;
      }
      const currentlySubscribedChannels = this.chat.sdk.getSubscribedChannels();
      if (currentlySubscribedChannels.includes(channel.id)) {
        log.debug('found hot subscription, removing it to prevent double subscribe', channel.id);
        this.chat.sdk.unsubscribe({ channels: [channel.id] });
      }
      channel
        .join((message: Message) => {
          console.log('got message', message);
          // move this channel to the top of the channel order
          const order = this.channelOrder.filter((id) => id !== channel.id);
          order.unshift(channel.id);
          this.channelOrder = order;

          this.channelMessages.get(channel.id)?.push(message);

          // if we are not on the current channel, we need to stream mesage updates on this message by itself for now
          if (this.currentChannelUrl !== channel.id) {
            // check to see if this channel id has any messages currently streaming on it
            if (!this.pendingMessageUpdateStreams.has(channel.id)) {
              this.pendingMessageUpdateStreams.set(channel.id, []);
            }
            const individualMessageUpdateStream = message.streamUpdates((message) => {
              this.updateMessage(message);
            });
            // append the message to the pending message update stream
            this.pendingMessageUpdateStreams.get(channel.id)?.push(individualMessageUpdateStream);
          }

          // if it's an admin message with the content that someone has been added to channel, refresh participants
          if (message.meta?.customType === 'admin' && message.text.includes('has been added to channel')) {
            this.loadChannelParticipants(channel.id);
          }

          // we need to stream updates on these messages
          // check if the user is in the known users
          if (message.userId && !this.knownUsers.some((user) => user.id === message.userId)) {
            this.updateKnownUsers([message.userId]).then().catch();
          }
          const knownUser = this.knownUsers.find((user) => user.id === message.userId);

          // dump typing indicator early if we're looking at this channel and the user just delivered a message
          if (this.currentChannelUrl === channel.id) {
            this.streamUpdatesOnCurrentMessageBatch();
            if (this.currentlyTyping.has(message.userId)) {
              clearTimeout(this.currentlyTyping.get(message.userId));
              this.currentlyTyping.delete(message.userId);
            }
          }

          if (this.currentChannelUrl === 'modcon_2024') {
            return;
          }

          if (this.currentChannelUrl !== channel.id && Object.values(message.mentionedUsers as MessageMentionedUsers).some((user) => user.id === this.chat?.currentUser?.id)) {
            this.addMention(channel.id);
            this.addAlert(channel.id);

            const htmlToRawTextWithoutMentions = (html: string): string => {
              // Create a temporary DOM element
              const tempElement = document.createElement('div');
              tempElement.innerHTML = html;

              const processNode = (node: Node) => {
                let result = '';

                node.childNodes.forEach((child: ChildNode) => {
                  if (child.nodeType === Node.TEXT_NODE) {
                    // If it's a text node, append its text content
                    result += child.textContent;
                  } else if (child.nodeType === Node.ELEMENT_NODE) {
                    if ((child as HTMLElement).tagName.toLowerCase() === 'span' && (child as HTMLElement).classList.contains('mention')) {
                      // Skip this node, effectively removing the mention
                    } else {
                      // Otherwise, process its child nodes recursively
                      result += processNode(child);
                    }
                  }
                });

                return result;
              };

              return processNode(tempElement);
            };
            //Send system notification on mention
            const notificationParams = {
              title: 'Message from ' + knownUser?.name || '',
              body: htmlToRawTextWithoutMentions(message.text),
            };
            sendNotification(notificationParams);
          } else if (this.currentChannelUrl !== channel.id) {
            this.addAlert(channel.id);
          } else {
            this.currentChannelMutations++;
          }
        })
        .then(({ disconnect }) => {
          log.debug('joined channel', channel.id);
          // add channel to order
          this.channelOrder.push(channel.id);
          this.channelDisconnectFns.set(channel.id, disconnect);
          this.knownChannels.set(channel.id, channel);
          this.loadChannelParticipants(channel.id);
          // parse the channel custom operators json
          if (channel.custom?.operators) {
            try {
              const operators = JSON.parse(channel.custom.operators);
              if (Array.isArray(operators)) {
                this.operators.set(channel.id, operators);
              }
            } catch (e) {
              log.error('failed to parse operators json');
            }
          }
          channel
            .getPinnedMessage()
            .then((message) => {
              if (message) {
                this.pinnedMessagesForChannels.set(channel.id, [message]);
              }
            })
            .catch((_e) => {
              // nothing to do here yet
            });
          // fill the pins
          let channelPins = channel.custom?.pins ? JSON.parse(channel.custom.pins) : [];
          if (!Array.isArray(channelPins)) {
            channelPins = [];
          }
          channelPins.forEach((pin: string) => {
            channel.getMessage(pin).then((message) => {
              if (!message) {
                return;
              }

              addToArrayInMap(this.pinnedMessagesForChannels, channel.id, message);
            });
          });
        })
        .catch((error) => {
          this.lastErrors.push(JSON.stringify(error));
          log.error('failed to join channel');
          log.error(error);
          log.error(error.status);
        });
    },
    loadChannelParticipants(id: string) {
      const channel = this.knownChannels.get(id);
      if (!channel) {
        return;
      }
      channel
        .getMembers()
        .then((members) => {
          this.channelParticipants.set(
            id,
            members.members.map((member) => member.user)
          );
          if (OMIT_CHANNEL_WATCHES.includes(id)) {
            return;
          }
          // also add to known users if we need to know them
          members.members.forEach((member) => {
            if (member.user.active) {
              this.onlineUserIds.add(member.user.id);
            }
            if (!this.knownUsers.some((user) => user.id === member.user.id)) {
              this.knownUsers.push({
                id: member.user.id,
                name: member.user.name ?? 'Unknown',
                profileUrl: member.user.profileUrl ?? '',
              });
            }
            if (member.user.active) {
              addToArrayInMap(this.participantsOnlineInChannel, id, member.user.id);
            } else {
              removeFromArrayInMap(this.participantsOnlineInChannel, id, member.user.id);
            }
          });
        })
        .catch(() => {
          log.error('failed to get channel participants');
        });
    },
    deleteMessageFromChannel(channelId: string, timetoken: string) {
      const messageIndexToRemove = this.channelMessages.get(channelId)?.findIndex((m) => m.timetoken === timetoken);
      if (messageIndexToRemove === -1 || messageIndexToRemove === undefined) {
        return;
      }
      this.channelMessages.get(channelId)?.splice(messageIndexToRemove, 1);
    },
    deleteMessage(timetoken: string) {
      // just prune it from the message list, as its deleted in the channel view
      const messages = this.currentChannelMessages;
      const index = messages.findIndex((m) => m.timetoken === timetoken);
      if (index > -1) {
        messages[index]
          .delete({ soft: true })
          .then(() => {
            // we dont need to do anything here, the handler will take care of it
          })
          .catch((e) => {
            log.error('failed to delete message');
            log.error(e);
            log.error(e.status);
          });
      }
    },
    removePin(timetoken: string) {
      const message = this.getMessage(timetoken);
      if (!message) {
        return;
      }
      const appStore = useAppStateStore();
      const channel_id = this.currentChannelUrl;
      appStore.api.unpinMessageInChannel(channel_id, timetoken).then(() => {
        removeFromArrayInMapWithPredicate(this.pinnedMessagesForChannels, channel_id, (m) => m.timetoken === timetoken);
      });
    },
    pinMessage(timetoken: string) {
      const message = this.getMessage(timetoken);
      if (!message) {
        return;
      }
      const appStore = useAppStateStore();
      const channel_id = this.currentChannelUrl;
      appStore.api.pinMessageInChannel(channel_id, timetoken).then(() => {
        addToArrayInMap(this.pinnedMessagesForChannels, channel_id, message);
      });
    },
    streamUpdatesOnCurrentChannelList() {
      if (!this.chat) {
        return;
      }
      if (this.currentChannelListDisconnectFunction) {
        log.debug('removing channel list watch and registering another channel list watch');
        this.currentChannelListDisconnectFunction();
      }
      this.currentChannelListDisconnectFunction = Channel.streamUpdatesOn(Array.from(this.knownChannels.values()) as Channel[], (channels) => {
        channels.forEach((channel) => {
          this.chat?.getChannel(channel.id).then((updatedChannel) => {
            console.log('got a channel update', updatedChannel);
            // @ts-ignore
            currentChannel.name = updatedChannel.name ?? currentChannel.name;
            // @ts-ignore
            currentChannel.custom = updatedChannel.custom ?? currentChannel.custom;
            // @ts-ignore
            currentChannel.description = updatedChannel.description ?? currentChannel.description;

            this.loadChannelParticipants(channel.id);
          });
        });
      });
    },
    leaveChannel(id: string) {
      const channel = this.knownChannels.get(id);
      if (!channel) {
        return;
      }
      channel
        .leave()
        .then(() => {
          this.knownChannels.delete(id);
          this.channelMessages.delete(id);
          this.channelParticipants.delete(id);
        })
        .catch((e) => {
          log.error('failed to leave channel');
          log.error(e);
        });
    },
    muteUserWhoPostedMessage(message: Message, timeout: number) {
      if (!this.chat) {
        return;
      }
      const channel = this.knownChannels.get(message.channelId);
      if (!channel) {
        return;
      }
      this.chat
        .setRestrictions(message.userId, message.channelId, {
          mute: true,
        })
        .then(() => {
          // TODO: Move this to somewhere else, i could just leave and they'd never been unmuted in this channel
          setTimeout(() => {
            this.chat?.setRestrictions(message.userId, message.channelId, {
              mute: false,
            });
          }, timeout * 1000);
        })
        .catch((e) => {
          log.error('failed to set restrictions');
          log.error(e);
          log.error(e.status);
        });
    },
    async loadChannelMessages(id: string) {
      const channel = this.knownChannels.get(id);
      if (!channel) {
        return;
      }
      if (this.loadingChannels.has(id) || this.finishedLoadingChannels.has(id)) {
        log.debug('not loading messages for channel', id, {
          loading: this.loadingChannels.has(id),
          finished: this.finishedLoadingChannels.has(id),
        });
        return;
      }
      let shouldGetMore = true;
      this.loadingChannels.add(id);
      const discoveredMessages: Message[] = [];
      const currentMessageLength = this.channelMessages.get(id)?.length ?? 0;

      while (shouldGetMore) {
        // use channelMessages if discoveredMessages is empty
        let lastKnownMessageTimetoken = this.channelMessages.get(id)?.[0]?.timetoken ?? null;
        if (discoveredMessages.length !== 0) {
          lastKnownMessageTimetoken = discoveredMessages[0]?.timetoken ?? null;
        }
        const historyParameters = {
          count: MESSAGES_TO_LOAD_MAX,
          startTimetoken: lastKnownMessageTimetoken ? `${lastKnownMessageTimetoken}` : undefined,
        };
        log.debug('loading messages for channel', id, historyParameters);
        try {
          const { messages, isMore } = await channel.getHistory(historyParameters);
          // prepend to discovered messages
          discoveredMessages.unshift(...messages);
          // these are loaded in chunks of 25, so if we get less than 25, we're done
          if (messages.length < 20 || discoveredMessages.length >= 50) {
            shouldGetMore = false;
          }
        } catch (error) {
          log.error('failed to load more messages');
          log.error(error);
          shouldGetMore = false;
        }
      }
      const knownMessages = [...discoveredMessages, ...(this.channelMessages.get(id) ?? [])];
      // deduplicate knownMessages by user id and timetoken
      const dedupedMessages = new Map<string, Message>();
      knownMessages.forEach((message) => {
        dedupedMessages.set(`${message.timetoken}-${message.userId}`, message as Message);
      });
      this.channelMessages.set(id, Array.from(dedupedMessages.values()));
      // compare the old currentMessagesLength to the new one
      if (this.channelMessages.get(id)?.length === currentMessageLength) {
        this.finishedLoadingChannels.add(id);
      }
      // see what missing ids we have from knownUsers vs dedupedMessages
      const missingUserIds = Array.from(new Set(knownMessages.map((m) => m.userId))).filter((userId) => !this.knownUsers.some((user) => user.id === userId));
      this.updateKnownUsers(missingUserIds).then().catch();
      // build out the message reactions that are known
      dedupedMessages.forEach((message) => {
        this.knownReactionsToMessages.set(message.timetoken, message.reactions);
      });
      this.streamUpdatesOnCurrentMessageBatch();
      this.loadingChannels.delete(id);
      this.currentChannelMutations++;
    },
    replyToMessage(timeToken: string) {
      const messages = this.currentChannelMessages;
      const message = messages.find((m) => m.timetoken === timeToken);
      if (!message) {
        return;
      }
      this.channelComposerStates.set(message.channelId, {
        channelUrl: message.channelId,
        composerState: STATE_REPLYING,
        replyingToMessage: message,
        editingMessage: null,
      });
    },
    streamUpdatesOnCurrentMessageBatch() {
      if (this.currentMessageStreamDisconnectFunction) {
        log.debug('removing batch watch and registering another batch watch');
        this.currentMessageStreamDisconnectFunction();
      }
      const messages = this.channelMessages.get(this.channelUrl);
      // remove all current individual message stream events
      this.pendingMessageUpdateStreams.get(this.channelUrl)?.forEach((disconnect) => {
        disconnect();
      });
      if (!messages || messages.length <= 0) {
        return;
      }
      // connect to the new batch of messages
      if (messages && messages.length > 0) {
        this.currentMessageStreamDisconnectFunction = Message.streamUpdatesOn(messages as Message[], (msgs) => {
          // mutate, but not if we're loading messages on this same channel right now
          if (!this.loadingChannels.has(msgs[0]?.channelId) && this.currentChannelUrl === msgs[0]?.channelId) {
            this.currentChannelMutations++;
          }
          msgs.forEach((incomingMessageUpdate) => {
            this.updateMessage(incomingMessageUpdate);
            this.knownReactionsToMessages.set(incomingMessageUpdate.timetoken, Object.fromEntries(Object.entries(incomingMessageUpdate.reactions).filter(([key, value]) => value.length > 0)));
            if (incomingMessageUpdate.channelId === this.currentChannelUrl) {
              this.currentChannelMutations++;
            }
          });
        });
      }
    },

    getMessage(timetoken: string): Message | undefined {
      const messages = this.currentChannelMessages;
      return messages.find((m) => m.timetoken === timetoken) as Message | undefined;
    },

    refreshMessageFromPubnub(timetoken: string) {
      const currentChannel = this.knownChannels.get(this.channelUrl);
      const message = this.getMessage(timetoken);
      if (!message) {
        return;
      }
      currentChannel
        ?.getMessage(timetoken)
        .then((updatedMessage) => {
          this.updateMessage(updatedMessage);
        })
        .catch((e) => {
          log.error('failed to get message from pubnub?');
          log.error(e);
        });
    },
    updateMessage(message: Message) {
      // log.debug('updating message', message.timetoken);
      const messages = this.channelMessages.get(message.channelId);
      if (!messages) {
        return;
      }
      const index = messages.findIndex((m) => m.timetoken === message.timetoken);
      if (index > -1) {
        messages.splice(index, 1, message);
      } else {
        messages.push(message);
      }
    },
    stopReplyingToMessage(channelUrl: string) {
      this.channelComposerStates.delete(channelUrl);
    },
    startEditingMessage(timeToken: string) {
      const message = this.getMessage(timeToken);
      if (!message) {
        return;
      }
      this.channelComposerStates.set(message.channelId, {
        channelUrl: message.channelId,
        composerState: STATE_EDITING,
        replyingToMessage: null,
        editingMessage: message,
      });
    },
    stopEditingCurrentMessage() {
      this.stopEditingMessage(this.channelUrl);
    },
    stopEditingMessage(channelUrl: string) {
      this.channelComposerStates.delete(channelUrl);
    },
    addAlert(channelUrl: string) {
      invoke('bounce_dock_icon').catch((error) => {
        log.error('failed to bounce dock icon');
        log.error(error);
      });
      const currentAlertCount = this.channelAlerts.get(channelUrl) ?? 0;
      this.channelAlerts.set(channelUrl, currentAlertCount + 1);
      localStorage.setItem('chat-alerts', serializeMap(this.channelAlerts));
    },
    addMention(channelUrl: string) {
      const currentMentionCount = this.unreadMentions.get(channelUrl) ?? 0;
      this.unreadMentions.set(channelUrl, currentMentionCount + 1);
      localStorage.setItem('chat-mentions', serializeMap(this.unreadMentions));
    },
    removeAlerts(channelUrl: string) {
      this.channelAlerts.delete(channelUrl);
      localStorage.setItem('chat-alerts', serializeMap(this.channelAlerts));
    },
    removeMentions(channelUrl: string) {
      this.unreadMentions.delete(channelUrl);
      localStorage.setItem('chat-mentions', serializeMap(this.unreadMentions));
    },
    changeChannel(id: string) {
      log.debug('changing channel to', id);
      this.channelUrl = id;
      if (this.knownChannels.get(id) && !this.channelDisconnectFns.has(id)) {
        log.debug('channel already known, connecting to it', id);
        this.connectToChannel(this.knownChannels.get(id) as Channel);
      }
      if (!this.channelMessages.has(id) || this.channelMessages.get(id)?.length === 0) {
        log.debug('loading messages for channel', id);
        this.loadChannelMessages(id).catch((error: any) => {
          log.error('Failed to load channel messages');
          log.error(error);
        });
        this.loadChannelParticipants(id);
      }
      this.dumpCurrentlyTypingList();
      this.watchTyping(id);
      this.watchPresence(id);
      this.removeAlerts(id);
      this.removeMentions(id);
      // clear local mutations
      this.currentChannelMutations = 0;
      localStorage.setItem('current-channel', id);
    },
    dumpCurrentlyTypingList() {
      this.currentlyTyping.forEach((timeout) => {
        clearTimeout(timeout);
      });
      this.currentlyTyping.clear();
    },
    watchPresence(id: string) {
      const channel = this.knownChannels.get(id);
      if (!this.chat || !channel) {
        return;
      }
      if (this.currentChannelParticipantListStreamDisconnectFunction) {
        log.debug('removing presence watch and registering another presence watch');
        this.currentChannelParticipantListStreamDisconnectFunction();
      }
      channel
        .streamPresence((userIds: string[]) => {
          if (userIds.length === 0) {
            log.warn('got empty response from streamPresence?');
            return;
          }
        })
        .then((disconnect) => {
          this.currentChannelParticipantListStreamDisconnectFunction = disconnect;
        });
    },
    watchTyping(id: string) {
      const channel = this.knownChannels.get(id);
      if (!this.chat || !channel) {
        return;
      }
      if (this.currentChannelStopTypingUpdatesDisconnectFunction) {
        log.debug('removing typing watch and registering another typing watch');
        this.currentChannelStopTypingUpdatesDisconnectFunction();
      }
      this.dumpCurrentlyTypingList();
      this.currentChannelStopTypingUpdatesDisconnectFunction = channel.getTyping((typing) => {
        this.updateTypers(typing, channel.id);
      });
    },
    updateTypers(typing: string[], id: string) {
      if (this.currentChannelRaw?.id !== id) {
        log.warn('I should not have received this event');
        return;
      }
      typing.forEach((typingUser) => {
        if (typingUser === this.chat?.currentUser?.id) {
          return;
        }
        if (this.currentlyTyping.has(typingUser)) {
          clearTimeout(this.currentlyTyping.get(typingUser));
        }
        this.currentlyTyping.set(
          typingUser,
          setTimeout(() => {
            this.currentlyTyping.delete(typingUser);
          }, 5000)
        );
      });
    },
    applyReactionToMessage(timetoken: string, emoji: string) {
      const message = this.getMessage(timetoken);
      if (!message) {
        return;
      }
      message.toggleReaction(emoji).catch((e) => {
        log.error('failed to apply reaction to message');
        log.error(e);
      });
    },
    async createDirectConversation(user: ChatUser): Promise<void> {
      if (!this.chat || !this.chat.currentUser?.name) {
        return;
      }
      const appStore = useAppStateStore();
      const { channel_id } = await appStore.api.createChatChannel({
        invite_uuids: [user.id],
        type: 'direct',
      });
      await this.refreshTokenAsync();
      this.handlePostChannelCreation(channel_id);
    },
    async createGroupConversation(participants: ChatUser[]): Promise<void> {
      if (!this.chat || !this.chat.currentUser?.name) {
        return;
      }
      const appStore = useAppStateStore();
      const { channel_id } = await appStore.api.createChatChannel({
        invite_uuids: participants.map((user) => user.id),
        type: 'group',
      });
      await this.refreshTokenAsync();
      this.handlePostChannelCreation(channel_id);
    },
    handlePostChannelCreation(channel_id: string) {
      const currentlySubscribedChannels = this.chat?.sdk.getSubscribedChannels();
      if (currentlySubscribedChannels.includes(channel_id)) {
        log.debug('found hot subscription, removing it to prevent double subscribe', channel_id);
        this.changeChannel(channel_id);
        this.loadChannelMessages(channel_id).catch((error: any) => {
          log.error('Failed to load channel messages');
          log.error(error);
        });
        return;
      } else {
        this.chat?.getChannel(channel_id).then((channel) => {
          if (!channel) {
            return;
          }
          this.connectToChannel(channel);
          waitFor(() => {
            return this.knownChannels.has(channel.id);
          }).then(() => {
            this.changeChannel(channel.id);
            this.loadChannelMessages(channel.id).catch((error: any) => {
              log.error('Failed to load channel messages');
              log.error(error);
            });
          });
        });
      }
    },
    createChannel(participants: ChatUser[]): Promise<void> {
      if (participants.length === 1) {
        return this.createDirectConversation(participants[0]);
      }
      return this.createGroupConversation(participants);
    },
    scheduleTokenRefresh() {
      if (this.tokenRefreshInterval) {
        clearInterval(this.tokenRefreshInterval);
      }
      this.tokenRefreshInterval = setInterval(() => {
        if (this.tokenExpirationTime && Date.now() > this.tokenExpirationTime.getTime()) {
          this.refreshChatToken();
        }
      }, 5000);
    },
    async refreshTokenAsync() {
      const appStore = useAppStateStore();
      log.debug('refreshing chat token async');
      this.authentication = await appStore.refreshChatToken();
      await appStore.api.getSignedCookie(this.authentication.token);
      this.tokenExpirationTime = new Date(Date.now() + this.authentication.ttl * 1000 - 10000);
      if (this.chat && this.chat.sdk) {
        log.debug('setting new token on chat sdk');
        this.chat.sdk.setToken(this.authentication.token);
      }
    },
    refreshChatToken(fromErrorState = false) {
      log.debug('refreshing chat token');
      if (this.busyUpdatingToken) {
        log.debug('already refreshing token');
        return;
      }
      const appStore = useAppStateStore();
      this.busyUpdatingToken = true;
      if (fromErrorState && this.currentRecoveryAttempts > 5) {
        log.error('too many recovery attempts, giving up on life until refresh');
        return;
      }
      if (fromErrorState) {
        log.debug('recovering from error state');
        this.currentRecoveryAttempts++;
        this.disconnect().then(() => {
          log.debug(`re-initializing entire chat app after token refresh due to lost connection? mytimein-user-${appStore.currentUser?.id}`);
          this.initSdkIfRequired(`${appStore.currentUser?.id}`).then(() => {
            this.busyUpdatingToken = false;
          });
        });
        return;
      }
      log.debug('fetching another token');
      appStore
        .refreshChatToken()
        .then((auth) => {
          log.debug('got auth token response', auth);
          this.authentication = auth;
          this.tokenExpirationTime = new Date(Date.now() + auth.ttl * 1000 - 10000);
          if (this.chat && this.chat.sdk) {
            this.chat.sdk.setToken(auth.token);
          }
          // set our cookie
          appStore.api.getSignedCookie(auth.token).then().catch();
        })
        .catch((error) => {
          log.error('failed to refresh chat token');
          log.error(error);
          log.error(error.status);
        })
        .finally(() => {
          this.busyUpdatingToken = false;
        });
    },
    addPendingSend(channelId: string, pendingMessage: PendingChatMessage): number {
      // perform some additional mutations to make it look like a partial<Message>
      pendingMessage.timetoken = TimetokenUtils.dateToTimetoken(new Date());
      pendingMessage.content = {
        files: [],
        text: 'Sending Message...',
        type: 'text',
        meta: {
          type: 'pending',
        },
      };
      pendingMessage.mentionedUsers = [];
      pendingMessage.channelId = channelId;
      pendingMessage.userId = this.currentUserId;
      pendingMessage.meta = { type: 'pending' };
      pendingMessage.customStatus = 'Sending Message...';
      addToArrayInMap(this.pendingMessagesMap, channelId, pendingMessage);

      return pendingMessage.timetoken;
    },
    updatePendingSendCustomStatus(channelId: string, pendingTimetoken: number, status: string) {
      const pendingMessage = this.pendingMessagesMap.get(channelId)?.find((m) => m.timetoken === `${pendingTimetoken}`);
      if (!pendingMessage) {
        return;
      }
      pendingMessage.content.text = pendingMessage.customStatus = status;
    },
    decrementPendingSend(channelId: string, pendingTimetoken: number) {
      removeFromArrayInMapWithPredicate(this.pendingMessagesMap, channelId, (m) => m.timetoken === pendingTimetoken);
    },
    async updateChannel(id: string, newParticipants: ChatUser[]) {
      const channel = this.knownChannels.get(id);
      if (!channel || newParticipants.length === 0) {
        return;
      }
      if (channel.id.startsWith('direct.') && newParticipants.length > 0) {
        // if the original channel was a direct channel and we are adding users to it, we need to actually make a new group channel instead
        const currentParticipants = (await channel.getMembers()).members.map((member) => member.user);
        return this.createChannel([...newParticipants, ...currentParticipants]);
      }
      const appStore = useAppStateStore();

      appStore.api
        .inviteMembersToChatChannel({
          channel_id: channel.id,
          invite_uuids: newParticipants.map((user) => user.id),
        })
        .then(() => {
          this.loadChannelParticipants(id);
        })
        .catch((e) => {
          log.error('failed to invite multiple users');
          log.error(e);
        });
    },
    /**
     * THIS HORRIBLE FUNCTION SENDS A DEAD MESSAGE TO THE QUEUE TO KEEP THINGS ACTIVE ON MACOS BECAUSE WEBKIT IS AWFUL
     */
    async sendPulse() {
      if (!this.chat || !this.chat.currentUser) {
        return;
      }
      this.chat.sdk
        .signal({
          channel: this.chat.currentUser.id,
          message: 'pulse',
        })
        .then(() => {
          // this should keep pubnub alive in background in webkit
        })
        .catch((e: any) => {
          log.error('failed to pulse');
          log.error('error value from pulse', e);
          log.error(e.status);
        });
    },
    async updateKnownUsers(missingIds: string[]) {
      if (!this.chat) {
        return;
      }
      missingIds.forEach((id) => {
        if (id === 'unknown-user') {
          // too early?
          return;
        }
        if (id === 'PUBNUB_INTERNAL_MODERATOR') {
          const knownUserIndex = this.knownUsers.findIndex((u) => u.id === id);
          if (knownUserIndex < 1) {
            this.knownUsers.push({
              id: id,
              name: 'Moderator',
              profileUrl: '',
            });
          }
          return;
        }
        this.chat
          ?.getUser(id)
          .then((user) => {
            if (!user) {
              return;
            }
            if (user.active && !this.onlineUserIds.has(user.id)) {
              this.onlineUserIds.add(user.id);
            }
            // replace a known user, if we have one
            const knownUserIndex = this.knownUsers.findIndex((u) => u.id === user.id);
            if (knownUserIndex > -1) {
              this.knownUsers.splice(knownUserIndex, 1, {
                id: user.id,
                name: user.name ?? 'Unknown',
                profileUrl: user.profileUrl ?? '',
              });
              return;
            }
            this.knownUsers.push({
              id: user.id,
              name: user.name ?? 'Unknown',
              profileUrl: user.profileUrl ?? '',
            });
          })
          .catch((error) => {
            this.lastErrors.push(JSON.stringify(error));
            log.error('Failed to query user');
            log.error(error);
            log.error(error.status);
          });
      });
    },
    reinitializeChat() {
      if (!this.chat) {
        log.warn('[chat] not initialized yet.. no work to do');
        return;
      }
      const currentUserId = this.chat.currentUser?.id;
      this.disconnect().then(() => {
        log.debug('disconnect ok, cleaned up. Waiting 5s to reinitialize');
        setTimeout(() => {
          this.initSdkIfRequired(currentUserId).then().catch();
        }, 1000);
      });
    },
  },
  getters: {
    currentlyReplyingTo: (state): Message | null => {
      return (state.channelComposerStates.get(state.channelUrl)?.replyingToMessage ?? null) as Message | null;
    },
    currentlyEditingMessage: (state): Message | null => {
      return (state.channelComposerStates.get(state.channelUrl)?.editingMessage ?? null) as Message | null;
    },
    currentChannelParticipants: (state): ChatUser[] => {
      return (state.channelParticipants.get(state.channelUrl) ?? []) as ChatUser[];
    },
    isLoadingCurrentChannelUrl: (state): boolean => {
      return state.loadingChannels.has(state.channelUrl);
    },
    currentChannelUrl: (state): string => {
      return state.channelUrl;
    },
    currentChannelMessages: (state): Message[] => {
      return (state.channelMessages.get(state.channelUrl) ?? []) as Message[];
    },
    currentPendingMessages: (state): PendingChatMessage[] => {
      return state.pendingMessagesMap.get(state.channelUrl) ?? [];
    },
    currentlyTypingNames: (state): string[] => {
      return Array.from(state.currentlyTyping.keys()).map((id) => state.knownUsers.find((user) => user.id === id)?.name ?? '');
    },
    currentChannelRaw: (state): Channel | undefined => {
      return state.knownChannels.get(state.channelUrl) as Channel | undefined;
    },
    currentChannelOnlineUserIds: (state): string[] => {
      return state.participantsOnlineInChannel.get(state.channelUrl) ?? [];
    },
    currentUserId: (state): string | null => {
      return state.chatUserId;
    },
    currentChannelPins: (state): Message[] => {
      return (state.pinnedMessagesForChannels.get(state.channelUrl) ?? []) as Message[];
    },
    currentChannelPinnedTimetokens: (state): string[] => {
      return state.pinnedMessagesForChannels.get(state.channelUrl)?.map((m) => m.timetoken) ?? [];
    },
    getUser:
      (state) =>
      (id: string): ChatUser | undefined => {
        return state.knownUsers.find((user) => user.id === id) as ChatUser | undefined;
      },
    canOperateOnCurrentChannel: (state): boolean => {
      return state.operators.get(state.channelUrl)?.includes(state.chatUserId ?? '') ?? false;
    },
    reactionsForMessage:
      (state) =>
      (timetoken: string): ChatReactionCollection => {
        return state.knownReactionsToMessages.get(timetoken) ?? {};
      },
  },
});

import { defineStore } from 'pinia';
import { useAppStateStore } from '@src/store/app';

function getInitialState() {
  return {
    alerts: [] as string[],
    dashboard: [] as LastShiftWarning[],
    success: [] as string[],
    communicationErrors: [] as string[],
    noScheduleAvailable: false as boolean,
  };
}
export const useAlertStore = defineStore('alert', {
  state: () => getInitialState(),
  getters: {
    dashboardWarnings: (state) => state.dashboard,
  },
  actions: {
    addAlerts(alerts: string[]) {
      alerts.forEach((alert) => {
        this.alerts.push(alert);
      });
    },
    removeAlert(index: number) {
      this.alerts.splice(index, 1);
    },
    addClockDriftAlert() {
      // check if a clock drift alert exists and remove it
      const index = this.alerts.findIndex((alert) => alert.includes('Clock drift detected'));
      if (index >= 0) {
        return;
      }
      this.alerts.push('Clock drift detected. Please refresh the page to correct the time.');
    },
    async refreshWarnings() {
      const appStateStore = useAppStateStore();
      appStateStore.api.getWarningsForLastShift().then((response) => {
        this.dashboard = response as unknown as LastShiftWarning[];
      });
    },
    clearWarning(index: number) {
      this.dashboard.splice(index, 1);
    },
    clearDashboardWarnings() {
      this.dashboard = [];
    },
    clearAll() {
      this.alerts = [];
      this.dashboard = [];
      this.success = [];
      this.communicationErrors = [];
    },
  },
});

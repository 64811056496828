import twemoji from 'twemoji';
import { EmojiManager } from '@src/utils/emoji_manager';

export const emojiDirective = {
  mounted(el: HTMLElement) {
    el.innerHTML = twemoji.parse(el.innerHTML, {
      base: 'emoji/',
      folder: '64',
      ext: '.png',
      attributes: (icon, variant) => {
        const emojiManager = EmojiManager.getInstance();
        const emoji = emojiManager.getEmojiMap().get(icon);
        if (!emoji) {
          return {};
        }

        const searchTerm = ':' + emoji.name.split(':')[0].split(' ').join('_').toLowerCase() + ':';
        return {
          'data-name': searchTerm,
          draggable: false,
        };
      },
    });

    // Now replace <img> tags with <div> elements with background images
    const imgElements = el.querySelectorAll('img.emoji') as NodeListOf<HTMLImageElement>; // Ensure you're targeting the right elements
    imgElements.forEach((img) => {
      // Get the emoji URL from the <img> src attribute
      const emojiUrl = img.src;

      // Create a <div> element to replace the <img>
      const div = document.createElement('div');
      div.classList.add('twemoji-emoji');
      div.style.display = 'inline-block';
      // div.style.backgroundSize = '1680px 1560px';
      // div.style.width = '40px';
      // div.style.height = '40px';
      div.style.backgroundImage = `url('${emojiUrl}')`;
      div.style.backgroundSize = 'contain';
      div.style.backgroundRepeat = 'no-repeat';
      div.style.backgroundPosition = 'center';

      // Copy any attributes from the <img> to the <div> (like data-name, etc.)
      Array.from(img.attributes).forEach((attr) => {
        div.setAttribute(attr.name, attr.value);
      });

      // Replace the <img> element with the new <div>
      img.replaceWith(div);
    });
  },
  beforeUnmount(el: HTMLElement) {
    el.innerHTML = '';
  },
};

<script setup lang="ts">
  import { DocumentIcon } from '@heroicons/vue/24/outline';
  import { ArrowDownIcon } from '@heroicons/vue/20/solid';
  import { ref, toRefs } from 'vue';
  import { save } from '@tauri-apps/plugin-dialog';
  import { useToast } from 'primevue/usetoast';
  import { Tippy } from 'vue-tippy';
  import { invoke } from '@tauri-apps/api/core';
  import { listen, UnlistenFn } from '@tauri-apps/api/event';
  import { calculateTooltipPosition } from '@utils/tooltip_calculator';
  import { useChatStore } from '@src/store/chat';
  const chatStore = useChatStore();

  const props = withDefaults(
    defineProps<{
      src: string | undefined;
      file: { name: string; url: string };
      size?: number[];
    }>(),
    {
      size: () => [],
    }
  );

  const { file } = toRefs(props);
  const toast = useToast();
  const downloading = ref(false);
  const savePath = ref<string | null>('');
  const listeners = ref<UnlistenFn[]>([]);
  const progressBar = ref<SVGPathElement>();
  const progressText = ref<SVGTextElement>();
  const progress = ref(0);
  const totalBytes = ref(0);

  function formatFileSize(bytes: number): string {
    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
    let size = bytes;
    let unitIndex = 0;

    // Convert to the appropriate unit
    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
    }

    // Round to two decimal places and return the size with the appropriate unit
    return `${size.toFixed(2)} ${units[unitIndex]}`;
  }

  const download = async () => {
    await registerListener();
    savePath.value = await save({
      defaultPath: trimAfterFifthDash(file.value.name),
      filters: [
        {
          name: file.value.name,
          extensions: [file.value.name.split('.').pop() || ''],
        },
      ],
    });

    if (!savePath.value) {
      listeners.value.forEach((unlisten) => unlisten());
      return;
    }

    downloading.value = true;
    try {
      await invoke('download_file', { url: props.src, filePath: savePath.value });
    } catch (error) {
      toast.add({
        severity: 'error',
        summary: 'Download Failed',
        detail: 'An error occurred while downloading the file.',
        life: 3000,
      });
      downloading.value = false;
      totalBytes.value = 0;
      progress.value = 0;
    }

    listeners.value.forEach((unlisten) => unlisten());
  };

  const handleMtiEvent = ({ payload }: { payload: MtiEvent }) => {
    if (payload.name === 'download_progress') {
      handleDownloadProgress(payload.data as { downloaded: number; totalSize: number });
    }
    if (payload.name === 'download_complete') {
      handleDownloadComplete(payload.data as { file_path: string });
    }
  };

  const handleDownloadProgress = (data: { downloaded: number; totalSize: number }) => {
    progress.value = (data.downloaded / data.totalSize) * 100;
    totalBytes.value = data.totalSize;
    updateProgress(data.downloaded, data.totalSize);
  };

  const handleDownloadComplete = (data: { file_path: string }) => {
    //here because the listener will pick up for any file on the page
    if (data.file_path !== savePath.value) {
      return;
    }
    setTimeout(() => {
      downloading.value = false;
      totalBytes.value = 0;
      progress.value = 0;
    }, 1000);
    toast.add({
      severity: 'info',
      summary: 'Download Complete',
      detail: {
        fileName: file.value.name,
        outputPath: savePath.value,
      },
      group: 'tr',
      life: 3000,
    });
    savePath.value = null;
  };

  const registerListener = async () => {
    if (listeners.value) {
      listeners.value.forEach((unlisten) => unlisten());
      listeners.value = [];
    }

    let mtiEventListener = await listen('mti_event', handleMtiEvent);

    listeners.value.push(mtiEventListener);
  };

  const updateProgress = (progress: number, totalBytes: number) => {
    let currentPercent = (progress / totalBytes) * 100;
    if (progressBar.value) {
      progressBar.value.style.strokeDasharray = `${currentPercent} 100`;
    }
    if (progressText.value) {
      progressText.value.textContent = `${currentPercent.toFixed(0)}%`;
    }
  };

  const trimAfterFifthDash = (filename: string) => {
    // Use a regex to match the fifth dash and everything after it
    return filename.replace(/(?:-[^-]*){5}(\.\w+)$/, '$1');
  };

  const showMessageOptionTooltip = (event: MouseEvent) => {
    chatStore.messageOptionTooltipMessage = 'Download';
    chatStore.showMessageOptionTooltip = true;

    requestAnimationFrame(() => {
      let success = calculateTooltipPosition(event, 'chat-layer2', 'message-options-tooltip', 'top', 10, 10);
      if (!success) {
        chatStore.messageOptionTooltipMessage = null;
        chatStore.showMessageOptionTooltip = false;
      }
    });
  };

  const hideMessageOptionTooltip = () => {
    chatStore.showMessageOptionTooltip = false;
  };
</script>

<template>
  <div class="rounded-md border p-4 shadow bg-surface-50 border-surface-200/50 group dark:border-surface-900 dark:bg-surface-900/30 dark:shadow-none">
    <div class="relative flex items-center space-x-0.5">
      <div v-if="downloading" class="absolute right-0 bottom-0">
        <svg class="size-10" viewBox="0 0 36 36">
          <!-- Inner circle with black background and 90% opacity -->
          <circle cx="18" cy="18" r="15.9155" class="fill-black/40" />

          <!-- Background circle path -->
          <path class="stroke-surface-0" stroke-width="3.8" fill="none" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />

          <!-- Progress bar path -->
          <path ref="progressBar" class="stroke-primary-400" stroke-width="3.8" fill="none" stroke-dasharray="0, 100" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />

          <!-- Progress text -->
          <text ref="progressText" x="17.8" y="21.5" class="text-2xs fill-surface-0" text-anchor="middle">0%</text>
        </svg>
      </div>
      <div class="absolute -top-2 -right-2 hidden group-hover:block">
        <div
          class="flex h-full w-8 items-center justify-center text-surface-600 first:rounded-tl-md first:rounded-bl-md last:rounded-tr-md last:rounded-br-md hover:bg-surface-200 dark:text-surface-400 dark:bg-surface-800 hover:active:text-surface-900 dark:hover:bg-surface-700 dark:hover:active:text-surface-200"
        >
          <button
            class="w-10 rounded-md border p-1 shadow bg-surface-0 border-surface-50 dark:border-surface-950 dark:bg-surface-800 dark:text-surface-500 dark:hover:text-surface-400 dark:hover:bg-surface-700"
            @click="download"
            @mouseenter="showMessageOptionTooltip"
            @mouseleave="hideMessageOptionTooltip"
          >
            <ArrowDownIcon class=""></ArrowDownIcon>
          </button>
        </div>
      </div>
      <DocumentIcon class="stroke-1 size-12 fill-surface-100 dark:fill-surface-300 dark:stroke-surface-400" />
      <div class="overflow-clip">
        <button class="truncate break-all text-primary-500 hover:underline" @click="download">
          {{ trimAfterFifthDash(file.name) }}
        </button>
        <p class="text-2xs dark:text-surface-400">
          {{ size.length ? formatFileSize(size[0]) : 0 + ' bytes' }}
        </p>
      </div>
    </div>
  </div>
</template>

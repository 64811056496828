<script setup lang="ts">
  import { nextTick, onMounted, onUnmounted, ref, watch } from 'vue';
  import { throttle } from '@src/utils/throttle';

  const INTERNAL_ID_PREFIX = 'item_';

  const props = withDefaults(
    defineProps<{
      chunkSize?: number;
      loadMoreDistance?: number;
      items: any[];
      emitRangeUpdate?: boolean;
      anchorBottomThreshold?: number;
    }>(),
    {
      anchorBottomThreshold: 10,
      emitRangeUpdate: false,
      chunkSize: 10,
      loadMoreDistance: 100,
    }
  );
  const emit = defineEmits(['load-next', 'load-prev', 'range', 'render-range', 'draw-ok', 'anchor-top', 'anchor-bottom', 'first-draw']);
  const modScroller = ref<HTMLElement | null>(null);
  const scrollerItself = ref<HTMLElement | null>(null);
  const loadPreviousObserver = ref<IntersectionObserver | null>(null);
  const loadNextObserver = ref<IntersectionObserver | null>(null);
  const scrollerSizeObserver = ref<ResizeObserver | null>(null);
  const containerResizeObserver = ref<ResizeObserver | null>(null);
  const rangeLocked = ref(false);
  const knownHeights = ref<Map<string, number>>(new Map());

  const itemResizeObserver = ref<ResizeObserver | null>(null);
  const currentlyObservingItems = ref<HTMLElement[]>([]);

  const onDeckRange = ref({ start: 0, end: 0 });
  const currentlyVisibleRange = ref({ start: 0, end: 0 });
  const stickToElement = ref<HTMLElement | null | undefined>(null);

  const topElementObserving = ref<HTMLElement | Element | null>(null);
  const bottomElementObserving = ref<HTMLElement | Element | null>(null);

  const firstRenderFinished = ref(false);

  watch(
    () => props.items.length,
    () => {
      if (shouldStickToBottomOfContainer()) {
        stickToElement.value = null;
        scrollToBottom();
        return;
      }
      const currentlyRenderingPosition = currentlyVisibleRange.value.start;
      if (!stickToElement.value) {
        return;
      }
      // grab a reference to the node we want to stick to before we jump into the next tick
      stickToElement.value = modScroller.value?.querySelector(`#item_${currentlyRenderingPosition}`);
    },
    { flush: 'sync' }
  );

  watch(
    () => props.items.length,
    () => {
      // immediately detach our anchors
      if (topElementObserving.value) {
        loadPreviousObserver.value?.unobserve(topElementObserving.value);
      }
      if (bottomElementObserving.value) {
        loadNextObserver.value?.unobserve(bottomElementObserving.value);
      }
      if (!firstRenderFinished.value) {
        firstRenderFinished.value = true;
        emit('first-draw');
        // scroll to bottom
        scrollToBottom();
        return;
      }
      // wait until next draw to attach the observers
      nextTick(() => {
        updateOnDeckRange();

        // do we need to wait for vue here?
        // request animation frame
        requestAnimationFrame(() => {
          if (stickToElement.value && scrollerItself.value) {
            scrollerItself.value.scrollTop = stickToElement.value.offsetTop;
            stickToElement.value = null;
          }
          attachAnchorToTop();
          attachAnchorToBottom();
          // updateViewRange();
          updateKnownSizes();
          updateItemResizerObservables();
          emit('draw-ok');
        });
      });
    }
  );

  const shouldStickToBottomOfContainer = () => {
    if (!scrollerItself.value) return false;

    const distanceFromBottom = scrollerItself.value.scrollHeight - scrollerItself.value.scrollTop - scrollerItself.value.clientHeight;
    return distanceFromBottom < props.anchorBottomThreshold;
  };

  const attachAnchorToTop = () => {
    if (!modScroller.value) {
      return;
    }
    // attach an intersection observer to the top element of the list
    // sort of, within reason. probably not the very first, maybe autoselect depending on the range. how about 25%?
    // let targetIndex = Math.floor(props.items.length * 0.25);
    let targetIndex = 0;
    const target = modScroller.value?.querySelector(`#item_${targetIndex}`);
    if (!target) return;

    // TODO: Check if we are already above this, as we jumped into skeleton loader section

    if (topElementObserving.value) {
      loadPreviousObserver.value?.unobserve(topElementObserving.value);
    }

    // console.log('attaching to top', target);

    topElementObserving.value = target;
    loadPreviousObserver.value?.observe(target);
  };

  const attachAnchorToBottom = () => {
    if (!modScroller.value) {
      return;
    }
    // attach an intersection observer to the bottom element of the list
    const target = modScroller.value?.querySelector(`#item_${props.items.length - 1}`);
    if (!target) return;

    // TODO: Check if we are already below this, as we jumped into skeleton loader section

    if (bottomElementObserving.value) {
      loadNextObserver.value?.unobserve(bottomElementObserving.value);
    }

    bottomElementObserving.value = target;
    loadNextObserver.value?.observe(target);
  };

  const createObservers = () => {
    loadPreviousObserver.value = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          emit('load-prev');
        }
      },
      {
        root: modScroller.value,
        rootMargin: '0px',
        threshold: 0,
      }
    );

    loadNextObserver.value = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          emit('load-next');
        }
      },
      {
        root: modScroller.value,
        rootMargin: '0px',
        threshold: 0,
      }
    );

    // scrollerSizeObserver.value = new ResizeObserver(() => {
    //   // TODO: This actually occurs before the new items are rendered, so lets just use the watcher instead?
    //   // if(shouldStickToBottomOfContainer.value) {
    //   //   requestAnimationFrame(() => {
    //   //     scrollToBottom();
    //   //   })
    //   // }
    // });
    // scrollerSizeObserver.value.observe(scrollerItself.value);

    itemResizeObserver.value = new ResizeObserver((entries) => {
      // update the size of each entry
      entries.forEach((entry) => {
        knownHeights.value.set(`${INTERNAL_ID_PREFIX}${(entry.target as HTMLElement).dataset.id}`, entry.contentRect.height);
      });
    });

    containerResizeObserver.value = new ResizeObserver(() => {
      if (shouldStickToBottomOfContainer()) {
        requestAnimationFrame(() => {
          scrollToBottom();
        });
      }
    });

    if (!modScroller.value) {
      return;
    }
    containerResizeObserver.value.observe(modScroller.value);
  };

  const updateItemResizerObservables = () => {
    // get items from inside the render range
    const items = Array.from(modScroller.value?.querySelectorAll('.mod-scroller-item') || []).filter((item) => !item.querySelector('.mod-scroller-skeleton'));
    if (!items) return;
    // unobserve the last stack we had
    currentlyObservingItems.value.forEach((item) => {
      itemResizeObserver.value?.unobserve(item);
    });
    currentlyObservingItems.value = [];
    // observe each item
    items.forEach((item) => {
      itemResizeObserver.value?.observe(item);
      currentlyObservingItems.value.push(item as HTMLElement);
    });
  };

  const scrollToBottom = () => {
    if (!scrollerItself.value) return;

    requestAnimationFrame(() => {
      // this is tricky, we need to update the ondeck range before we trigger a scroll or else it will not reach the very bottom
      // so adjust the currently visible range to be at the bottom to force the ondeck range to move
      currentlyVisibleRange.value = {
        start: props.items.length - 1,
        end: props.items.length - 1,
      };
      // update our on deck range so v-if does not interrupt scrolling with a re-render
      updateOnDeckRange();
      // on next tick, now scroll!
      nextTick(() => {
        // jump to the bottom
        scrollerItself.value?.scrollTo({
          // eslint-disable-next-line no-loss-of-precision
          top: 9999999999999999999,
          behavior: 'instant',
        });
        // we need to manually fire the scroller to update our positions, because instant scroll does not fire a scroll event
        updateViewRange();
      });
    });
  };

  /**
   * Updates the range currently being render in the DOM as real items
   */
  const updateOnDeckRange = () => {
    const { start, end } = currentlyVisibleRange.value;

    if (start === -1 || end === -1) return;

    // if we have less than 100 items, just render them all
    if (props.items.length <= 100) {
      // these are via indexes, so we need to adjust for the 0 index
      onDeckRange.value = { start: 0, end: props.items.length - 1 };
      if (props.emitRangeUpdate) {
        emit('render-range', onDeckRange.value);
      }
      return;
    }

    const rangeSize = 100;
    let newStart = Math.max(0, start - Math.floor(rangeSize / 2));
    let newEnd = newStart + (rangeSize - 1);

    if (newEnd > props.items.length) {
      newEnd = props.items.length;
      newStart = Math.max(0, newEnd - rangeSize);
    }

    onDeckRange.value = { start: newStart, end: newEnd };
    rangeLocked.value = true;

    if (props.emitRangeUpdate) {
      emit('render-range', onDeckRange.value);
    }
    // only observe items in the render range
    requestAnimationFrame(() => {
      updateItemResizerObservables();
    });
  };

  const updateViewRange = () => {
    if (!modScroller.value) return;

    const items = modScroller.value.querySelectorAll('.mod-scroller-item');
    let start = -1;
    let end = -1;

    items.forEach((item, index) => {
      const rect = item.getBoundingClientRect();
      const scrollerRect = modScroller.value!.getBoundingClientRect();

      if (rect.bottom > scrollerRect.top && rect.top < scrollerRect.bottom) {
        if (start === -1) start = index;
        end = index;
      }
    });

    currentlyVisibleRange.value = { start, end };

    const rangeSize = onDeckRange.value.end - onDeckRange.value.start;
    const topThreshold = onDeckRange.value.start + Math.floor(rangeSize * 0.15);
    const bottomThreshold = onDeckRange.value.end - Math.floor(rangeSize * 0.05);

    if (currentlyVisibleRange.value.start < topThreshold || currentlyVisibleRange.value.end > bottomThreshold) {
      updateOnDeckRange();
    }

    if (props.emitRangeUpdate) {
      emit('range', currentlyVisibleRange.value);
    }
  };

  const updateKnownSizes = () => {
    if (!modScroller.value) return;

    const items = modScroller.value.querySelectorAll('.mod-scroller-item');
    items.forEach((item) => {
      if (knownHeights.value.has(`${INTERNAL_ID_PREFIX}${(item as HTMLElement).dataset.id}`)) return;
      const rect = item.getBoundingClientRect();
      knownHeights.value.set(`${INTERNAL_ID_PREFIX}${(item as HTMLElement).dataset.id}`, rect.height);
    });
  };

  const throttledUpdater = throttle(() => {
    updateViewRange();
  }, 100);

  const isWithinRenderRange = (index: number) => {
    return index >= onDeckRange.value.start && index <= onDeckRange.value.end;
  };

  const getKnownHeights = () => {
    return knownHeights.value;
  };

  onMounted(() => {
    scrollerItself.value?.addEventListener('scroll', throttledUpdater);
    scrollerItself.value?.addEventListener('scrollend', throttledUpdater);
    nextTick(() => {
      createObservers();
      attachAnchorToTop();
      attachAnchorToBottom();
      updateOnDeckRange();
      // force a 1px scroll to trigger our bottom dynamic anchor
      scrollerItself.value?.scrollBy(0, 1);

      requestAnimationFrame(() => {
        updateKnownSizes();
        updateItemResizerObservables();
      });
    });
  });

  onUnmounted(() => {
    scrollerItself.value?.removeEventListener('scroll', throttledUpdater);
    scrollerItself.value?.removeEventListener('scrollend', throttledUpdater);
    if (loadPreviousObserver.value) {
      loadPreviousObserver.value.disconnect();
    }
    if (loadNextObserver.value) {
      loadNextObserver.value.disconnect();
    }
    if (scrollerSizeObserver.value) {
      scrollerSizeObserver.value.disconnect();
    }
  });

  const getObserving = () => {
    return currentlyObservingItems.value;
  };

  defineExpose({
    scrollToBottom,
    getKnownHeights,
    getObserving,
  });
</script>

<template>
  <div ref="modScroller" style="height: 100%">
    <div ref="scrollerItself" class="mod-scroller-itself" style="height: 100%; overflow-y: auto">
      <div v-for="(item, index) in items" :id="`item_${index}`" :key="item.timetoken" :data-id="item.timetoken" class="mod-scroller-item" :class="[shouldStickToBottomOfContainer() ? 'mod-sticky' : '']">
        <slot v-if="isWithinRenderRange(index)" :item="item" :index="index" />
        <div v-else class="mod-scroller-skeleton" :style="[knownHeights.has(`item_${item.timetoken}`) ? `min-height: ${knownHeights.get(`item_${item.timetoken}`)}px` : `min-height: auto`]">
          <!-- Less dom = more ram -->
        </div>
      </div>
      <!-- TODO: force the browser to anchor to the bottom more naturally -->
      <div v-if="shouldStickToBottomOfContainer()" id="mod-anchor"></div>
    </div>
  </div>
</template>

<style>
  #mod-anchor {
    overflow-anchor: auto;
    height: 1px;
  }

  .mod-sticky.mod-scroller-item {
    overflow-anchor: none;
  }
</style>

<script setup lang="ts">
  import { computed, ref } from 'vue';
  import Button from 'primevue/button';
  import { Tippy } from 'vue-tippy';
  import { useChatStore } from '@src/store/chat';
  import Popover from 'primevue/popover';
  import { Channel, Message } from '@pubnub/chat';
  import ThumbtackIcon from '@components/svgs/PinIcon.vue';
  import ChatPinnedMessage from '@components/Chat/ChatPinnedMessage.vue';
  import { StarIcon, BugAntIcon } from '@heroicons/vue/24/outline';
  import { useAppStateStore } from '@src/store/app';
  import { useToast } from 'primevue/usetoast';

  const toast = useToast();
  const chatStore = useChatStore();
  const appStore = useAppStateStore();
  const pins = ref<typeof Popover>();
  const emit = defineEmits(['add-member', 'open-sidebar', 'favorite', 'unfavorite']);
  const showParticipantControls = computed(() => {
    return !['modcon_2024', 'announcements'].includes(chatStore.currentChannelUrl);
  });

  const getUser = (userId: string) => {
    return chatStore.knownUsers.find((user) => user.id === userId);
  };

  const togglePins = (e) => {
    pins.value?.toggle(e);
  };

  const removePin = (timetoken: string) => {
    chatStore.removePin(timetoken);
  };

  const pinsAvailable = computed(() => {
    return chatStore.currentChannelUrl.startsWith('direct.') || chatStore.currentChannelUrl.startsWith('group.');
  });

  const isFavorite = computed(() => {
    return appStore.favoriteChannels.find((channel) => channel.id === currentChannel.value.id);
  });

  const sortedPins = computed((): Message[] => {
    let data = chatStore.currentChannelPins;
    return data.sort((a, b) => {
      return Number(b.timetoken) - Number(a.timetoken);
    }) as Message[];
  });

  const currentChannel = computed(() => {
    return chatStore.knownChannels.get(chatStore.currentChannelUrl) as Channel;
  });

  const toggleFavorite = () => {
    if (isFavorite.value) {
      emit('unfavorite', currentChannel.value.id);
      return;
    }
    emit('favorite', currentChannel.value.id);
  };

  const copyDebugInformation = () => {
    const debugInformation = {
      currentUserId: chatStore.chatUserId,
      currentChannel: chatStore.currentChannelRaw?.id,
      currentChannelMeta: chatStore.currentChannelRaw?.custom ?? 'no custom metadata on this channel',
      permissions: chatStore.chat.sdk.parseToken(chatStore.authentication.token),
      lastErrors: chatStore.lastErrors,
    };
    navigator.clipboard.writeText(JSON.stringify(debugInformation, null, 2));
    toast.add({
      severity: 'success',
      summary: 'Success',
      detail: 'Debug information copied to clipboard',
      life: 3000,
    });
  };

  const channelName = computed(() => {
    // Cache the channel ID and check early to reduce unnecessary processing
    const channelId = currentChannel.value?.id;
    if (!channelId || (!channelId.startsWith('direct.') && !channelId.startsWith('group.'))) {
      return currentChannel.value?.name ?? '...';
    }

    // Cache participants and check length early to avoid unnecessary computations
    const participants = chatStore.channelParticipants.get(channelId);
    const participantCount = participants?.length;

    if (!participantCount) {
      return currentChannel.value?.name ?? '...';
    }

    // If there's only one participant and it's the current user, return early
    if (participantCount === 1) {
      const singleUser = participants[0];
      return singleUser.id === chatStore.currentUserId ? `${singleUser.name} (me)` : singleUser.name;
    }

    // Filter out the current user if there are more participants
    const users = participants.filter((user) => user.id !== chatStore.currentUserId);

    // Map and sort only if necessary, and cache the results
    const userNames = users.length > 1 ? users.map((user) => user.name?.split(' ')[0]).sort() : users.map((user) => user.name);

    return userNames.join(', ');
  });
  const closeAllOpenMenus = () => {
    chatStore.emojiPickerOpen = false;

    chatStore.participantCardOpen = false;
    chatStore.participantCardUser = null;
    chatStore.composerFocusStealOverride = false;

    chatStore.showMessageContextMenu = false;
    chatStore.messageContextTimeToken = null;
  };
</script>
<template>
  <div class="flex w-full items-center border-b bg-white p-2 h-[64px] dark:bg-surface-900 dark:border-white/10 text-surface-500 dark:text-surface-300" @contextmenu.stop.prevent="closeAllOpenMenus">
    <h2 v-if="showParticipantControls" class="text-ellipsis font-semibold line-clamp-1 max-w-[350px]">
      {{ channelName }}
    </h2>
    <h2 v-else class="text-ellipsis font-semibold line-clamp-1 max-w-[350px]">
      {{ chatStore.currentChannelRaw?.name }}
    </h2>

    <div v-if="showParticipantControls" class="ml-2 space-x-2 flex items-center grow">
      <button v-if="pinsAvailable" @click="toggleFavorite">
        <StarIcon class="size-4" :class="[!isFavorite ? 'text-yellow-500 group-hover/favorite:fill-yellow-500' : 'text-yellow-500 fill-yellow-500 group-hover/favorite:text-white-500 group-hover/favorite:fill-transparent']" />
      </button>
      <Tippy placement="bottom">
        <template #content>
          <div class="text-xs p-2 bg-surface-0 dark:bg-surface-700 rounded-md shadow-md dark:text-surface-100 dark:border-black border">Add Member</div>
        </template>
        <Button icon="pi pi-plus" severity="secondary" icon-pos="left" size="small" label="Add" @click="emit('add-member')" />
      </Tippy>
      <Button v-if="pinsAvailable" severity="secondary" label="Pins" @click="togglePins">
        <ThumbtackIcon class="size-3.5" />
      </Button>
      <Button severity="secondary" label="debug" @click="copyDebugInformation">
        <BugAntIcon class="size-4" />
      </Button>
      <Popover v-if="pinsAvailable" ref="pins">
        <div class="flex flex-col gap-4 w-[25rem]">
          <div class="flex items-center justify-between">
            <h3 class="font-semibold">Pinned Messages</h3>
          </div>
          <div class="h-72 overflow-y-auto small-scroll pr-4">
            <ul v-if="sortedPins.length" role="list" class="divide-y divide-surface-100 dark:divide-surface-700">
              <li v-for="message in sortedPins" :key="message.timetoken">
                <ChatPinnedMessage :user="getUser(message.userId)" :message="message" @remove-pin="removePin(message.timetoken)" />
              </li>
            </ul>
            <div v-else class="flex items-center justify-center h-full">Nothing here yet!</div>
          </div>
        </div>
      </Popover>
      <Tippy placement="bottom">
        <template #content>
          <div class="text-xs p-2 bg-surface-0 dark:bg-surface-700 rounded-md shadow-md dark:text-surface-100 dark:border-black border">Participants</div>
        </template>
        <Button icon="pi pi-users" severity="secondary" icon-pos="left" size="small" :label="`${chatStore.currentChannelParticipants.length}`" @click="emit('open-sidebar')" />
      </Tippy>
    </div>
  </div>
</template>

import AudioPlayer from '@src/audio';
import { useActiveJobStore } from '@src/store/activejob';
import { useAlertStore } from '@src/store/alert';
import { useAppStateStore } from '@src/store/app';
import { useOperationsStore } from '@src/store/operations';
import { useRuntimeConfigurationStore } from '@src/store/runtimeconfiguration';
import { useTimerStore } from '@src/store/timer';
import { getVersion } from '@tauri-apps/api/app';
import { invoke } from '@tauri-apps/api/core';
import { listen, UnlistenFn } from '@tauri-apps/api/event';
import { BaseDirectory, readFile, remove } from '@tauri-apps/plugin-fs';
import { platform } from '@tauri-apps/plugin-os';
import { exit } from '@tauri-apps/plugin-process';
import { check, Update } from '@tauri-apps/plugin-updater';
import { Logger } from '@utils/logger';
import { CronJob } from 'cron';
import { DateTime, Duration } from 'luxon';
import { isPermissionGranted, requestPermission, sendNotification } from '@tauri-apps/plugin-notification';
import { useUserStore } from '@src/store/user';
import { useChatStore } from '@src/store/chat';
import { useGptStore } from '@src/store/gpt';

const log = new Logger('SENTINEL');

export class Sentinel {
  activeJobStore: ReturnType<typeof useActiveJobStore>;
  runtimeConfigurationStore: ReturnType<typeof useRuntimeConfigurationStore>;
  appStateStore: ReturnType<typeof useAppStateStore>;
  timerStore: ReturnType<typeof useTimerStore>;
  ongoingOperationsStore: ReturnType<typeof useOperationsStore>;
  alertStore: ReturnType<typeof useAlertStore>;
  userStore: ReturnType<typeof useUserStore>;
  chatStore: ReturnType<typeof useChatStore>;
  gptStore: ReturnType<typeof useGptStore>;
  update: Update | null;

  listeners: UnlistenFn[];
  sentryInstance: null;
  failedUploads: number[];
  frequency: number;
  audioPlayer: AudioPlayer;
  jobs: CronJob[];

  BREAK = 'Break' as string;
  OFF_THE_CLOCK = 'Off The Clock' as string;
  YET_TO_START = 'Yet to start' as string;
  LATE = 'Late' as string;
  LATE_ACTIVE = 'Late active' as string;
  ABSENT = 'Absent: no activity' as string;
  ACTIVE = 'Active' as string;
  REST_DAY = 'Rest day' as string;
  SYSTEM_LOGGED_OUT = 'System logged out' as string;
  PTO = 'PTO' as string;
  PTO_MOD = 'PTO (mod)' as string;
  UPTO_MOD = 'Upto (mod)' as string;
  UNDERTIME = 'Undertime' as string;

  constructor() {
    // get all the stores it needs
    this.activeJobStore = useActiveJobStore();
    this.runtimeConfigurationStore = useRuntimeConfigurationStore();
    this.timerStore = useTimerStore();
    this.ongoingOperationsStore = useOperationsStore();
    this.appStateStore = useAppStateStore();
    this.alertStore = useAlertStore();
    this.userStore = useUserStore();
    this.chatStore = useChatStore();
    this.gptStore = useGptStore();
    this.update = null;

    this.listeners = [];
    this.failedUploads = [];
    this.sentryInstance = null;
    this.audioPlayer = new AudioPlayer();
    this.jobs = [];
    this.frequency = this.getRandomFrequency();
    this.prepare();
  }

  prepare = () => {
    log.debug('Preparing Sentinel');
    log.debug('Resetting State');

    invoke('reset_state').then(() => {});

    log.debug('State Reset');
    log.debug('Unregistering Listeners');

    this.unregisterListeners();

    log.debug('Listeners Unregistered');
    log.debug('Registering Listeners');

    this.registerListeners().then(() => {});

    log.debug('Listeners Registered');
    log.debug('Gathering Environment Information');

    this.gatherEnvironmentInformation().then(() => {});

    log.debug('Environment Information Gathered');
    log.debug('Starting Monitoring Loop');

    this.startMonitoringLoop();

    log.debug('Monitoring Loop Started');
    log.debug('Loading Runtime Configuration');

    this.runtimeConfigurationStore.boot().then(() => {
      this.appStateStore.boot().then(() => {});
    });

    log.debug('Runtime Configuration Loaded');

    log.warn('Game list and Excluded Process List are not implemented');
    //TODO Implement these
    //this.loadGameList();
    //this.loadExcludedProcessList();

    log.debug('Sentinel Booted');
  };

  reset = () => {
    this.activeJobStore.$reset();
    this.runtimeConfigurationStore.$reset();
    this.timerStore.$reset();
    this.ongoingOperationsStore.$reset();
    this.appStateStore.$reset();
    this.alertStore.$reset();
    this.chatStore.$reset();
    this.gptStore.$reset();
    this.userStore.$reset();

    this.runtimeConfigurationStore
      .boot()
      .then(() => {
        this.appStateStore
          .boot()
          .then(() => {})
          .catch((error) => {
            log.error('failed to reboot app state store');
            log.error(error);
          });
      })
      .catch((error) => {
        log.error('failed to reboot runtime configuration store');
        log.error(error);
      });
  };

  getRandomFrequency = () => {
    return Math.random() * (6 - 5) + 5;
  };

  gatherEnvironmentInformation = async () => {
    this.runtimeConfigurationStore.platform = platform();
    this.runtimeConfigurationStore.version = await getVersion();
    this.runtimeConfigurationStore.currentMachine = await this.getSystemInformation();
  };

  reportMachineInformation = () => {
    if (!this.runtimeConfigurationStore.currentMachine) {
      return;
    }
    this.appStateStore.api
      .uploadSystemInformation(this.runtimeConfigurationStore.currentMachine)
      .catch((error) => {
        log.error('failed to upload system information');
        log.error(error);
      })
      .finally(() => {
        this.appStateStore.api
          .getLastMachine()
          .then((response) => {
            const lastMachine = response;
            if (!lastMachine) {
              if (!this.runtimeConfigurationStore.currentMachine) {
                return;
              }
              this.appStateStore.api.setMainComputer(this.runtimeConfigurationStore.currentMachine.system_uuid).catch((error) => {
                log.error('failed to set main computer');
                log.error(error);
              });
              return;
            }
            // @ts-ignore
            if (lastMachine.is_primary === 1 && this.runtimeConfigurationStore.currentMachine.system_uuid !== lastMachine.uuid) {
              this.runtimeConfigurationStore.primaryMachine = false;
            }
          })
          .catch((response) => {
            if (response.code === 404 && this.runtimeConfigurationStore.currentMachine) {
              this.appStateStore.api.setMainComputer(this.runtimeConfigurationStore.currentMachine.system_uuid).catch(() => {});
            }
          });
      });
  };

  getSystemInformation = async (): Promise<ComputerSpecs> => {
    const info: ComputerSpecs = await invoke('get_system_info');
    info.version = this.runtimeConfigurationStore.version;
    return info;
  };

  registerListeners = async () => {
    const mtiEventListener = await listen('mti_event', (event: { payload: MtiEvent }) => {
      this.handleMtiEvent(event.payload);
    });
    this.listeners.push(mtiEventListener);
  };

  unregisterListeners = () => {
    this.listeners.forEach((dropListener) => dropListener());
  };

  handleMtiEvent(payload: MtiEvent): void {
    log.debug(payload);
    if (payload.name === 'machine_locked' || payload.name === 'machine_unlocked') {
      this.handleScreenLockedEvent(payload);
    }
    if (payload.name === 'machine_sleep' || payload.name === 'machine_wake') {
      this.handleMachineSleepEvent(payload);
    }
    if (payload.name === 'close_app') {
      this.handleCloseRequestEvent(payload).catch((error: any) => {
        log.error('failed to handle close request');
        log.error(error);
      });
    }
    if (payload.name === 'dump_cache') {
      window.location.reload();
    }
  }

  handleUserIdleEvent = (event: any) => {
    if (!this.activeJobStore.activeJob || !this.activeJobStore.activeJob.saved) {
      return;
    }
    if (this.activeJobStore.additionalDetails.onBreak || this.activeJobStore.additionalDetails.beRightBack) {
      return;
    }

    const metaKey = event.payload.isIdle ? 'idle_start' : 'idle_end';
    const meta: Record<string, any> = {};
    meta[metaKey] = DateTime.now().toISO();

    const trackerEvent: TrackerEvent = {
      eventType: event.payload.isIdle ? 'user_idle_start' : 'user_idle_end',
      meta,
    };
    this.appStateStore.api
      .reportEvent(trackerEvent)
      .then(() => {})
      .catch(() => {
        log.error('failed to report user idle event');
      });
  };

  handleCloseRequestEvent = async (data: MtiEvent) => {
    const promises = [];
    promises.push(this.chatStore.disconnect());

    if (this.activeJobStore.activeJob && !this.activeJobStore.activeJob.saved) {
      const trackerEvent: TrackerEvent = {
        eventType: 'tracker_closed',
        meta: { data },
      };
      promises.push(this.appStateStore.api.reportEvent(trackerEvent));
    }

    Promise.all(promises)
      .catch((error) => {
        log.error('Failed to report close event - Closing App');
        log.error(error);
      })
      .finally(async () => {
        await exit(0);
      });
  };

  handleScreenLockedEvent = (event: MtiEvent) => {
    if (!this.activeJobStore.activeJob || this.activeJobStore.activeJob.saved) {
      return;
    }
    if (this.activeJobStore.additionalDetails.onBreak || this.activeJobStore.additionalDetails.beRightBack) {
      return;
    }
    // they have unlocked the machine, probably.
    if (event.data.locked === false || this.timerStore.machineLockedTooLong) {
      this.chatStore.broadcastOnlineNotice();
    } else {
      this.timerStore.clearMachineLockedTime();
      this.chatStore.broadcastIdleNotice();
    }
    const trackerEvent: TrackerEvent = {
      eventType: event.data.locked ? 'machine_locked' : 'machine_unlocked',
      meta: {
        event,
      },
    };

    this.appStateStore.api
      .reportEvent(trackerEvent)
      .then(() => {})
      .catch((error) => {
        log.error('Failed to report lock event');
        log.error(error);
      });
  };

  handleMachineSleepEvent = (event: MtiEvent) => {
    if (event.data.sleep) {
      this.chatStore.broadcastOfflineNotice();
    } else {
      this.chatStore.reinitializeChat();
    }

    if (!this.activeJobStore.activeJob || !this.activeJobStore.activeJob.saved) {
      return;
    }
    if (this.activeJobStore.additionalDetails.onBreak || this.activeJobStore.additionalDetails.beRightBack) {
      return;
    }

    const trackerEvent: TrackerEvent = {
      eventType: event.data.sleep ? 'machine_sleep' : 'machine_wake',
      meta: {
        event,
      },
    };

    this.appStateStore.api
      .reportEvent(trackerEvent)
      .then(() => {})
      .catch((error) => {
        log.error('Failed to report sleep event');
        log.error(error);
      });
  };

  processTick = () => {
    this.shiftStartingSoon();
    this.checkIfUserIsLate();
    this.shiftHasEnded();
    this.checkIfOverBreak();
    this.checkIfMidShift();
    this.runTrackerFeatures();
  };

  whisper = (abortEarly = false) => {
    this.activeJobStore
      .refresh()
      .then(() => {
        log.info('whisper sent');
      })
      .catch((error) => {
        if (error.code === 401 && !abortEarly) {
          this.appStateStore.api.refreshTokens().then(() => {
            this.whisper(true);
          });
        }
      });
  };

  runTrackerFeatures = () => {
    if (!this.activeJobStore.activeJob || !this.activeJobStore.activeJob.trackerEnabled || !this.activeJobStore.isClockedIn) {
      return;
    }

    this.checkIfInMeeting();
    this.checkIdleState();
    this.processUploadQueue();

    const currentTime = DateTime.now();

    if (this.timerStore.nextCaptureTime === null) {
      this.updateNextCaptureTime();

      return;
    }

    if (currentTime > this.timerStore.nextCaptureTime) {
      this.updateNextCaptureTime();
      this.callHome();
      this.captureScreens();
      this.captureProcesses();
      this.captureApplicationActivity();
      this.captureWebsites();
      this.captureInputData();
    }
  };

  captureInputData = () => {
    this.captureMouseData();
  };

  captureWebsites = () => {
    if (!this.activeJobStore.shouldTrackWebsites) return;

    invoke<Website[]>('get_browser_history')
      .then((websites) => {
        this.ongoingOperationsStore.uploadingWebsites = true;

        this.appStateStore.api
          .uploadWebsites({
            job_id: this.activeJobStore.activeJob?.jobId ?? -1,
            websites,
          })
          .then(() => {})
          .catch((error) => {
            log.error('failed to upload websites');
            log.error(error);
          });
      })
      .catch((error) => {
        log.error('failed to capture websites');
        log.error(error);
      })
      .finally(() => {
        this.ongoingOperationsStore.uploadingWebsites = false;
      });
  };

  dockBounce = () => {
    setTimeout(async () => {
      invoke('bounce_dock_icon').catch((error) => {
        log.error('Failed to bounce dock via');
        log.error(error);
      });
    }, 2000);
  };

  captureProcesses = (force = false) => {
    if (!this.activeJobStore.shouldTrackProcesses && !force) {
      return;
    }
    invoke<Process[]>('get_running_processes')
      .then((processes) => {
        const filteredProcesses = processes.filter((process: Process) => {
          if (this.isProcessGame(process)) {
            process.category = 'game';
          }
          return !this.isProcessExcluded(process);
        });
        this.uploadProcesses(filteredProcesses);
      })
      .catch((error) => {
        log.error('Failed to capture processes');
        log.error(error);
      });
  };

  uploadProcesses = (processes: Process[]) => {
    this.ongoingOperationsStore.uploadingProcesses = true;
    this.appStateStore.api
      .uploadProcesses({
        job_id: this.activeJobStore.activeJob?.jobId ?? -1,
        processes: processes,
      })
      .catch((error: unknown) => {
        log.error('Failed to upload processes');
        log.error(error);
      })
      .finally(() => {
        this.ongoingOperationsStore.uploadingProcesses = false;
      });
  };

  captureApplicationActivity = (force = false) => {
    if (!this.activeJobStore.shouldTrackApplications && !force) {
      return;
    }
    invoke<Application[]>('get_activity')
      .then((applications: Application[]) => {
        applications = this.calculateTotalApplicationUsage(applications);
        this.uploadApplicationActivity(applications);
      })
      .catch((error: unknown) => {
        log.error('Failed to capture application activity');
        log.error(error);
      });
  };

  uploadApplicationActivity = (applications: Application[]) => {
    this.ongoingOperationsStore.uploadingApplicationActivity = true;
    this.appStateStore.api
      .uploadApplications({
        job_id: this.activeJobStore.activeJob?.jobId ?? -1,
        applications: applications,
        icons: [],
      })
      .catch((error) => {
        log.error('Failed to upload application activity');
        log.error(error);
      })
      .finally(() => {
        this.ongoingOperationsStore.uploadingApplicationActivity = false;
      });
  };

  calculateTotalApplicationUsage = (applications: Application[]): Application[] => {
    return applications.map((app) => this.calculateTimePerWindow(app));
  };

  calculateTimePerWindow = (app: Application): Application => {
    app.windows = app.windows.map((window) => {
      const tempMilliseconds = this.calculateWindowDuration(window.captureTimes);
      window.seconds = tempMilliseconds / 1000;
      return window;
    });
    return app;
  };

  calculateWindowDuration = (timeSeries: CaptureTime[]): number => {
    let sum = Duration.fromMillis(0);
    for (const { start, end } of timeSeries) {
      const startDateTime = DateTime.fromMillis(start);
      const endDateTime = end === 0 ? DateTime.utc() : DateTime.fromMillis(end);
      const duration = endDateTime.diff(startDateTime);
      sum = sum.plus(duration);
    }
    return sum.valueOf();
  };

  isProcessExcluded = (process: Process) => {
    return this.appStateStore.excludedProcessList.some((p: string) => p.toLowerCase() === process.path.toLowerCase());
  };

  isProcessGame = (process: Process) => {
    return this.appStateStore.gameList.some((game: GameMedia) => {
      return (game?.executables ?? []).some((executable: Executable) => {
        return process.path.toLowerCase().includes(executable.name.toLowerCase());
      });
    });
  };

  captureScreens = () => {
    if (!this.activeJobStore.activeJob || !this.activeJobStore.activeJob.trackerEnabled || this.activeJobStore.additionalDetails.onBreak || this.activeJobStore.additionalDetails.beRightBack) {
      log.info('No screenshots needed.');
      return;
    }

    log.debug('Capturing screen data.');

    invoke('capture_screenshot', {
      trackScreenshots: this.activeJobStore.activeJob.trackScreenshots,
      screenshotsTaken: this.activeJobStore.additionalDetails.screenshotsTaken,
      osRelease: this.runtimeConfigurationStore.currentMachine?.os_release,
      OSSysArch: this.runtimeConfigurationStore.currentMachine?.os_platform,
      inMeeting: this.activeJobStore.additionalDetails.inMeeting,
    })
      .then(() => {
        log.debug('Screenshots captured');
      })
      .catch((error: unknown) => {
        log.error('Failed to capture screenshots');
        log.error(error);
      });
  };

  captureScreensAsync = async () => {
    if (!this.activeJobStore.activeJob) {
      return;
    }
    return await invoke('capture_screenshot', {
      trackScreenshots: this.activeJobStore.activeJob.trackScreenshots,
      screenshotsTaken: this.activeJobStore.additionalDetails.screenshotsTaken,
      osRelease: this.runtimeConfigurationStore.currentMachine?.os_release,
      OSSysArch: this.runtimeConfigurationStore.currentMachine?.os_platform,
      inMeeting: this.activeJobStore.additionalDetails.inMeeting,
    });
  };

  captureMouseData = () => {
    invoke<Mouse>('get_mouse_position')
      .then((data: Mouse) => {
        this.appStateStore.api
          .uploadInputData({
            mouse: data,
          })
          .then(() => {
            log.info('Input data processed');
          })
          .catch((error: unknown) => {
            log.error('Failed to send input data');
            log.error(error);
          });
      })
      .catch((error: unknown) => {
        log.error('Failed to invoke input data');
        log.error(error);
      })
      .finally(() => {});
  };

  updateNextCaptureTime = () => {
    this.timerStore.updateCaptureTime(this.frequency);
  };

  callHome = () => {
    this.appStateStore.api.callHome().then(() => {});
  };

  checkIdleState = () => {
    invoke<{ isIdle: boolean; idleTime: number; shouldReport: boolean }>('check_if_user_idle')
      .then((idle: { isIdle: boolean; idleTime: number; shouldReport: boolean }) => {
        if (idle.shouldReport) {
          this.handleUserIdleEvent({ payload: idle });
          if (idle.isIdle) {
            this.chatStore.broadcastIdleNotice();
          } else {
            this.chatStore.broadcastOnlineNotice();
          }
        }
      })
      .catch((error) => {
        log.error('Failed to check idle state');
        log.error(error);
      });
  };

  processUploadQueue = () => {
    log.debug('Processing upload queue');
    invoke<string>('get_capture_group')
      .then((data) => {
        log.debug('Got capture group');
        log.debug(data);
        this.serverlessUpload(JSON.parse(data)).then(() => {});
      })
      .catch((error: unknown) => {
        log.error('Failed to get capture group');
        log.error(error);
      });
  };

  takeScreenshotsAndUploadImmediately = () => {
    this.captureScreensAsync().then(() => {
      this.processUploadQueue();
    });
  };

  shiftHasEnded = () => {
    if (!this.activeJobStore.activeJob) {
      return;
    }
    const shiftEndTime = DateTime.fromSeconds(this.activeJobStore.activeJob.timeToUnix);
    const timeTillShiftEnds = shiftEndTime.diffNow(['minutes']);

    if (DateTime.now() > shiftEndTime && timeTillShiftEnds.minutes >= this.appStateStore.settings.shiftAfterEndReminderTime) {
      this.playAlarm().catch((error: unknown) => {
        log.error("Couldn't play alarm");
        log.error(error);
      });
      this.sendDynamicNotification('Shift has ended', 'Your shift has ended. Please remember to clock out and have a GREAT day!').catch((error: unknown) => {
        log.error('Failed to send notification');
        log.error(error);
      });
    }
  };

  checkIfMidShift = () => {
    if (!this.activeJobStore.activeJob || this.activeJobStore.activeJob.isBreak || this.timerStore.playedHalfwayThroughShiftReminder) {
      return;
    }
    if (![this.YET_TO_START, this.LATE].includes(this.activeJobStore.activeJob.decoratedStatus)) {
      return;
    }
    const shiftEndTime = DateTime.fromSeconds(this.activeJobStore.activeJob.timeToUnix);
    const shiftStartTime = DateTime.fromSeconds(this.activeJobStore.activeJob.timeFromUnix);
    const shiftDuration = shiftEndTime.diff(shiftStartTime, ['minutes']).minutes;

    const now = DateTime.now();
    const halfwayMark = shiftStartTime.plus({ minutes: shiftDuration / 2 });

    if (now <= halfwayMark) {
      this.sendDynamicNotification('Halfway There!', 'You are halfway through your shift. Please remember to clock back in.').catch((error: unknown) => {
        log.error('Failed to send notification');
        log.error(error);
      });
      this.timerStore.playedHalfwayThroughShiftReminder = true;
    }
  };

  updateTimers = () => {
    this.timerStore.localTime = DateTime.now();
    // check if there is a client timezone available in the active job store
    if (this.activeJobStore.activeJob && this.activeJobStore.activeJob.clientTimezone) {
      // @ts-ignore
      this.timerStore.clientTime = DateTime.now().setZone(this.activeJobStore.activeJob.clientTimezone);
    }

    this.timerStore.utcTime = DateTime.utc();
    //@ts-ignore
    this.timerStore.manilaTime = DateTime.local().setZone('Asia/Manila');
  };

  startMonitoringLoop = () => {
    this.jobs.forEach((job) => {
      job.stop();
    });
    this.jobs = [];

    // Schedule Timers (every second)
    this.jobs.push(
      new CronJob(
        '* * * * * *',
        () => {
          this.updateTimers();
          this.refreshTokenExpired();
        },
        null,
        true
      )
    );

    // Schedule regular process tick
    this.jobs.push(
      new CronJob(
        '0 * * * * *',
        () => {
          this.processTick();
        },
        null,
        true
      )
    );

    // Schedule a whisper (10 minutes)
    this.jobs.push(
      new CronJob(
        '0 */10 * * * *',
        () => {
          this.whisper();
        },
        null,
        true
      )
    );

    // sync NTP time from Google
    this.jobs.push(
      new CronJob(
        '0 0/10 * * * *',
        () => {
          this.syncUtcTime();
        },
        null,
        true
      )
    );
    this.checkForApplicationUpdates();

    // get announcements & check for updates every hour
    this.jobs.push(
      new CronJob(
        '0 0 * * * *',
        () => {
          this.appStateStore.getAnnouncements();
          this.checkForApplicationUpdates();
        },
        null,
        true
      )
    );
  };

  refreshTokenExpired = () => {
    if (this.appStateStore.accessTokenExpired && !this.appStateStore.refreshingAccessToken) {
      this.appStateStore.api.refreshTokens().then(() => {
        log.info('Failed to refresh access token');
      });
    }
  };

  syncUtcTime = () => {
    invoke<ClockInformation>('get_utc_time')
      .then((result: ClockInformation) => {
        this.timerStore.utcTime = DateTime.fromSeconds(result.utc);
        log.info(`Synced UTC time from time.google.com`);

        //Do this for our own sanity
        const minutes = 2;
        const seconds = 60;
        const microseconds = 1000000;

        if (result.offset > minutes * seconds * microseconds) {
          log.info('Clock drift detected');
          this.alertStore.addClockDriftAlert();
        } else {
          this.alertStore.removeAlert(this.alertStore.alerts.findIndex((alert) => alert.includes('Clock drift detected')));
        }
      })
      .catch((error) => {
        log.error('Failed to sync time');
        log.error(error);
      });
  };

  checkForApplicationUpdates = () => {
    check()
      .then((update) => {
        if (!update) {
          log.info('No updates available');
          return;
        }

        log.info('Update available');

        update
          .download()
          .then(() => {
            this.update = update;
            // mark update as ready to install until they click the update button
            this.runtimeConfigurationStore.newerVersionAvailable = true;
            log.info('Downloaded update');
          })
          .catch((error) => {
            this.runtimeConfigurationStore.newerVersionAvailable = false;
            log.error('Failed to download update');
            log.error(error);
          });
      })
      .catch((error) => {
        this.runtimeConfigurationStore.newerVersionAvailable = false;
        log.error('Failed to check for updates');
        log.error(error);
      });
  };

  shiftStartingSoon = () => {
    if (!this.activeJobStore.activeJob || this.activeJobStore.activeJob.decoratedStatus !== this.YET_TO_START) {
      return;
    }
    const shiftStartTime = DateTime.fromSeconds(this.activeJobStore.activeJob.timeFromUnix);
    const timeTillShiftStarts = shiftStartTime.diffNow(['minutes']);

    if (timeTillShiftStarts.minutes <= 1) {
      return;
    }

    if (timeTillShiftStarts.minutes <= this.appStateStore.settings.shiftReminderTime) {
      this.sendShiftStartingSoonNotification(Math.round(timeTillShiftStarts.minutes)).catch((error: unknown) => {
        log.error('Failed to send shift starting soon notification');
        log.error(error);
      });
    }
  };

  checkIfUserIsLate = () => {
    if (!this.activeJobStore.activeJob || ![this.YET_TO_START, this.LATE].includes(this.activeJobStore.activeJob.decoratedStatus) || this.activeJobStore.additionalDetails.clockingIn) {
      return;
    }
    if (this.activeJobStore.activeJob.timeFromUnix - DateTime.now().toSeconds() < 0) {
      this.chatStore.composerFocusStealOverride = true;
      this.activeJobStore.additionalDetails.clockingIn = true;
      this.playAlarm().catch((error: unknown) => {
        log.error('Failed to play alarm');
        log.error(error);
      });
      this.sendDynamicNotification('Clock In Reminder', 'Please remember to clock in.').catch((error: unknown) => {
        log.error('Failed to send clock in reminder');
        log.error(error);
      });
    }
  };

  checkIfOverBreak = () => {
    if (!this.activeJobStore.activeJob || !this.timerStore.overBreakSnoozeReturnTime || !this.timerStore.breakStartedAt || !this.activeJobStore.activeJob.isBreak) {
      return;
    }
    const now = DateTime.now();
    const breakTimeInMinutes = this.activeJobStore.activeJob.breakDuration;
    const currentBreakDuration = now.diff(this.timerStore.breakStartedAt, ['minutes']).minutes;

    if (currentBreakDuration > breakTimeInMinutes) {
      this.playAlarm().catch((error) => {
        log.error('Failed to play alarm');
        log.error(error);
      });
      this.timerStore.playedBreakReminder = true;
      this.sendDynamicNotification('Break has ended', 'Please remember to clock back in.').catch((error: unknown) => {
        log.error('Failed to send notification');
        log.error(error);
      });
    }
  };

  playAlarm = async () => {
    if (!this.activeJobStore.activeJob) {
      return;
    }

    const focusPromise = invoke('focus');
    const playWarningSoundPromise = this.audioPlayer.playWarningSound();

    await Promise.all([focusPromise, playWarningSoundPromise]);
  };

  sendShiftStartingSoonNotification = async (minutesUntilStart: number) => {
    if (!this.activeJobStore.activeJob || this.timerStore.playedStartingShiftReminder) {
      return;
    }

    const body = `You're scheduled to begin your shift with ${this.activeJobStore.activeJob.clientName} in about ${minutesUntilStart} minutes.`;
    this.sendDynamicNotification('Shift starting soon', body).catch((error: unknown) => {
      log.error('Failed to send notification');
      log.error(error);
    });
    this.audioPlayer.playWarningSound().catch((error: unknown) => {
      log.error('Failed to play alarm');
      log.error(error);
    });
    this.timerStore.playedStartingShiftReminder = true;
  };

  sendDynamicNotification = async (title: string, body: string) => {
    // Do you have permission to send a notification?
    let permissionGranted = await isPermissionGranted();

    // If not we need to request it
    if (!permissionGranted) {
      const permission = await requestPermission();
      permissionGranted = permission === 'granted';
    }

    const notificationParams = {
      title,
      body,
      sound: this.runtimeConfigurationStore.platform === 'darwin' ? 'Blow' : 'Default',
    };

    // Once permission has been granted we can send the notification
    if (permissionGranted) {
      sendNotification(notificationParams);
      log.debug('Notification Sent');
    }
  };

  checkIfInMeeting = () => {
    if ((this.timerStore.nextMeetingCheckAt && DateTime.now() < this.timerStore.nextMeetingCheckAt) || this.ongoingOperationsStore.performingMeetingCheck) {
      return;
    }
    this.ongoingOperationsStore.performingMeetingCheck = true;
    // get existing meeting flag
    invoke('check_in_meeting')
      .then((response) => {
        this.appStateStore.api.reportMeetingStatus(response === 1).catch((error: unknown) => {});
        if (response === 1) {
          this.activeJobStore.additionalDetails.inMeeting = true;
        }
      })
      .catch(() => {})
      .finally(() => {
        this.ongoingOperationsStore.performingMeetingCheck = false;
        this.timerStore.nextMeetingCheckAt = DateTime.now().plus({
          minutes: 1,
        });
      });
  };

  deleteImagesFromDisk = (captureGroup: CaptureGroup) => {
    captureGroup.screenshots.forEach((display: DisplayDefinition) => {
      remove(display.path)
        .then(() => {})
        .catch((error) => {
          log.error("Couldn't delete image from disk");
          log.error(error);
        })
        .finally(() => {});
    });
  };

  uploadToS3 = async (screens: GenericObject, captureGroup: CaptureGroup): Promise<void> => {
    for (const [index, screen] of screens.entries()) {
      let path = captureGroup.screenshots[index].path;
      path = `mti/` + path.split('mti/')[1];
      try {
        // const stream = await invoke<Uint8Array>('read_screenshot', { path });
        // THIS DOES NOT WORK? NO IDEA.
        const stream = await readFile(path, { baseDir: BaseDirectory.Temp });
        if (!stream || stream.length === 0) {
          log.error('Empty buffer for screen');
          this.failedUploads.push(screen.mytimein_id);
          continue;
        }
        if (!screen.uploadKey) {
          log.error('No upload key');
          continue;
        }
        await this.appStateStore.api.uploadToS3(screen.uploadKey, stream, screen.compliance_mode);
      } catch (error) {
        log.error('Failed to upload to S3');
        log.error(error);
        this.failedUploads.push(screen.mytimein_id);
      }
    }
  };

  serverlessUpload = async (captureGroup?: CaptureGroup): Promise<void> => {
    if (!captureGroup || !captureGroup.screenshots || this.ongoingOperationsStore.uploadingScreenshots) {
      return Promise.resolve();
    }

    log.debug('Upload cycle');
    this.ongoingOperationsStore.uploadingScreenshots = true;
    try {
      const response = await this.appStateStore.api.startScreenshotCycle(captureGroup);
      const data = response;
      if (!response || !data) {
        this.ongoingOperationsStore.uploadingScreenshots = false;
        return;
      }

      if (!this.activeJobStore.shouldTakeScreenshots) {
        this.ongoingOperationsStore.uploadingScreenshots = false;
        this.deleteImagesFromDisk(captureGroup);
        return;
      }

      await this.uploadToS3(data, captureGroup);

      if (this.failedUploads.length > 0) {
        log.error('Failures occurred during upload cycle');
        this.appStateStore.api
          .abandonScreenshotCycle({
            abandoned: this.failedUploads.map((id) => ({ id })),
          })
          .then(() => {
            this.ongoingOperationsStore.uploadingScreenshots = false;
            this.deleteImagesFromDisk(captureGroup);
          })
          .catch(() => {});
      }
      // remove the failures form the display group
      captureGroup.screenshots = captureGroup.screenshots.filter((screen: DisplayDefinition) => {
        return !this.failedUploads.includes(screen.screen_id);
      });

      /**
       * Wait 30 seconds to tell MTI we completed the cycle.
       */
      setTimeout(() => {
        const screenshots_that_successfully_uploaded = data.filter((screen) => {
          return !this.failedUploads.includes(screen.mytimein_id ?? -1);
        });
        this.appStateStore.api
          .completeScreenshotCycle({
            screen_data: screenshots_that_successfully_uploaded.map((screen) => {
              return {
                id: screen.mytimein_id,
              };
            }),
          })
          .then(() => {
            this.deleteImagesFromDisk(captureGroup);
          })
          .catch(() => {})
          .finally(() => {
            this.ongoingOperationsStore.uploadingScreenshots = false;
          });
      }, 1000 * 30);
    } catch (error) {
      log.error('Failed to start screenshot cycle');
      log.error(error);
      this.ongoingOperationsStore.uploadingScreenshots = false;
    }
  };

  loadGameList = () => {
    // TODO: Load games, is cache supported in this edgeview?
  };

  loadExcludedProcessList = () => {
    // TODO: Load excluded, is cache supported in this edgeview?
  };
}

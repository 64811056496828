import emojiData from 'emoji-datasource-google';
import emojiUiGroups from './emoji_ui_groups';

export class EmojiManager {
  private static instance: EmojiManager;
  private emojiUiGroups: Emoji[];
  private emojiMap: Map<string, Emoji>;
  private defaultEmojiMap: Map<string, Emoji>;
  private groupedEmojis: Record<SimpleCategory, Emoji[]>;
  private skinTone: SkinVar | undefined;
  private emojiCategoryMap: Record<string, string>;

  private constructor() {
    this.emojiMap = new Map();
    this.defaultEmojiMap = new Map();
    this.groupedEmojis = {
      People: [],
      Food: [],
      Nature: [],
      Activities: [],
      Travel: [],
      Objects: [],
      Symbols: [],
      Flags: [],
    };
    this.emojiUiGroups = emojiUiGroups;
    this.skinTone = undefined;
    this.emojiCategoryMap = {
      Activities: 'Activities',
      'Animals & Nature': 'Nature',
      'Food & Drink': 'Food',
      'People & Body': 'People',
      'Smileys & Emotion': 'People',
      Component: 'Symbols',
      Symbols: 'Symbols',
      Flags: 'Flags',
      'Travel & Places': 'Travel',
      Objects: 'Objects',
    };
  }

  public static getInstance(): EmojiManager {
    if (!EmojiManager.instance) {
      EmojiManager.instance = new EmojiManager();
      EmojiManager.instance.loadEmojis();
    }
    return EmojiManager.instance;
  }

  public setSkinTone(skinTone: SkinVar | undefined): void {
    this.skinTone = skinTone;
  }

  public getBackgroundPosition = (emojiString: string, size: 16 | 20 | 32 | 64 = 32, skipSkinTone: boolean = false): Record<string, any> => {
    const emoji: Emoji | undefined = this.emojiMap.get(emojiString);
    if (!emoji) {
      return { 'background-position': `0px 0px` };
    }

    const emojiSize = size + 2;
    const getPosition = (x: number, y: number) => ({
      'background-position': `${x * emojiSize * -1}px ${y * emojiSize * -1}px`,
    });

    // Check if the skin variation exists in the object
    if (!skipSkinTone) {
      if (emoji.skin_variations && this.skinTone && emoji.skin_variations[this.skinTone]) {
        const variation = emoji.skin_variations[this.skinTone];
        return getPosition(variation.sheet_x, variation.sheet_y);
      }
    }

    // Fallback to the default position if no valid variation is found
    return getPosition(emoji.sheet_x, emoji.sheet_y);
  };

  public loadEmojis(): {
    emojiMap: Map<string, Emoji>;
    groupedEmojis: Record<SimpleCategory, Emoji[]>;
  } {
    const emojis: Emoji[] = emojiData as Emoji[];

    emojis.forEach((emoji) => {
      this.emojiMap.set(emoji.unified, emoji);
      this.defaultEmojiMap.set(emoji.unified, emoji);

      if (emoji.non_qualified) {
        this.emojiMap.set(emoji.non_qualified, emoji);
      }

      /**
       * Preload the variants into the map directly
       */
      if (emoji.skin_variations) {
        Object.keys(emoji.skin_variations).forEach((skinToneKey: string) => {
          if (!emoji.skin_variations) {
            return;
          }

          const variation = emoji.skin_variations[skinToneKey as SkinVar];

          if (variation) {
            // Merge the emoji and variation, overwriting properties like unified, sheet_x, and sheet_y
            const unified = variation.unified;
            const sheet_x = variation.sheet_x;
            const sheet_y = variation.sheet_y;
            const emojiWithSkin = { ...emoji, unified, sheet_x, sheet_y };

            // Update the emoji map with the new skin variation
            this.emojiMap.set(unified, emojiWithSkin);
          }
        });
      }
    });
    this.groupedEmojis = this.groupEmojisByMainGroup(emojis);

    return { emojiMap: this.emojiMap, groupedEmojis: this.groupedEmojis };
  }

  public getEmojiMap(): Map<string, Emoji> {
    return this.emojiMap;
  }

  /**
   * This does not contain variants for skin tones
   */
  public getDefaultEmojiMap(): Map<string, Emoji> {
    return this.defaultEmojiMap;
  }

  public getGroupedEmojis(): Record<SimpleCategory, Emoji[]> {
    return this.groupedEmojis;
  }

  public getCategoryMap(): Record<string, string> {
    return this.emojiCategoryMap;
  }

  public getUiGroups(): Emoji[] {
    return this.emojiUiGroups;
  }

  public static getValidSkinTones(): (string | undefined)[] {
    return ['1F3FB', '1F3FC', '1F3FD', '1F3FE', '1F3FF', undefined];
  }

  private groupEmojisByMainGroup(emojis: Emoji[]): Record<string, Emoji[]> {
    // First, group by original category
    const initialGroupedEmojis = emojis.reduce(
      (acc, emoji) => {
        const mainGroup = emoji.category;
        if (!acc[mainGroup]) {
          acc[mainGroup] = [];
        }
        acc[mainGroup].push(emoji);
        return acc;
      },
      {} as Record<string, Emoji[]>
    );
    // Now map and combine groups
    const finalGroupedEmojis: Record<string, Emoji[]> = {};

    // Special handling for 'People' category
    if (initialGroupedEmojis['Smileys & Emotion'] && initialGroupedEmojis['People & Body']) {
      finalGroupedEmojis['People'] = [...initialGroupedEmojis['Smileys & Emotion'], ...initialGroupedEmojis['People & Body']];
      delete initialGroupedEmojis['Smileys & Emotion'];
      delete initialGroupedEmojis['People & Body'];
    }

    // Combine the rest of the categories
    for (const category in initialGroupedEmojis) {
      const mappedCategory = this.emojiCategoryMap[category] || 'Uncategorized';
      if (!finalGroupedEmojis[mappedCategory]) {
        finalGroupedEmojis[mappedCategory] = [];
      }
      finalGroupedEmojis[mappedCategory].push(...initialGroupedEmojis[category]);
    }

    return finalGroupedEmojis;
  }

  /**
   * Converts an emoji character to its Unicode code point representation.
   * @param {string} emoji - The emoji character to convert.
   * @returns {string} The Unicode code point representation of the emoji.
   */
  public emojiToUnicode = (emoji: string): string => {
    return [...emoji] // Spread the emoji string into individual characters
      .map((char) => char.codePointAt(0)?.toString(16).toUpperCase().padStart(4, '0') || '') // Convert each character to its Unicode code point, format it as a hexadecimal string, and pad it to ensure it has at least 4 characters
      .filter((code) => code.length > 0) // Filter out any empty strings
      .join('-'); // Join the code points with a hyphen
  };

  /**
   * Converts a Unicode string to an emoji string.
   * @param {string} unicode - The Unicode string to convert.
   * @returns {string} The emoji string.
   */
  public unicodeToEmoji = (unicode: string): string => {
    // If the format starts with 'U+' and has spaces, convert it to the new format
    if (unicode.startsWith('U+')) {
      return unicode.replace(/U\+/g, '').replace(/\s/g, '-').trim();
    }
    // If the format is already in the new format (separated by dashes), convert it directly
    return this.emojiToUnicode(unicode);
  };

  /**
   * Normalizes the emoji input by converting it to its Unicode representation
   * and then mapping it to the unified emoji if available.
   * @param {string} input - The emoji input to normalize.
   * @returns {string} The normalized emoji.
   */
  public normalizeEmojiInput = (input: string): string => {
    const formattedUnicode = this.unicodeToEmoji(input);
    return this.emojiMap.get(formattedUnicode)?.unified || formattedUnicode;
  };
}

const birdImages = [
  '001-crow.png',
  '002-seagull.png',
  '003-humming bird.png',
  '004-woodpecker.png',
  '005-owl.png',
  '006-chicken.png',
  '007-rooster.png',
  '008-macaw.png',
  '009-parrot.png',
  '010-peacock.png',
  '011-sparrow.png',
  '012-penguin.png',
  '013-Canary.png',
  '014-heron.png',
  '015-flamingo.png',
  '016-nest.png',
  '017-eagle.png',
  '018-cockatoo.png',
  '019-pheasant.png',
  '020-hawk.png',
  '021-ostrich.png',
  '022-american robin.png',
  '023-stork.png',
  '024-chick.png',
  '025-swan.png',
  '026-pelican.png',
  '027-feather.png',
  '028-birds house.png',
  '029-cage.png',
  '030-wagtail.png',
  '031-Canary.png',
  '032-kiwi.png',
  '033-northern cardinal.png',
  '034-vulture.png',
  '035-hoopoe.png',
  '036-Puffin.png',
  '037-bullfinch.png',
  '038-nightingale.png',
  '039-bird.png',
  '040-bird.png',
  '041-turkey.png',
  '042-barn swallow.png',
  '043-bird.png',
  '044-toucan.png',
  '045-bird.png',
  '046-kingfisher.png',
  '047-pigeon.png',
  '048-mallard duck.png',
  '049-duck.png',
  '050-swallow.png',
];
export const getRandomBird = () => {
  return 'https://s3.ap-southeast-1.amazonaws.com/public.mytimein.com/assets/icons/' + birdImages[Math.floor(Math.random() * birdImages.length)];
};

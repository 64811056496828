import WarningSound from './assets/sounds/warn2.mp3';

export class AudioPlayer {
  async playWarningSound() {
    const audio = new Audio(WarningSound);
    try {
      await audio.play();
    } catch (error) {
      console.error('Could not play warning sound', error);
    }
  }
}

export default AudioPlayer;

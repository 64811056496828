<script setup lang="ts">
  import { save } from '@tauri-apps/plugin-dialog';
  import { ref, toRefs } from 'vue';
  import { useToast } from 'primevue/usetoast';
  import { invoke } from '@tauri-apps/api/core';
  import { listen, UnlistenFn } from '@tauri-apps/api/event';
  import ChatImageFile from '@components/Chat/ChatImageFile.vue';
  import ChatVideoFile from '@components/Chat/ChatVideoFile.vue';
  import { ArrowDownIcon } from '@heroicons/vue/20/solid';
  // @ts-ignore
  import LazyComponent from 'v-lazy-component';
  import { calculateTooltipPosition } from '@utils/tooltip_calculator';
  import { useChatStore } from '@src/store/chat';
  const chatStore = useChatStore();

  const props = withDefaults(
    defineProps<{
      src: string | undefined;
      file: { id: string; name: string; type: string; url: string };
      contain?: boolean;
      preview?: boolean;
      videoStyles?: string;
      imageStyles?: string;
    }>(),
    {
      contain: false,
      preview: true,
      videoStyles: '',
      imageStyles: '',
    }
  );

  const emits = defineEmits(['image-click', 'video-click']);

  const progressBar = ref<SVGPathElement>();
  const progressText = ref<SVGTextElement>();
  const listeners = ref<UnlistenFn[]>([]);
  const { file } = toRefs(props);
  const toast = useToast();
  const progress = ref(0);
  const totalBytes = ref(0);
  const downloading = ref(false);
  const savePath = ref<string | null>(null);
  const hideDownload = ref(false);
  const handleImageClick = () => {
    emits('image-click');
  };
  const handleVideoClick = (payload: { src: string }) => {
    emits('video-click', payload);
  };

  const download = async () => {
    await registerListeners();
    savePath.value = await save({
      defaultPath: file.value.name,
      filters: [
        {
          name: file.value.name,
          extensions: [file.value.name.split('.').pop() || ''],
        },
      ],
    });

    if (!savePath.value) {
      listeners.value.forEach((unlisten) => unlisten());
      return;
    }

    downloading.value = true;
    try {
      await invoke('download_file', { url: props.src, filePath: savePath.value });
    } catch (error) {
      toast.add({
        severity: 'error',
        summary: 'Download Failed',
        detail: 'An error occurred while downloading the file.',
        life: 3000,
      });
      downloading.value = false;
      totalBytes.value = 0;
      progress.value = 0;
    }
    listeners.value.forEach((unlisten) => unlisten());
  };

  const handleMtiEvent = ({ payload }: { payload: MtiEvent }) => {
    if (payload.name === 'download_progress') {
      handleDownloadProgress(payload.data as { downloaded: number; totalSize: number });
    }
    if (payload.name === 'download_complete') {
      handleDownloadComplete(payload.data as { file_path: string });
    }
  };

  const handleDownloadProgress = (data: { downloaded: number; totalSize: number }) => {
    progress.value = (data.downloaded / data.totalSize) * 100;
    totalBytes.value = data.totalSize;
    updateProgress(data.downloaded, data.totalSize);
  };

  const handleDownloadComplete = (data: { file_path: string }) => {
    //here because the listener will pick up for any file on the page
    if (data.file_path !== savePath.value) {
      return;
    }
    setTimeout(() => {
      downloading.value = false;
      totalBytes.value = 0;
      progress.value = 0;
    }, 1000);
    toast.add({
      severity: 'info',
      summary: 'Download Complete',
      detail: {
        fileName: file.value.name,
        outputPath: savePath.value,
      },
      group: 'tr',
      life: 3000,
    });
    savePath.value = null;
  };

  const registerListeners = async () => {
    if (listeners.value) {
      listeners.value.forEach((unlisten) => unlisten());
      listeners.value = [];
    }

    let mtiEventListener = await listen('mti_event', handleMtiEvent);
    listeners.value.push(mtiEventListener);
  };

  const updateProgress = (progress: number, totalBytes: number) => {
    let currentPercent = (progress / totalBytes) * 100;
    if (progressBar.value) {
      progressBar.value.style.strokeDasharray = `${currentPercent} 100`;
    }
    if (progressText.value) {
      progressText.value.textContent = `${currentPercent.toFixed(0)}%`;
    }
  };

  const showMessageOptionTooltip = (event: MouseEvent) => {
    chatStore.messageOptionTooltipMessage = 'Download';
    chatStore.showMessageOptionTooltip = true;

    requestAnimationFrame(() => {
      let success = calculateTooltipPosition(event, 'chat-layer2', 'message-options-tooltip', 'top', 10, 10);
      if (!success) {
        chatStore.messageOptionTooltipMessage = null;
        chatStore.showMessageOptionTooltip = false;
      }
    });
  };

  const hideMessageOptionTooltip = () => {
    chatStore.showMessageOptionTooltip = false;
  };
</script>

<template>
  <div class="relative group w-full" data-testid="chat-media-file-container" :style="[imageStyles ? imageStyles : videoStyles]">
    <lazy-component v-if="src" wrapper-tag="div" :threshold="0.5" style="min-height: 100px">
      <ChatImageFile v-if="file.type.includes('image')" :file="file" :src="src" :contain="contain" :style="imageStyles" @download="download" @click="handleImageClick" @hide-download="hideDownload = true" />
      <ChatVideoFile v-if="file.type.includes('video')" :preview="preview" :file="file" :src="src" :contain="contain" :custom-style="videoStyles" @click="handleVideoClick" @hide-download="hideDownload = true" />
    </lazy-component>

    <div v-if="downloading" class="absolute top-2 left-2">
      <svg class="size-10" viewBox="0 0 36 36">
        <!-- Inner circle with black background and 90% opacity -->
        <circle cx="18" cy="18" r="15.9155" class="fill-black/40" />

        <!-- Background circle path -->
        <path class="stroke-surface-0" stroke-width="3.8" fill="none" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />

        <!-- Progress bar path -->
        <path ref="progressBar" class="stroke-primary-400" stroke-width="3.8" fill="none" stroke-dasharray="0, 100" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />

        <!-- Progress text -->
        <text ref="progressText" x="17.8" y="21.5" class="text-2xs fill-surface-0" text-anchor="middle">0%</text>
      </svg>
    </div>

    <div v-if="!hideDownload" class="absolute top-2 right-2 hidden group-hover:block" @contextmenu.stop.prevent>
      <div
        class="flex h-full w-8 items-center justify-center text-surface-600 first:rounded-tl-md first:rounded-bl-md last:rounded-tr-md last:rounded-br-md hover:bg-surface-200 dark:text-surface-400 dark:bg-surface-800 hover:active:text-surface-900 dark:hover:bg-surface-700 dark:hover:active:text-surface-200"
      >
        <button
          class="w-10 rounded-md border p-1 shadow bg-surface-0 border-surface-50 dark:border-surface-950 dark:bg-surface-800 dark:text-surface-300 dark:hover:text-surface-200 dark:hover:bg-surface-700"
          @mouseenter="showMessageOptionTooltip"
          @mouseleave="hideMessageOptionTooltip"
          @click="download"
        >
          <ArrowDownIcon class=""></ArrowDownIcon>
        </button>
      </div>
    </div>
  </div>
</template>

export const calculateTooltipPosition = (event: MouseEvent, outerLayerId: string, layerInnerId: string, placement: 'left' | 'right' | 'top' | 'mouse' = 'right', paddingX: number = 0, paddingY: number = 20): boolean => {
  const clickedElement = event.target as HTMLElement;
  const layer = document.getElementById(outerLayerId) as HTMLElement;
  const tooltip = document.getElementById(layerInnerId) as HTMLElement;

  if (!layer || !tooltip) {
    return false;
  }

  const viewPortWidth = window.innerWidth;
  const viewPortHeight = window.innerHeight;

  const { width: toolTipWidth, height: toolTipHeight } = tooltip.getBoundingClientRect();
  const { x: clickedElementX, y: clickedElementY, top: clickedElementTop, left: clickedElementLeft, right: clickedElementRight, height: clickedElementHeight } = clickedElement.getBoundingClientRect();
  const clickedElementWidth = clickedElementRight - clickedElementLeft;
  if (placement === 'mouse') {
    //check to see if the bottom of the screen
    if (event.clientY + toolTipHeight > viewPortHeight) {
      layer.style.top = `${viewPortHeight - toolTipHeight - paddingY}px`;
    } else {
      layer.style.top = `${event.clientY}px`;
    }
    layer.style.left = `${event.clientX}px`;
    return true;
  }

  if (placement === 'top') {
    layer.style.left = `${clickedElementX + clickedElementWidth / 2 - toolTipWidth / 2}px`;
    layer.style.top = `${clickedElementY - toolTipHeight - paddingY}px`;
    return true;
  }

  if (placement === 'right') {
    // Check if the tooltip overflows the right edge of the viewport
    if (clickedElementX + toolTipWidth + paddingX > viewPortWidth) {
      // Position the tooltip 10px from the right edge of the viewport
      layer.style.left = `${viewPortWidth - toolTipWidth - paddingX}px`;
    } else {
      // Position the tooltip to the right of the clicked element with padding
      const toolTipDistanceFromLeft = clickedElementX + clickedElementWidth + paddingX;
      layer.style.left = `${toolTipDistanceFromLeft}px`;
    }
  }
  if (placement === 'left') {
    // If the tooltip overflowed the left edge of the viewport, position it with padding from the left edge
    if (clickedElementX - toolTipWidth - paddingX < 0) {
      layer.style.left = `${paddingX}px`;
    } else {
      // Otherwise, position the tooltip to the left of the clicked element with padding
      const toolTipDistanceFromRight = clickedElementLeft - toolTipWidth - paddingX;
      layer.style.left = `${toolTipDistanceFromRight}px`;
    }
  }

  if (clickedElementY + toolTipHeight > viewPortHeight) {
    const tooltipDistanceFromBottom = viewPortHeight - toolTipHeight - paddingY; //padding from bottom
    layer.style.top = `${tooltipDistanceFromBottom}px`;
  } else {
    layer.style.top = `${clickedElementTop}px`;
  }

  // if (placement === 'bottom') {
  //   if (clickedElementX - toolTipWidth - paddingX < 0) {
  //     layer.style.left = `${paddingX}px`;
  //   } else {
  //     // Otherwise, position the tooltip to the left of the clicked element with padding
  //     const toolTipDistanceFromRight = clickedElementLeft - toolTipWidth - paddingX;
  //     layer.style.left = `${toolTipDistanceFromRight}px`;
  //   }
  //
  //   console.log(clickedElementY);
  //   console.log(clickedElementHeight);
  //   console.log(event.target.getBoundingClientRect());
  //   // Check if the tooltip overflows the bottom edge of the viewport
  //   if (clickedElementTop + clickedElementHeight + toolTipHeight + paddingY > viewPortHeight) {
  //     // Position the tooltip 10px from the bottom edge of the viewport
  //     layer.style.top = `${viewPortHeight - toolTipHeight - paddingY}px`;
  //   } else {
  //     // Position the tooltip below the clicked element with padding
  //     const toolTipDistanceFromTop = clickedElementTop + clickedElementHeight + paddingY;
  //     layer.style.top = `${toolTipDistanceFromTop}px`;
  //   }
  // }

  return true;
};

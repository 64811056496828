import { init, mdToHtml } from 'md4w';
import linkifyHtml from 'linkify-html';
import hljs from 'highlight.js';
import emojiRegexFn from 'emoji-regex';

// import all the highlight js languages
import 'highlight.js/lib/languages/javascript';
import 'highlight.js/lib/languages/typescript';
import 'highlight.js/lib/languages/python';
import 'highlight.js/lib/languages/java';
import 'highlight.js/lib/languages/ruby';
import 'highlight.js/lib/languages/php';
import 'highlight.js/lib/languages/go';
import 'highlight.js/lib/languages/csharp';
import 'highlight.js/lib/languages/swift';
import 'highlight.js/lib/languages/kotlin';
import 'highlight.js/lib/languages/scala';
import 'highlight.js/lib/languages/rust';
import wasmUrl from '../../node_modules/md4w/js/md4w-fast.wasm?url';
import { EmojiManager } from './emoji_manager';
import { Logger } from '@utils/logger';
import { cyrb53 } from '@utils/hash';

const log = new Logger('MARKDOWN_SERVICE');
const emojiRegex = emojiRegexFn();

export class MarkdownService {
  options: { defaultProtocol: string };
  cache: Map<
    string,
    {
      expires: number;
      html: string;
    }
  >;

  constructor() {
    this.options = { defaultProtocol: 'https' };
    this.cache = new Map();
    this.initialize()
      .then(() => {
        log.debug('ready');
      })
      .catch((e) => {
        log.error('failed to initialize', e);
      });
  }

  async initialize() {
    await init(wasmUrl);
  }

  /**
   * Returns the previously cached result if it exists and has not expired.
   *
   * @param id
   */
  getCachedResult(id: string) {
    const cached = this.cache.get(id);
    if (cached && cached.expires > Date.now()) {
      return cached.html;
    }
    return null;
  }

  /**
   * Extracts the proper language and adds syntax highlighting
   *
   * @param html
   */
  highlightCodeBlocks = (html: string) => {
    return html.replace(/<pre><code class="language-(\w+)">([\s\S]*?)<\/code><\/pre>/g, (match, lang, code) => {
      const language = hljs.getLanguage(lang) ? lang : 'javascript';
      const highlightedCode = hljs.highlight(code, { language }).value;
      return `<pre><code class="modcode language-${language} hljs">${highlightedCode}</code></pre>`;
    });
  };

  /**
   * Converts markdown text to html.
   *
   * @param markdownText
   */
  processMarkdownToHtml(markdownText: string) {
    const html = mdToHtml(markdownText, {
      parseFlags: ['DEFAULT', 'NO_HTML_BLOCKS', 'LATEX_MATH_SPANS'],
    });

    return this.highlightCodeBlocks(html);
  }

  /**
   * Returns true if the text content is a single emoji - to make that emoji size larger by default (like discord)
   *
   * @param textContent
   */
  isSingleEmoji(textContent: string | undefined) {
    if (!textContent) {
      return false;
    }

    let reconstructedString = '';
    const matches = textContent.matchAll(emojiRegex);
    for (const match of matches) {
      reconstructedString += match[0];
    }
    return reconstructedString.toLowerCase() === textContent.split(' ').join('').trim().toLowerCase();
  }

  /**
   * Caches the rendered html result to the local cache for 1 hour.
   *
   * @param id
   * @param html
   */
  cacheResult(id: string, html: string) {
    const cacheDuration = 60 * 60 * 1000; // 1 hr, in ms
    this.cache.set(id, {
      expires: Date.now() + cacheDuration,
      html: html,
    });
  }

  enhanceHtml(html: string) {
    html = linkifyHtml(html);
    return this.replaceEmojisInHtmlString(html);

    // return linkifyHtml(html);
    // return twemoji.parse(linkifyHtml(html), {
    //   base: 'emoji/',
    //   folder: '64',
    //   ext: '.png',
    //   className: isEmojiOnly ? 'large-emoji' : 'emoji',
    //   attributes: (icon, variant) => {
    //     const emojiManager = EmojiManager.getInstance();
    //     const normalizedIcon = emojiManager.normalizeEmojiInput(icon);
    //     const emoji = emojiManager.getEmojiMap().get(normalizedIcon);
    //
    //     log.info(icon);
    //     log.info(emoji);
    //     log.info(variant);
    //
    //     if (!emoji) {
    //       log.warn('Emoji not found in map not setting data label');
    //       log.info(icon);
    //       log.info(emoji);
    //       return {};
    //     }
    //
    //     const searchTerm = ':' + emoji.name.split(':')[0].split(' ').join('_').toLowerCase() + ':';
    //     return {
    //       'data-name': searchTerm,
    //       draggable: false,
    //     };
    //   },
    // });
  }

  /**
   * This is for garbage collection purposes, if the cache gets insanely large, we should clear old by old keys first until it hits the max size
   * The max size should be, at most, 1000 entries
   */
  runGarbageCollection() {
    const maxCacheSize = 1000;
    if (this.cache.size <= maxCacheSize) {
      return;
    }

    const keys = Array.from(this.cache.keys());
    keys.sort((a, b) => {
      return this.cache.get(a)!.expires - this.cache.get(b)!.expires;
    });

    const keysToRemove = keys.slice(0, keys.length - maxCacheSize);
    keysToRemove.forEach((key) => {
      this.cache.delete(key);
    });
  }

  replaceEmojisInHtmlString(html: string) {
    const emojiManager = EmojiManager.getInstance();

    // Parse the string into an HTML DOM structure
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const isEmojiOnly = doc.body.textContent ? this.isSingleEmoji(doc.body.textContent) : false;

    // Function to replace emoji in a text node
    const replaceEmojiInText = (text: string) => {
      return text.replace(emojiRegex, (match) => {
        const normalizedIcon = emojiManager.normalizeEmojiInput(match);
        const emoji = emojiManager.getEmojiMap().get(normalizedIcon);

        if (!emoji) {
          log.warn('Emoji not found in map:', match);
          return match; // Return original emoji if not found in map
        }

        const searchTerm = ':' + emoji.name.split(':')[0].split(' ').join('_').toLowerCase() + ':';
        //Case Sensitive
        const src = `emoji/64/${normalizedIcon.toLowerCase()}.png`;

        const cssClass = isEmojiOnly ? 'large-emoji' : 'emoji';

        // Return an img tag as a string to replace the emoji
        return `<img src="${src}" class="${cssClass}" data-name="${searchTerm}" draggable="false" alt="${match}"/>`;
      });
    };

    // Traverse the DOM and find all text nodes
    const traverseAndReplace = (node: Node) => {
      node.childNodes.forEach((child) => {
        if (child.nodeType === Node.TEXT_NODE) {
          // Replace emoji in the text node
          const newText = replaceEmojiInText(child.nodeValue as string);
          const newFragment = document.createRange().createContextualFragment(newText);
          child.replaceWith(newFragment);
        } else if (child.nodeType === Node.ELEMENT_NODE) {
          // Recursively process child nodes
          traverseAndReplace(child);
        }
      });
    };

    traverseAndReplace(doc.body);

    // Serialize the DOM back to a string
    return doc.body.innerHTML;
  }

  /**
   * Returns rendered html from markdown text, with URL support & cross-platform emoji support.
   * Cache is based on combination of the reqId and updated_at timestamp. Cache will auto-clear after 1 hour.
   *
   * @param markdownText
   * @param id
   */
  render(markdownText: string, id: string) {
    if (!markdownText) {
      return '';
    }

    this.runGarbageCollection();
    const hashedContent = cyrb53(markdownText);
    const cacheId = `${id}-${hashedContent}`;
    const cachedResult = this.getCachedResult(cacheId);

    if (cachedResult) {
      return cachedResult;
    }

    const html = this.processMarkdownToHtml(markdownText);
    const enhancedHtml = this.enhanceHtml(html);
    this.cacheResult(cacheId, enhancedHtml);
    return enhancedHtml;
  }
}

<script setup lang="ts">
  import { computed } from 'vue';
  import { StarIcon, ChevronUpDownIcon } from '@heroicons/vue/24/outline';
  import { Channel } from '@pubnub/chat';
  import { getRandomBird } from '@utils/birds';
  import { useAppStateStore } from '@src/store/app';
  import { useChatStore } from '@src/store/chat';
  const props = withDefaults(
    defineProps<{
      channel: Channel;
      currentChannelUrl: string;
      mentions: undefined | number;
      alerts: undefined | number;
      favorite: boolean;
      canFavorite?: boolean;
      draggable?: boolean;
    }>(),
    {
      mentions: undefined,
      alerts: undefined,
      favorite: false,
      canFavorite: true,
    }
  );

  const chosenBirbImage = getRandomBird();
  const emit = defineEmits(['change-channel', 'hide', 'favorite', 'unfavorite', 'leave']);
  const appStore = useAppStateStore();
  const chatStore = useChatStore();

  const toggleFavorite = () => {
    if (props.favorite) {
      emit('unfavorite', props.channel.id);
      return;
    }
    emit('favorite', props.channel.id);
  };

  const isGroupChannel = computed(() => {
    return props.channel.id.startsWith('group') || props.channel.id.startsWith('direct');
  });

  const isFavorite = computed(() => {
    return appStore.favoriteChannels.find((channel) => channel.id === props.channel.id);
  });

  const channelName = computed(() => {
    // Cache the channel ID and check early to reduce unnecessary processing
    const channelId = props.channel?.id;
    if (!channelId || (!channelId.startsWith('direct.') && !channelId.startsWith('group.'))) {
      return props.channel?.name ?? '...';
    }

    // Cache participants and check length early to avoid unnecessary computations
    const participants = chatStore.channelParticipants.get(channelId);
    const participantCount = participants?.length;

    if (!participantCount) {
      return props.channel?.name ?? '...';
    }

    // If there's only one participant and it's the current user, return early
    if (participantCount === 1) {
      const singleUser = participants[0];
      return singleUser.id === chatStore.currentUserId ? `${singleUser.name} (me)` : singleUser.name;
    }

    // Filter out the current user if there are more participants
    const users = participants.filter((user) => user.id !== chatStore.currentUserId);

    // Map and sort only if necessary, and cache the results
    const userNames = users.length > 1 ? users.map((user) => user.name?.split(' ')[0]).sort() : users.map((user) => user.name);

    return userNames.join(', ');
  });

  const changeChannel = () => {
    if (props.currentChannelUrl === props.channel.id) {
      return;
    }
    emit('change-channel', props.channel.id);
  };
</script>

<template>
  <div>
    <button
      :key="`group-${channel.id}`"
      class="w-full rounded-md px-4 mb-0.5 py-1 text-left text-xs transition duration-150 group line-clamp-1 text-surface-600 hover:bg-primary-100 dark:text-surface-200 hover:dark:bg-primary-100/10"
      :class="[currentChannelUrl === channel.id ? 'bg-primary-100 dark:bg-primary-100/10' : '']"
      @click="changeChannel"
    >
      <div class="flex items-center gap-2">
        <div v-if="isGroupChannel" class="shrink-0">
          <img v-if="channel.custom?.cover" :src="channel.custom.cover as string" alt="channel cover" class="rounded-xl object-contain ring-1 ring-inset size-6 ring-surface-200" />
          <img v-else :src="chosenBirbImage" alt="Channel Bird" class="rounded-xl object-contain ring-1 ring-inset size-6 ring-surface-200" />
        </div>
        <div v-else class="shrink-0 font-light text-surface-700 dark:text-surface-400 text-base mr-0.5">#</div>
        <div class="relative flex-1 truncate flex items-center">
          <p class="text-xs font-medium text-black dark:text-surface-200 truncate flex-grow">
            {{ channelName }}
          </p>
          <div class="relative shrink-0 w-8 h-6 flex items-center text-right">
            <div class="absolute right-1 -z-10 w-full group-hover:hidden">
              <div
                v-if="alerts"
                class="inline-flex items-center justify-center rounded-full font-bold outline outline-1 text-[9px] size-4"
                :class="[mentions ? 'bg-red-500 text-red-50 outline-red-600' : 'bg-surface-500 outline-surface-600 dark:bg-surface-100 dark:outline-surface-100 dark:text-black text-white']"
              >
                {{ alerts }}
              </div>
            </div>
            <button v-if="draggable" type="button" aria-haspopup="false" class="group/drag drag-handle">
              <ChevronUpDownIcon class="size-5" />
            </button>
            <div v-if="canFavorite || draggable" class="absolute right-0 top-0.5 z-50 hidden shrink-0 group-hover:block">
              <button v-if="canFavorite" type="button" aria-haspopup="true" aria-controls="overlay_menu" class="group/favorite" @click.stop="toggleFavorite">
                <StarIcon class="size-5" :class="[!isFavorite ? 'text-yellow-500 group-hover/favorite:fill-yellow-500' : 'text-yellow-500 fill-yellow-500 group-hover/favorite:text-white-500 group-hover/favorite:fill-transparent']" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </button>
  </div>
</template>

<script setup lang="ts">
  import TextArea from 'primevue/textarea';
  import Button from 'primevue/button';
  import { ref } from 'vue';
  import { useActiveJobStore } from '@src/store/activejob';
  import { useToast } from 'primevue/usetoast';
  import injectStrict from '@utils/utils';
  import { SentinelKey } from '@utils/symbols';
  import FormLabel from '@components/Shared/FormLabel.vue';
  import { useChatStore } from '@src/store/chat';

  const chatStore = useChatStore();

  const emits = defineEmits(['shift-started', 'close']);

  const comments = ref('');
  const loading = ref(false);
  const commentErrors = ref(false);

  const activeJobStore = useActiveJobStore();
  const sentinel = injectStrict(SentinelKey);
  const toast = useToast();

  async function clockIn(): Promise<void> {
    commentErrors.value = false;
    comments.value = comments.value ? comments.value.trim() : '';

    if (!comments.value || comments.value === '') {
      commentErrors.value = true;
      return;
    }

    loading.value = true;
    const payload = { message: comments.value };

    try {
      await activeJobStore.startShift(payload);
      sentinel.reportMachineInformation();
      comments.value = '';
      emits('shift-started', true);
      emits('close');
    } catch (error) {
      toast.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to start shift',
        life: 3000,
      });
    } finally {
      loading.value = false;
      chatStore.composerFocusStealOverride = false;
    }
  }

  function cancel(): void {
    emits('close');
  }
</script>

<template>
  <form @submit.prevent="clockIn">
    <FormLabel for-id="clock-in-text">Message to Client</FormLabel>
    <TextArea id="clock-in-text" v-model="comments" :invalid="commentErrors" rows="5" class="w-full" auto-resize />
    <p v-if="commentErrors" id="startOfDayErrorText" class="text-sm text-red-500">Please fill out this field before continuing</p>
    <div class="mt-4 flex flex-col-reverse justify-end gap-2 sm:flex-row sm:flex-wrap md:space-x-2 md:space-y-0">
      <Button id="startShiftStartBtn" type="submit" severity="info" :loading="loading" @click="clockIn"> Start Shift </Button>
      <Button id="startShiftCancelBtn" severity="secondary" :loading="loading" outlined @click="cancel"> Cancel </Button>
    </div>
  </form>
</template>

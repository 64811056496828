<script setup lang="ts">
  import { computed, onMounted, onUnmounted, ref, toRefs, onBeforeMount, nextTick } from 'vue';
  import { DateTime } from 'luxon';
  import injectStrict from '@utils/utils';
  import { MarkdownServiceKey } from '@utils/symbols';
  import { useChatStore } from '@src/store/chat';
  import MessageOptions from '@components/Chat/MessageOptions.vue';
  import { Message, User as ChatUser } from '@pubnub/chat';
  import ChatReaction from '@components/Chat/ChatReaction.vue';
  import ChatMediaContainer from '@components/Chat/ChatMediaContainer.vue';
  import ChatParentMessage from '@components/Chat/ChatParentMessage.vue';
  import { useAppStateStore } from '@src/store/app';
  import { EmojiManager } from '@utils/emoji_manager';
  import { Logger } from '@utils/logger';
  const log = new Logger('ChatMessage');

  const emojiManager = EmojiManager.getInstance();

  const props = defineProps<{
    message: Message;
    reactions: boolean;
    senderAvatar: string | undefined | null;
    senderName: string | undefined | null;
    senderUser?: ChatUser;
    parentMessage?: { message: Message; avatar: string | null } | null;
    parentUser?: ChatUser;
  }>();

  const appStore = useAppStateStore();

  const { message } = toRefs(props);
  const tooltipRef = ref();
  const showTooltip = ref(false);
  const avatarTooltip = ref();
  const showAvatarTooltip = ref(false);
  const selectedUser = ref<ChatUser>();
  const content = ref<HTMLParagraphElement | null>(null);
  const chatStore = useChatStore();
  const markdownService = injectStrict(MarkdownServiceKey);
  const me = ref<HTMLDivElement | null>(null);
  const observer = ref<ResizeObserver | null>(null);
  const hasCodeBlocks = ref(false);
  const hoveringMessage = ref(false);

  const artificialNodesCreated = ref<HTMLElement[]>([]);
  const showEmojiPickerButton = ref(false);

  import { open } from '@tauri-apps/plugin-shell';
  import { calculateTooltipPosition } from '@utils/tooltip_calculator';

  const emit = defineEmits(['will-delete', 'scroll-to-message', 'send-message']);

  const relativeCreatedTime = computed(() => {
    const messageDate = DateTime.fromMillis(parseInt(message.value.timetoken) / 10000);
    const now = DateTime.now();
    if (now.diff(messageDate, 'days').days > 1) {
      // show it as a date string (July 1st, 7:00AM)
      return messageDate.toLocaleString(DateTime.DATETIME_MED).replace(`, ${now.year}`, '');
    }
    return DateTime.fromMillis(parseInt(message.value.timetoken) / 10000).toLocaleString(DateTime.TIME_SIMPLE);
  });

  function unicodeToEmoji(unicode: string) {
    const codePoints = unicode.split('-').map((u) => parseInt(u, 16));
    return String.fromCodePoint(...codePoints);
  }

  const applyReaction = (emojiUnicode: string) => {
    let emoji = emojiManager.getEmojiMap().get(emojiUnicode);
    if (!emoji) {
      log.error('Emoji not found');
      return;
    }

    const skinTonePreference = appStore.settings.emojiSkinTone;
    if (emoji.skin_variations && skinTonePreference && emoji.skin_variations[skinTonePreference]) {
      emoji.unified = emoji.skin_variations[skinTonePreference].unified;
    }

    appStore.updateRecentlyUsedEmojis(emoji.unified);
    chatStore.applyReactionToMessage(message.value.timetoken, unicodeToEmoji(emoji.unified));
  };

  const deleteMessage = () => {
    chatStore.deleteMessage(message.value.timetoken);
  };

  const pin = () => {
    chatStore.pinMessage(message.value.timetoken);
  };

  const unpin = () => {
    chatStore.removePin(message.value.timetoken);
  };

  const mute = (timeout: number) => {
    chatStore.muteUserWhoPostedMessage(message.value, timeout);
  };

  const isOwnMessage = computed(() => {
    return message.value.userId === chatStore.chatUserId;
  });

  const getMessageDate = computed(() => {
    let now = DateTime.now();
    let createdAt = DateTime.fromMillis(parseInt(message.value.timetoken) / 10000);
    //see if created at and now are the same day
    if (createdAt.hasSame(now, 'day')) {
      return 'Today at ' + createdAt.toLocaleString(DateTime.TIME_SIMPLE);
    }
    return createdAt.toLocaleString(DateTime.DATETIME_SHORT);
  });

  const copyCodeButtonListener = (e: MouseEvent) => {
    if (!e || !e.target || !(e.target instanceof HTMLElement)) {
      return;
    }
    const targetElement = e.target as HTMLElement;
    const nearestCodeBlock = e.target.closest('pre')?.querySelector('code');
    navigator.clipboard.writeText(nearestCodeBlock?.textContent || '').then(() => {
      targetElement.innerHTML = '<svg fill="currentColor" class="size-4" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M19.916 4.626a.75.75 0 0 1 .208 1.04l-9 13.5a.75.75 0 0 1-1.154.114l-6-6a.75.75 0 0 1 1.06-1.06l5.353 5.353 8.493-12.74a.75.75 0 0 1 1.04-.207Z" clip-rule="evenodd"/></svg>';
      setTimeout(() => {
        targetElement.innerHTML =
          '<svg fill="currentColor" class="size-4" viewBox="0 0 24 24"><path d="M7.5 3.375c0-1.036.84-1.875 1.875-1.875h.375a3.75 3.75 0 0 1 3.75 3.75v1.875C13.5 8.161 14.34 9 15.375 9h1.875A3.75 3.75 0 0 1 21 12.75v3.375C21 17.16 20.16 18 19.125 18h-9.75A1.875 1.875 0 0 1 7.5 16.125V3.375Z"/><path d="M15 5.25a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 17.25 7.5h-1.875A.375.375 0 0 1 15 7.125V5.25ZM4.875 6H6v10.125A3.375 3.375 0 0 0 9.375 19.5H16.5v1.125c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625V7.875C3 6.839 3.84 6 4.875 6Z"/></svg>';
      }, 2000);
    });
  };

  /**
   * Creates the code support node for the code block (language information, copy button support)
   *
   * @param codeBlock - The code block element
   */
  const createCodeSupportNode = (codeBlock: HTMLElement) => {
    try {
      const preBlock = codeBlock.parentElement;
      if (!preBlock) {
        console.warn('No parent element found for the code block.');
        return;
      }
      preBlock.classList.add('relative', 'block', 'group');

      const wrapperNode = document.createElement('div');
      wrapperNode.classList.add('right-8', 'top-4', 'absolute');

      const copyButton = document.createElement('button');
      copyButton.classList.add('hidden', 'group-hover:block');
      copyButton.innerHTML =
        '<svg fill="currentColor" class="size-4" viewBox="0 0 24 24"><path d="M7.5 3.375c0-1.036.84-1.875 1.875-1.875h.375a3.75 3.75 0 0 1 3.75 3.75v1.875C13.5 8.161 14.34 9 15.375 9h1.875A3.75 3.75 0 0 1 21 12.75v3.375C21 17.16 20.16 18 19.125 18h-9.75A1.875 1.875 0 0 1 7.5 16.125V3.375Z"/><path d="M15 5.25a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 17.25 7.5h-1.875A.375.375 0 0 1 15 7.125V5.25ZM4.875 6H6v10.125A3.375 3.375 0 0 0 9.375 19.5H16.5v1.125c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625V7.875C3 6.839 3.84 6 4.875 6Z"/></svg>';
      copyButton.addEventListener('click', copyCodeButtonListener);
      wrapperNode.appendChild(copyButton);
      preBlock.appendChild(wrapperNode);
      artificialNodesCreated.value.push(copyButton);
      artificialNodesCreated.value.push(wrapperNode);
    } catch (error) {
      console.error('Error creating code support node:', error);
    }
  };

  const linkHandler = (e: any) => {
    e.preventDefault();
    const url = e.target.href;
    if (url) {
      open(url);
    }
  };

  onMounted(() => {
    // bind to any mentions in the message
    // const mentions = content?.value?.querySelectorAll('.mention');
    // mentions?.forEach((mention) => {});

    // Add code block supporting elements
    const codeBlocks = content?.value?.querySelectorAll('code');
    codeBlocks?.forEach((codeBlock) => {
      createCodeSupportNode(codeBlock as HTMLElement);
    });
    // prevent default link navigation behavior
    const availableLinks = content?.value?.querySelectorAll('a');
    availableLinks?.forEach((link) => {
      link.addEventListener('click', linkHandler);
    });
  });

  const sendMessageToReplyUserFromToolTip = ({ message }: { message: string }) => {
    if (!props.parentUser) {
      return;
    }

    emit('send-message', { user: props.parentUser, message });
    closeTooltip();
  };

  const sendMessageFromToolTip = ({ message }: { message: string }) => {
    emit('send-message', { user: selectedUser.value, message });
    closeTooltip();
  };

  const handleScrollToMessage = ({ message }: { message: Message }) => {
    emit('scroll-to-message', { message });
  };

  const closeTooltip = () => {
    chatStore.composerFocusStealOverride = false;
    if (tooltipRef.value) {
      // tooltipRef.value.tippy.hide();
      showAvatarTooltip.value = false;
    }
    if (avatarTooltip.value) {
      // avatarTooltip.value.hide();
      showTooltip.value = false;
    }
  };

  onBeforeMount(() => {
    hasCodeBlocks.value = message.value.text.includes('```');
  });

  onUnmounted(() => {
    observer.value?.disconnect();
    /**
     * Cleanup our nodes, click handlers, event listeners, etc.
     */
    const availableLinks = content?.value?.querySelectorAll('a');
    availableLinks?.forEach((link) => {
      link.removeEventListener('click', linkHandler);
    });
    artificialNodesCreated.value.forEach((node) => {
      node.removeEventListener('click', copyCodeButtonListener);
      node.remove();
    });
  });

  const closeAllOpenMenus = () => {
    chatStore.emojiPickerOpen = false;

    chatStore.participantCardOpen = false;
    chatStore.participantCardUser = null;
    chatStore.composerFocusStealOverride = false;

    chatStore.showMessageContextMenu = false;
    chatStore.messageContextTimeToken = null;
  };

  const showUserTooltip = (event: MouseEvent, user?: ChatUser) => {
    //Since these share the same tooltip, we need to close the message context menu if it's open
    closeAllOpenMenus();
    /**
     * requestAnimationFrame is used to avoid a race condition when opening a new tooltip while another is still open.
     * Without this, the old tooltip might close but the new one may not display. This ensures the old tooltip is fully
     * closed before opening the new one.
     */

    requestAnimationFrame(() => {
      if (!user) {
        return;
      }

      chatStore.participantCardUser = user;
      chatStore.composerFocusStealOverride = true;
      chatStore.participantCardOpen = true;

      /**
       * We request an additional animation frame to ensure the tooltip is in the DOM,
       * allowing us to accurately retrieve its dimensions.
       */

      requestAnimationFrame(() => {
        let success = calculateTooltipPosition(event, 'chat-layer', 'participant-card');

        // If positioning the tooltip fails for any reason, close it and reset the values.
        if (!success) {
          chatStore.participantCardUser = null;
          chatStore.composerFocusStealOverride = false;
          chatStore.participantCardOpen = false;
        }
      });
    });
  };

  const showMessageContextMenu = (event: MouseEvent, user?: ChatUser) => {
    //Since these share the same tooltip, we need to close the message context menu if it's open
    closeAllOpenMenus();

    /**
     * requestAnimationFrame is used to avoid a race condition when opening a new tooltip while another is still open.
     * Without this, the old tooltip might close but the new one may not display. This ensures the old tooltip is fully
     * closed before opening the new one.
     */

    requestAnimationFrame(() => {
      if (!user) {
        return;
      }

      chatStore.messageContextTimeToken = message.value.timetoken;
      chatStore.showMessageContextMenu = true;

      /**
       * We request an additional animation frame to ensure the tooltip is in the DOM,
       * allowing us to accurately retrieve its dimensions.
       */

      requestAnimationFrame(() => {
        let success = calculateTooltipPosition(event, 'chat-layer', 'message-context-menu', 'mouse');

        // If positioning the tooltip fails for any reason, close it and reset the values.
        if (!success) {
          chatStore.messageContextTimeToken = null;
          chatStore.showMessageContextMenu = false;
        }
      });
    });
  };
</script>

<template>
  <div ref="me" class="px-4 py-4 group/chat-message relative" @contextmenu.prevent="showMessageContextMenu($event, senderUser)" @mouseenter="hoveringMessage = true" @mouseleave="hoveringMessage = false">
    <ChatParentMessage v-if="parentMessage" :parent-message="parentMessage" :parent-user="parentUser" @scroll-to-message="handleScrollToMessage" @send-message="sendMessageToReplyUserFromToolTip" />
    <div v-if="!message.deleted" class="flex">
      <MessageOptions v-show="message.meta?.customType !== 'admin' && hoveringMessage" class="absolute -top-4 right-4" :timetoken="message.timetoken" :reactions="reactions" :is-own-message="isOwnMessage" :sender-id="message.userId" @simple-react="applyReaction" @mute="mute" />
      <div>
        <button class="mr-2 shrink-0 w-12" @click="showUserTooltip($event, senderUser)">
          <img
            v-if="!senderAvatar"
            src="data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='64' height='64'%3E%3Cpath fill='%23ADC9FF' d='M0 0h64v64H0z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath fill='%23ADC9FF' d='M0 0h64v64H0z'/%3E%3Cpath d='m31.373 24.202 1.938-.764s12.206-4.545 20.985 2.524S105 70.66 105 70.66H35.786s-.391-.882-.954-2.4l.904-.56c7.44-4.612 10.797-11.653 11.242-21.131.395-8.41-4.386-15.475-13.54-21.228l-2.03-1.119-.035-.02zM25 26.656 14 31.5l11 4.652v-9.496z' fill='%2330308F'/%3E%3Cpath d='M27 25.776V37.76c2.377 2.405 3.733 5.942 4.067 10.61 0 7.824 1.205 13.701 3.615 17.63 6.752-4.185 9.877-10.538 10.299-19.525.294-6.277-2.709-11.884-9.01-16.82a2 2 0 1 1-2.246-1.637A53.995 53.995 0 0 0 28.68 25l.036.02-1.715.756z' fill='%2330308F'/%3E%3C/g%3E%3C/svg%3E"
            draggable="false"
            alt=""
            class="size-8 rounded-full object-cover pointer-events-none"
          />
          <img v-else :src="senderAvatar" alt="profile" class="rounded-full size-10 object-cover pointer-events-none" draggable="false" />
        </button>
      </div>

      <div class="overflow-hidden w-full">
        <!-- sender information -->
        <div class="flex flex-wrap lg:flex-nowrap gap-x-2 items-baseline">
          <div class="grow">
            <button class="font-semibold text-sm text-surface-700 dark:text-surface-100 max-w-48 truncate" @click="showUserTooltip($event, senderUser)">
              {{ senderName }}
            </button>
          </div>
          <!-- show time Today at HH:MM A or M/DD/YYYY, HH:MM A -->
          <p class="text-xs text-surface-400 select-text shrink-0">
            {{ getMessageDate }}
          </p>
        </div>
        <!-- body -->
        <div class="text-surface-700 dark:text-surface-300 mt-2 mod-prose">
          <p v-if="message.meta?.customType === 'admin'">
            <span class="text-xs text-surface-500 dark:text-surface-400">{{ relativeCreatedTime }}</span>
            <span class="text-xs text-surface-500 dark:text-surface-400">{{ message.text }}</span>
          </p>
          <!--eslint-disable-next-line vue/no-v-html-->
          <div v-if="message.text" ref="content" class="select-text w-full pr-[4.275rem]" v-html="markdownService.render(message.text, `${message.timetoken}_1`)" />
          <ChatMediaContainer v-if="!message.deleted && message.meta?.mytimeinFiles?.length" :files="message.meta.mytimeinFiles" :meta="message.meta" />
          <ChatReaction v-if="!message.deleted" :message="message" :current-user-id="chatStore.currentUserId as string" :show-emoji-picker-button="showEmojiPickerButton" @react="applyReaction" @mouseenter="showEmojiPickerButton = true" @mouseleave="showEmojiPickerButton = false" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { Message } from '@pubnub/chat';
  import { KnownChatUser } from '@src/types/ChatAndMessaging';
  import { TimetokenUtils } from '@pubnub/chat';
  import { XMarkIcon, EyeIcon, EyeSlashIcon } from '@heroicons/vue/16/solid';
  import { computed, ref } from 'vue';
  import { DateTime } from 'luxon';
  import injectStrict from '@utils/utils';
  import { MarkdownServiceKey } from '@utils/symbols';

  const markdownService = injectStrict(MarkdownServiceKey);
  const emit = defineEmits(['remove-pin']);
  const clamp = ref(true);

  const props = defineProps<{
    user?: KnownChatUser;
    message: Message;
  }>();

  const showEye = computed(() => {
    return props.message.text.length > 100;
  });

  const removePin = () => {
    emit('remove-pin');
  };

  const getMessageDate = computed(() => {
    let now = DateTime.now();
    let createdAt = DateTime.fromMillis(parseInt(props.message.timetoken) / 10000);
    //see if created at and now are the same day
    if (createdAt.hasSame(now, 'day')) {
      return 'Today at ' + createdAt.toLocaleString(DateTime.TIME_SIMPLE);
    }
    return createdAt.toLocaleString(DateTime.DATETIME_SHORT);
  });
</script>

<template>
  <div v-if="user && message" class="flex gap-x-4 py-3">
    <img class="h-8 w-8 flex-none rounded-full bg-surface-100" :src="user.profileUrl" alt="" />
    <div class="flex-auto">
      <div class="flex items-baseline justify-between gap-x-2">
        <p class="text-sm font-semibold leading-6 text-surface-900 dark:text-surface-200">
          {{ user.name }}
        </p>
        <p class="flex-none text-xs text-surface-600 dark:text-surface-400">
          <time :datetime="TimetokenUtils.timetokenToDate(message.timetoken).toLocaleDateString()">{{ getMessageDate }}</time>
        </p>
      </div>
      <p class="mt-1 text-xs leading-6 text-surface-600 dark:text-surface-300" :class="[clamp ? 'line-clamp-2' : '']" v-html="markdownService.render(message.text, `${message.timetoken}_1`)" />
    </div>
    <div class="items-center relative">
      <XMarkIcon class="size-6 text-surface-300 dark:text-surface-600 hover:text-surface-600 dark:hover:text-surface-100 transition duration-100 cursor-pointer" @click="removePin" />
      <button v-if="showEye" @click="clamp = !clamp">
        <EyeIcon v-if="clamp" class="size-6 text-surface-300 dark:text-surface-600 hover:text-surface-600 dark:hover:text-surface-100 transition duration-100 cursor-pointer" />
        <EyeSlashIcon v-else class="size-6 text-surface-300 dark:text-surface-600 hover:text-surface-600 dark:hover:text-surface-100 transition duration-100 cursor-pointer" />
      </button>
    </div>
  </div>
</template>

<style scoped></style>

<script setup lang="ts">
  import { computed, ref } from 'vue';
  import Avatar from 'primevue/avatar';
  import Menu from 'primevue/menu';
  import { useAppStateStore } from '@src/store/app';
  import { useRouter } from 'vue-router';
  import { Logger } from '@utils/logger';
  import injectStrict from '@utils/utils';
  import { SentinelKey } from '@utils/symbols';

  const appState = useAppStateStore();
  const log = new Logger('MENU_AVATAR');

  const sentinal = injectStrict(SentinelKey);

  const avatar = computed<string>(() => {
    return appState.currentUser?.avatar || '';
  });

  const toggle = (event: MouseEvent) => {
    if (menu.value) {
      menu.value.toggle(event);
    }
  };

  const menu = ref<typeof Menu>();
  const router = useRouter();

  const items = ref([
    {
      label: 'Settings',
      icon: 'pi pi-cog',
      command: () => {
        router.push({ path: '/settings' });
      },
    },
    {
      label: 'Logout',
      icon: 'pi pi-sign-out',
      command: () => {
        appState
          .forgetCurrentUser()
          .then(() => {
            log.debug('Forgot Current User');
            sentinal.reset();
          })
          .catch((error) => {
            console.error(error);
          });
      },
    },
  ]);
</script>
<template>
  <div class="flex items-center justify-end text-sm">
    <div class="relative">
      <button class="flex items-center focus:ring-1" aria-haspopup="true" aria-controls="overlay_menu" @click="toggle">
        <Avatar v-if="avatar" :image="avatar" shape="circle" class="shrink-0 border-2 w-[58px] h-[58px] dark:border-white/20" />
        <span v-else class="flex h-8 w-8 cursor-pointer select-none items-center justify-center rounded-full border bg-surface-400 dark:border-white/20 dark:bg-white/10">
          <span class="font-medium leading-none text-white text-md">[]</span>
        </span>
        <slot></slot>
      </button>
      <Menu id="overlay_menu" ref="menu" :model="items" :popup="true" />
    </div>
  </div>
</template>
